import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import Big from 'big.js';
import { AppContext, AppContextT } from 'context';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { addDays, isWeekend } from 'date-fns';
import { getMidDay, getNextTradeDate, HexToRGBA } from 'utils';
import { ProcessingBank } from 'popcorn-js/party';
import _ from 'lodash';
import { calcDefaultImportExport, getCallCurrency, getPutCurrency, optionValid, OptionValues } from './index';
import { OptionDirection, OptionType } from 'popcorn-js/options';
import { OptionPanelCard } from 'components/Option/Tickets/OptionPanelCard';
import { BaseButton, BaseButtonGroup, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { OptionPutCall } from 'components/Option/Tickets/OptionPutCall';
import { ImportExport } from 'popcorn-js/tradeV2';
import { OptionDetails } from './OptionDetails';
import { OptionReferences } from './OptionReferences';
import { BillingInformation } from './BillingInformation';

export const OptionEditorPanel = ({
    uuid,
    option,
    index,
    currencyPairToTrade,
    expanded,
    nonTradingDays,
    updateOption,
    disabled,
    disableHeader,
    disableTitle,
    onExpand,
    onRemove,
    disableEditMaturityDate,
    initialDirection,
    initialType,
}: {
    initialDirection: OptionDirection;
    initialType: OptionType;
    index?: number;
    uuid?: string;
    option: OptionValues;
    currencyPairToTrade?: CurrencyPair;
    nonTradingDays: Date[];
    updateOption: (t: OptionValues, _uuid?: string) => void;
    onExpand?: () => void;
    expanded?: string;
    disabled?: boolean;
    disableHeader?: boolean;
    disableTitle?: boolean;
    disableEditMaturityDate?: boolean;
    onRemove?: () => void;
}): ReactElement => {
    const isMounted = useRef(false);
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const today = getMidDay(new Date());
    // const putAmount = type === OptionType.PUT ? foreignCurrencyAmount : Big(0);
    // const callAmount = type === OptionType.CALL ? foreignCurrencyAmount : Big(0);
    const nextTradeDate = isWeekend(today) ? getNextTradeDate(today) : today;
    const nextDeliveryDate = getNextTradeDate(addDays(nextTradeDate, 2));
    const initialImportExport = calcDefaultImportExport(initialType, initialDirection);

    // option values
    const [externalReference, setExternalReference] = useState<string | undefined>();
    const [premium, setPremium] = useState<Big | undefined>();
    const [tradeDate, setTradeDate] = useState<Date | null | undefined>(nextTradeDate);
    const [expiryDate, setExpiryDate] = useState<Date | null | undefined>(nextTradeDate);
    const [deliveryDate, setDeliveryDate] = useState<Date | null | undefined>(nextDeliveryDate);
    const [strikePrice, setStrikePrice] = useState<Big | undefined>();
    const [type, setType] = useState<OptionType | undefined>(initialType);
    const [direction, setDirection] = useState<OptionDirection | undefined>(initialDirection);
    const [importExport, setImportExport] = useState<ImportExport | undefined>(initialImportExport);
    const [bank, setBank] = useState<ProcessingBank | undefined>();
    const [interbankRate, setInterbankRate] = useState<Big | undefined>();
    const [notes, setNotes] = useState<string | undefined>();
    const [bankRate, setBankRate] = useState<Big | undefined>();
    const [notionalAmount, setNotionalAmount] = useState<Big | undefined>();
    const [quoteAmount, setQuoteAmount] = useState<Big | undefined>();

    useEffect(() => {
        const notionalAmount = Number(option.notionalAmount?.toFixed(2)) || 1.0;
        const quoteAmount = Number(option.quoteAmount?.toFixed(2)) || 0.0;
        const strikePrice = Big((quoteAmount / notionalAmount).toFixed(6));

        setStrikePrice(strikePrice);
    }, [quoteAmount, notionalAmount]);

    // this hook determined if this component is being mounted for the first time
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
    }, []);

    useEffect(() => {
        setExternalReference(option.externalReference);
        setPremium(option.premium);
        setExpiryDate(option.expiryDate);
        setDeliveryDate(option.deliveryDate);
        setStrikePrice(option.strikePrice);
        setTradeDate(option.tradeDate);
        setType(option.type);
        setDirection(option.direction);
        setImportExport(option.importExport);
        setBank(option.bank);
        setInterbankRate(option.interbankRate);
        setNotes(option.notes);
        setBankRate(option.bankRate ? Big(option.bankRate) : undefined);
        setNotionalAmount(option.notionalAmount);
        setQuoteAmount(option.quoteAmount);
    }, [option]);

    const handleRemove = useCallback(
        _.debounce(() => onRemove && onRemove(), 100),
        [onRemove],
    );
    const optionUpdateHandler = useMemo(
        () => _.debounce((_option: OptionValues, _uuid?: string) => updateOption(_option, _uuid), 50),
        [updateOption],
    );
    // this effect calls the updateTrade callback when changes are made to the option
    useEffect(() => {
        if ((!disableHeader && !expanded) || !isMounted.current) {
            // only update when expanded not being mounted
            return;
        }
        if (
            externalReference === option.externalReference &&
            notes === option.notes &&
            _.isEqual(premium, option.premium) &&
            _.isEqual(expiryDate, option.expiryDate) &&
            _.isEqual(deliveryDate, option.deliveryDate) &&
            _.isEqual(strikePrice, option.strikePrice) &&
            _.isEqual(tradeDate, option.tradeDate) &&
            type === option.type &&
            direction === option.direction &&
            importExport === option.importExport &&
            bank === option.bank &&
            _.isEqual(interbankRate, option.interbankRate) &&
            _.isEqual(bankRate, option.bankRate) &&
            _.isEqual(notionalAmount, option.notionalAmount) &&
            _.isEqual(quoteAmount, option.quoteAmount)
        ) {
            // don't update without differences
            // (this is a bit of a hack to stop the update when the component gets remounted on add/remove)
            return;
        }
        optionUpdateHandler(
            {
                externalReference,
                premium,
                expiryDate,
                deliveryDate,
                strikePrice,
                tradeDate,
                type,
                direction,
                importExport,
                bank,
                interbankRate,
                bankRate,
                notionalAmount,
                quoteAmount,
                notes,
            } as OptionValues,
            uuid,
        );
    }, [
        uuid,
        externalReference,
        premium,
        expiryDate,
        deliveryDate,
        strikePrice,
        tradeDate,
        type,
        direction,
        importExport,
        bank,
        interbankRate,
        bankRate,
        notionalAmount,
        quoteAmount,
    ]);

    const handleExternalReferenceChange = useMemo(() => setExternalReference, []);
    const handlePremiumChange = useMemo(() => _.debounce(setPremium, 50), []);
    const handleExpiryDateChange = useMemo(() => _.debounce(setExpiryDate, 50), []);
    const handleDeliveryDateChange = useMemo(() => _.debounce(setDeliveryDate, 50), []);
    const handleTypeChange = useMemo(() => _.debounce(setType, 50), []);
    const handleDirectionChange = useMemo(() => _.debounce(setDirection, 50), []);
    const handleImportExportChange = useMemo(() => _.debounce(setImportExport, 50), []);
    const handleBankChange = useMemo(() => _.debounce(setBank, 50), []);
    const handleNotesChange = useMemo(() => setNotes, []);
    const handleBankRateChange = useMemo(() => _.debounce(setBankRate, 50), []);
    const handleNotionalAmountChange = useMemo(() => _.debounce(setNotionalAmount, 50), []);
    const handleQuoteAmountChange = useMemo(() => _.debounce(setQuoteAmount, 50), []);
    const handleTradeDateChange = useMemo(() => _.debounce(setTradeDate, 50), []);

    const calcExpiryDate = useMemo(() => {
        return expiryDate?.toISOString();
    }, [expiryDate]);

    useEffect(() => {
        const nextTradeDate = isWeekend(today) ? getNextTradeDate(today) : today;
        const nextDeliveryDate = expiryDate ? getNextDeliveryDate(expiryDate, nonTradingDays) : nextTradeDate;
        setDeliveryDate(nextDeliveryDate);
    }, [calcExpiryDate]);

    const calcImportExport = useMemo(() => {
        return calcDefaultImportExport(type, direction);
    }, [type, direction]);

    useEffect(() => {
        setImportExport(calcImportExport);
    }, [calcImportExport]);

    const putCurrency = getPutCurrency(type, currencyPairToTrade, appContext.currencies);
    const callCurrency = getCallCurrency(type, currencyPairToTrade, appContext.currencies);

    return (
        <OptionPanelCard
            key={option.uuid + '-card'}
            uuid={option.uuid || ''}
            disableHeader={disableHeader}
            disabled={disabled}
            onExpand={onExpand}
            option={option}
            index={index}
            expanded={expanded}
            valid={optionValid(option)}
            onRemove={handleRemove}
            handleExternalReferenceChange={handleExternalReferenceChange}
            externalReference={externalReference}
        >
            <>{/* --------------------- */}</>
            <>{/* Manage option section */}</>
            <>{/* --------------------- */}</>
            {!disableTitle && (
                <div className={classes.manageTradeTitle}>
                    <div className={classes.titleText}>Manage option</div>
                    <div className={classes.manageOptionsSection}>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Option Direction</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`sell-button`}
                                    key={'sell-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        direction === OptionDirection.SELL && type === OptionType.PUT
                                            ? COLOR.IMPORT
                                            : direction === OptionDirection.SELL && type === OptionType.CALL
                                            ? COLOR.EXPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleDirectionChange(OptionDirection.SELL)}
                                    text={'Sell'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`buy-button`}
                                    key={'buy-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        direction === OptionDirection.BUY && type === OptionType.PUT
                                            ? COLOR.EXPORT
                                            : direction === OptionDirection.BUY && type === OptionType.CALL
                                            ? COLOR.IMPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleDirectionChange(OptionDirection.BUY)}
                                    text={'Buy'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Option Type</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`put-button`}
                                    key={'put-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        type === OptionType.PUT && direction === OptionDirection.BUY
                                            ? COLOR.EXPORT
                                            : type === OptionType.PUT && direction === OptionDirection.SELL
                                            ? COLOR.IMPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleTypeChange(OptionType.PUT)}
                                    text={'Put'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`call-button`}
                                    key={'call-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        type === OptionType.CALL && direction === OptionDirection.BUY
                                            ? COLOR.IMPORT
                                            : type === OptionType.CALL && direction === OptionDirection.SELL
                                            ? COLOR.EXPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleTypeChange(OptionType.CALL)}
                                    text={'Call'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Import/Export</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`import-button`}
                                    key={'import-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={importExport === ImportExport.IMPORT ? COLOR.IMPORT : COLOR.DARKBLUE}
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleImportExportChange(ImportExport.IMPORT)}
                                    text={'Import'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`export-button`}
                                    key={'export-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={importExport === ImportExport.EXPORT ? COLOR.EXPORT : COLOR.DARKBLUE}
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleImportExportChange(ImportExport.EXPORT)}
                                    text={'Export'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                    </div>
                </div>
            )}
            <div className={classes.tradeCard}>
                <>{/* ----------------------- */}</>
                <>{/* option buy/sell amounts  */}</>
                <>{/* ----------------------- */}</>
                <OptionPutCall
                    type={type}
                    direction={direction}
                    putCurrency={putCurrency}
                    callCurrency={callCurrency}
                    putAmount={type === OptionType.PUT ? notionalAmount : quoteAmount}
                    callAmount={type === OptionType.CALL ? notionalAmount : quoteAmount}
                    onPutAmountChange={(event: ChangeEvent<HTMLInputElement>) =>
                        type === OptionType.PUT
                            ? handleNotionalAmountChange(event.target.value ? Big(event.target.value) : undefined)
                            : handleQuoteAmountChange(event.target.value ? Big(event.target.value) : undefined)
                    }
                    onCallAmountChange={(event: ChangeEvent<HTMLInputElement>) =>
                        type === OptionType.CALL
                            ? handleNotionalAmountChange(event.target.value ? Big(event.target.value) : undefined)
                            : handleQuoteAmountChange(event.target.value ? Big(event.target.value) : undefined)
                    }
                    typeChange={(_type: OptionType) => {
                        setType(_type);
                    }}
                />
                <>{/* --------------------------- */}</>
                <>{/* dates, rates and other info */}</>
                <>{/* --------------------------- */}</>
                <OptionDetails
                    expiryDate={expiryDate}
                    tradeDate={tradeDate}
                    bank={bank}
                    strikePrice={strikePrice}
                    deliveryDate={deliveryDate}
                    premium={premium}
                    disableEditMaturityDate={disableEditMaturityDate}
                    setExpiryDate={setExpiryDate}
                    setDeliveryDate={setDeliveryDate}
                    setTradeDate={setTradeDate}
                    handleTradeDateChange={handleTradeDateChange}
                    handleBankChange={handleBankChange}
                    handleExpiryDateChange={handleExpiryDateChange}
                    handleDeliveryDateChange={handleDeliveryDateChange}
                    handlePremiumChange={handlePremiumChange}
                    nonTradingDays={nonTradingDays}
                />
                <>{/* -------------------------- */}</>
                <>{/* notes and other references */}</>
                <>{/* -------------------------- */}</>
                <OptionReferences notes={notes} handleNotesChange={handleNotesChange} />
                <>{/* -------------------- */}</>
                <>{/* billing information  */}</>
                <>{/* -------------------- */}</>
                <BillingInformation bankRate={bankRate} handleBankRateChange={handleBankRateChange} />
            </div>
        </OptionPanelCard>
    );
};

const isHoliday = (d: Date, nonTradingDays: Date[]): boolean => {
    for (const ntd of nonTradingDays) {
        if (ntd.getDate() === d.getDate() && ntd.getMonth() === d.getMonth() && ntd.getFullYear() === d.getFullYear()) {
            return true;
        }
    }
    return false;
};

const getNextDeliveryDate = (date: Date, nonTradingDays: Date[]): Date => {
    let count = 0;
    let nextTradeDate = date;
    while (true) {
        nextTradeDate = addDays(nextTradeDate, 1);
        if (isWeekend(nextTradeDate) || isHoliday(nextTradeDate, nonTradingDays)) {
            continue;
        }
        count += 1;
        if (count === 2) {
            break;
        }
    }
    return nextTradeDate;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    parentTradesTitle: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
    },
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.dark,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    titleText: {
        textTransform: 'uppercase',
        color: theme.palette.custom.stellcapBrand1.light,
        fontSize: '12px',
        margin: theme.spacing(2),
        marginLeft: theme.spacing(3),
    },
    warning: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
    warningIcon: { color: theme.palette.warning.light },
    warningText: {
        color: theme.palette.warning.light,
        fontSize: '10px',
        lineHeight: '10px',
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    tradeCard: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        width: '600px',
        paddingTop: theme.spacing(2),
    },
    manageTradeTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    detailsLeft: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        columnGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsLeftLeft: {
        display: 'flex',
        rowGap: theme.spacing(2),
        width: '50%',
        flexDirection: 'column',
    },
    detailsLeftRight: {
        display: 'flex',
        rowGap: theme.spacing(2),
        width: '50%',
        flexDirection: 'column',
    },
    detailsRight: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        rowGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    collapsedInfo: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        marginRight: theme.spacing(2),
    },
    acmLabel: {
        fontSize: '14px',
    },
    manageOptionsSection: {
        display: 'flex',
        flexDirection: 'column',
        columnGap: theme.spacing(2),
    },
    manageOptionsSectionHeading: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 'bold',
        width: '200px',
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
    },
    manageOptionsSectionRow: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
    },
}));
