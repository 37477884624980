import React, { ReactElement } from 'react';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { makeStyles } from '@material-ui/core/styles';
import { Counterparty } from 'popcorn-js/counterparty';
import { Invoice } from 'popcorn-js/invoice';
import { Dialog } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import InvoiceDetail from './InvoiceDetailV2';
import LinkedOrders from './LinkedOrders';
import LinkedTrades from './LinkedTrades';
import Payments from './Payments';

const InvoiceDetailDialogV2 = (props: {
    counterparties: Counterparty[];
    invoice: Invoice;
    onClose: () => void;
    readOnly?: boolean;
    show: boolean;
    updateInvoiceSuccess?: () => void;
}): ReactElement => {
    const { show, onClose, invoice, updateInvoiceSuccess } = props;
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} fullScreen open={show}>
            <BaseAppBar title={'Manage Invoice Details'} onClose={onClose} ShowLogo={true} showCloseButton />
            <div className={classes.invoiceDetailsWrapper}>
                <div className={classes.invoiceInformationWrapper}>
                    <InvoiceDetail invoice={invoice} updateInvoiceSuccess={updateInvoiceSuccess} />
                </div>
                <div className={classes.linkedInvoicesWrapper}>
                    <LinkedOrders invoice={invoice} />
                    <LinkedTrades invoice={invoice} />
                    <Payments invoice={invoice} />
                </div>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    invoiceDetailsWrapper: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '150%',
        display: 'flex',
        paddingTop: 35,
        paddingBottom: 35,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    invoiceInformationWrapper: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '763px',
        width: '500px',
        paddingRight: '8px',
        display: 'grid',
    },
    linkedInvoicesWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '763px',
        width: '771.41px',
        padding: 0,
        display: 'column',
    },
}));

export default InvoiceDetailDialogV2;
