import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';

const hostname = window && window.location && window.location.hostname;
const environment = hostname.split('.')[0];
import config from 'react-global-configuration';
import { ThemeWrapper } from 'theme/ThemeWrapper';
import './index.css';
import { Auth0Config } from 'auth0';
import { Config } from 'config';

let auth0Config: Auth0Config = { redirect_uri: `${window.location.origin}/app/dashboard` };
let cfg: Config;
let editMode = false;
switch (hostname) {
    case 'www.stellcap.io':
        auth0Config = {
            ...auth0Config,
            domain: 'tbdealing.eu.auth0.com',
            clientId: 'OISCg01pmHOEO3Vq27ozYl7LzE5gbmqd',
        };
        cfg = {
            url: `https://api.www.stellcap.io`,
            apiURL: `https://api.www.stellcap.io/api`,
            pricingURL: `https://api.www.stellcap.io/rick`,
        };
        break;
    case 'staging.stellcap.io':
        auth0Config = {
            ...auth0Config,
            domain: 'tbdealing.eu.auth0.com',
            clientId: 'gYP2H3YOEG3k5fJKNWugCg55DL6WERek',
        };
        cfg = {
            url: `https://api.staging.stellcap.io`,
            apiURL: `https://api.staging.stellcap.io/api`,
            pricingURL: `https://api.staging.stellcap.io/rick`,
        };
        break;
    case 'dev.stellcap.io':
        auth0Config = {
            ...auth0Config,
            domain: 'tbdealing.eu.auth0.com',
            clientId: 'VeWKsF2XHyHrA7TSkaY7ejSDl6DenZ4E',
        };
        cfg = {
            url: `https://api.dev.stellcap.io`,
            apiURL: `https://api.dev.stellcap.io/api`,
            pricingURL: `https://api.dev.stellcap.io/rick`,
        };
        break;
    case 'app.dev.fxflow.co':
        auth0Config = {
            ...auth0Config,
            domain: 'fxflow.eu.auth0.com',
            clientId: 'VIRa4JzkluqDpKwiO1Dl2tIF8vDw3Hzs',
        };
        cfg = {
            url: `https://api.dev.fxflow.co/api`,
            apiURL: `https://api.dev.fxflow.co/api`,
            pricingURL: `https://api.dev.fxflow.co/rick`,
        };
        break;
    default:
        auth0Config = {
            ...auth0Config,
            domain: 'tbdealing.eu.auth0.com',
            clientId: 'VeWKsF2XHyHrA7TSkaY7ejSDl6DenZ4E',
        };
        cfg = {
            url: `http://localhost:9006/api`,
            apiURL: `http://localhost:9006/api`,
            pricingURL: `http://localhost:8500/rick`,
        };
        editMode = true;
        break;
}

config.set(cfg);

const engine = new Styletron();

ReactDOM.render(
    <Auth0Provider
        domain={auth0Config.domain as string}
        clientId={auth0Config.clientId as string}
        redirectUri={auth0Config.redirect_uri}
        audience={`https://${auth0Config.domain}/api/v2/`}
        scope="openid profile email"
        cacheLocation={'localstorage'}
        useRefreshTokens={true}
    >
        <React.StrictMode>
            <StyletronProvider value={engine}>
                <ThemeWrapper editMode={editMode}>
                    <Router>
                        <App config={cfg} environment={environment as 'www' | 'dev' | 'localhost' | 'staging'} />
                    </Router>
                </ThemeWrapper>
            </StyletronProvider>
        </React.StrictMode>
        In
    </Auth0Provider>,
    document.getElementById('root'),
);
