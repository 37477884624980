import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import { Currency } from 'popcorn-js/currency';
import { useServiceWithCallBack } from 'hooks/useService';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION, ACTION_BUTTON_TYPE } from 'components/CardHeader/StandardCardHeader';
import Generator, { GeneratePositionReportForCurrencyResponse } from 'popcorn-js/report';
import { CurrencyPositionTotals } from './CurrencyPositionTotalsCard';
import { AppContext, AppContextT } from 'context';
import { ErrorOutline as ErrorIcon, Refresh } from '@material-ui/icons';
import { FXExposure } from './FXExposureCard';
import { DisplayRate } from './DisplayRate';
import FileSaver from 'file-saver';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import RefinitivLogo from 'assets/img/RefinitivLogoWhite.png';
import { format } from 'date-fns';
import { AgeingAnalysis } from './AgeingAnalysis/AgeingAnalysis';
import { ActionButton } from 'components/ActionButton/ActionButton';

export const CurrencyPositionCard = (props: { currency: Currency; setReloadTriggerMain: () => void }): ReactElement => {
    const [open, setOpen] = useState<boolean>(true);
    const { currency, setReloadTriggerMain } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    if (!appContext.localCurrency) {
        return (
            <div className={classes.positionValuesLoading}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>{'no local currency defined'}</div>
            </div>
        );
    }

    const downloadReport = (response: GeneratePositionReportForCurrencyResponse | undefined, currency: Currency) => {
        // convert base64 to byte array
        if (!response) {
            return;
        }
        const binData = atob(response.excelData);
        const bytes = new Array(binData.length);
        for (let i = 0; i < binData.length; i++) {
            bytes[i] = binData.charCodeAt(i);
        }

        const blob = new Blob([new Uint8Array(bytes)], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        });
        FileSaver.saveAs(blob, `${currency.isoCode} Dashboard Download ${moment().format(SystemDateFormat)}.xlsx`);
    };

    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);

    const [{ loading, response, error }, setRequest, setNextReqCallBack] = useServiceWithCallBack(
        { currency: currency.isoCode },
        Generator.GeneratePositionReportForCurrency,
    );

    const formatTime = format(new Date(), 'kk:mm');

    const [oneMonthRateBid, setOneMonthRateBid] = useState<number>(0);
    const [oneMonthRateAsk, setOneMonthRateAsk] = useState<number>(0);
    const [twoMonthRateBid, setTwoMonthRateBid] = useState<number>(0);
    const [twoMonthRateAsk, setTwoMonthRateAsk] = useState<number>(0);
    const [threeMonthRateBid, setThreeMonthRateBid] = useState<number>(0);
    const [threeMonthRateAsk, setThreeMonthRateAsk] = useState<number>(0);
    const [sixMonthRateBid, setSixMonthRateBid] = useState<number>(0);
    const [sixMonthRateAsk, setSixMonthRateAsk] = useState<number>(0);

    useEffect(() => {
        if (!loading && response) {
            setOneMonthRateBid(response?.spotRate.bid + response?.oneMonthRate.bid / 10000);
            setOneMonthRateAsk(response?.spotRate.ask + response?.oneMonthRate.ask / 10000);
            setTwoMonthRateBid(response?.spotRate.bid + response?.twoMonthRate.bid / 10000);
            setTwoMonthRateAsk(response?.spotRate.ask + response?.twoMonthRate.ask / 10000);
            setThreeMonthRateBid(response?.spotRate.bid + response?.threeMonthRate.bid / 10000);
            setThreeMonthRateAsk(response?.spotRate.ask + response?.threeMonthRate.ask / 10000);
            setSixMonthRateBid(response?.spotRate.bid + response?.sixMonthRate.bid / 10000);
            setSixMonthRateAsk(response?.spotRate.ask + response?.sixMonthRate.ask / 10000);
        }
    }, [loading, response]);

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'PositionCard/title',
                        type: ITEM_VARIATION.TITLE,
                        text: `${currency.isoCode} Position |`,
                    },
                    {
                        id: 'PositionCard/title',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <div className={classes.elementWrapper}>
                                <div className={classes.row}>
                                    <div className={classes.refinitivLogoWrapper}>
                                        <img alt="Refinitive Logo" src={RefinitivLogo} />
                                    </div>
                                    <DisplayRate text={'Spot Rate'} variant={'title'} color={'default'} />
                                    <div style={{ marginLeft: '5px' }}>
                                        <DisplayRate
                                            text={response?.spotRate.bid.toFixed(4)}
                                            variant={'value'}
                                            color={'export'}
                                        />
                                    </div>
                                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                                    <DisplayRate
                                        text={response?.spotRate.ask.toFixed(4)}
                                        variant={'value'}
                                        color={'import'}
                                    />
                                    <div style={{ marginLeft: '10px' }}>
                                        <DisplayRate text={'1M'} variant={'title'} color={'default'} />
                                    </div>
                                    <div style={{ marginLeft: '5px' }}>
                                        <DisplayRate
                                            text={oneMonthRateBid.toFixed(4)}
                                            variant={'value'}
                                            color={'export'}
                                        />
                                    </div>
                                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                                    <DisplayRate text={oneMonthRateAsk.toFixed(4)} variant={'value'} color={'import'} />
                                    <div style={{ marginLeft: '10px' }}>
                                        <DisplayRate text={'2M'} variant={'title'} color={'default'} />
                                    </div>
                                    <div style={{ marginLeft: '5px' }}>
                                        <DisplayRate
                                            text={twoMonthRateBid.toFixed(4)}
                                            variant={'value'}
                                            color={'export'}
                                        />
                                    </div>
                                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                                    <DisplayRate text={twoMonthRateAsk.toFixed(4)} variant={'value'} color={'import'} />
                                    <div style={{ marginLeft: '10px' }}>
                                        <DisplayRate text={'3M'} variant={'title'} color={'default'} />
                                    </div>
                                    <div style={{ marginLeft: '5px' }}>
                                        <DisplayRate
                                            text={threeMonthRateBid.toFixed(4)}
                                            variant={'value'}
                                            color={'export'}
                                        />
                                    </div>
                                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                                    <DisplayRate
                                        text={threeMonthRateAsk.toFixed(4)}
                                        variant={'value'}
                                        color={'import'}
                                    />
                                    <div style={{ marginLeft: '10px' }}>
                                        <DisplayRate text={'6M'} variant={'title'} color={'default'} />
                                    </div>
                                    <div style={{ marginLeft: '5px' }}>
                                        <DisplayRate
                                            text={sixMonthRateBid.toFixed(4)}
                                            variant={'value'}
                                            color={'export'}
                                        />
                                    </div>
                                    <DisplayRate text={'/'} variant={'value'} color={'default'} />
                                    <DisplayRate text={sixMonthRateAsk.toFixed(4)} variant={'value'} color={'import'} />
                                </div>
                            </div>
                        ),
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        id: 'PositionCard/refresh',
                        element: (
                            <div className={classes.timeRefreshWrapper}>
                                <div className={classes.time}>{formatTime}</div>
                                <div className={classes.refreshPosition}>
                                    <ActionButton
                                        id="refresh"
                                        onClick={() =>
                                            setRequest({
                                                currency: currency.isoCode,
                                            })
                                        }
                                        icon={<Refresh />}
                                    />
                                </div>
                            </div>
                        ),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'PositionCard/download',
                        icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                        helpText: 'Refresh & Download',
                        onClick: () => {
                            setRequest({
                                currency: currency.isoCode,
                                download: true,
                            });
                            setNextReqCallBack(
                                () => (response: GeneratePositionReportForCurrencyResponse | undefined) => {
                                    downloadReport(response, currency);
                                },
                            );
                        },
                        hide: !open,
                        disabled: loading,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'PositionCard/collapse',
                        icon: ACTION_BUTTON_TYPE.COLLAPSE,
                        helpText: 'Collapse',
                        onClick: () => setOpen(false),
                        hide: !open,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'PositionCard/expand',
                        icon: ACTION_BUTTON_TYPE.EXPAND,
                        helpText: 'Expand',
                        onClick: () => setOpen(true),
                        hide: open,
                    },
                ],
            }}
        >
            <Collapse in={open}>
                <div className={classes.positionCardContentRoot}>
                    <div>
                        {!reloadTrigger && (
                            <CurrencyPositionTotals
                                currency={currency}
                                loading={loading}
                                response={response}
                                error={error}
                            />
                        )}
                    </div>
                    <div>
                        {!reloadTrigger && (
                            <FXExposure
                                refresh={setReloadTriggerMain}
                                currency={currency}
                                loading={loading}
                                response={response}
                                error={error}
                            />
                        )}
                    </div>
                    <AgeingAnalysis currency={currency} response={response} loading={loading} error={error} />
                </div>
            </Collapse>
        </StandardCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    positionCardContentRoot: {
        backgroundColor: theme.palette.background.paper,
        height: '325px',
        padding: 0,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'auto auto 1fr auto',
        '&:last-child': {
            padding: 0,
        },
        overFlow: 'hidden',
    },
    positionValuesLoading: {
        gridColumn: '1/4',
        padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.light,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
    refinitivLogoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 10px 0px 8px',
        height: '12px',
        width: '60px',
        alignSelf: 'center',
    },
    elementWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    timeRefreshWrapper: {
        width: '66px',
        height: '22px',
        background: theme.palette.custom.paperExtended.paper6,
        borderRadius: '6px',
        padding: theme.spacing(0.5),
        fontSize: '12px',
        display: 'flex',
    },
    time: {
        margin: '5px 0px 5px 0px',
        fontWeight: 'bold',
    },
    refreshPosition: {
        display: 'flex',
        marginRight: '10px',
    },
}));
