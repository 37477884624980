export interface CurrencyPair {
    baseCurrency: string;
    quoteCurrency: string;
    displayName?: string;
    name: string;
    pips: number;
    settlementDays: number;
}

export const currencyPairs: Record<string, CurrencyPair> = {
    'USD/ZAR': { name: 'USD/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'ZAR' },
    'GBP/GHS': { name: 'GBP/GHS', pips: 1000, settlementDays: 2, baseCurrency: 'GBP', quoteCurrency: 'GHS' },
    'USD/DKK': { name: 'USD/DKK', pips: 1000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'DKK' },
    'USD/AUD': { name: 'USD/AUD', pips: 1000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'AUD' },
    'GBP/ZAR': { name: 'GBP/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'GBP', quoteCurrency: 'ZAR' },
    'DKK/ZAR': { name: 'DKK/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'DKK', quoteCurrency: 'ZAR' },
    'USD/CAD': { name: 'USD/CAD', pips: 10000, settlementDays: 1, baseCurrency: 'USD', quoteCurrency: 'CAD' },
    'GHS/USD': { name: 'GHS/USD', pips: 1000, settlementDays: 2, baseCurrency: 'GHS', quoteCurrency: 'USD' },
    'ZAR/NAD': { name: 'ZAR/NAD', pips: 1000, settlementDays: 2, baseCurrency: 'ZAR', quoteCurrency: 'NAD' },
    'CAD/ZAR': { name: 'CAD/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'CAD', quoteCurrency: 'ZAR' },
    'NAD/ZAR': { name: 'NAD/ZAR', pips: 1000, settlementDays: 2, baseCurrency: 'NAD', quoteCurrency: 'ZAR' },
    'EUR/USD': { name: 'EUR/USD', pips: 10000, settlementDays: 2, baseCurrency: 'EUR', quoteCurrency: 'USD' },
    'AUD/USD': { name: 'AUD/USD', pips: 10000, settlementDays: 3, baseCurrency: 'AUD', quoteCurrency: 'USD' },
    'EUR/ZAR': { name: 'EUR/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'EUR', quoteCurrency: 'ZAR' },
    'USD/GHS': { name: 'USD/GHS', pips: 1000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'GHS' },
    'USD/EUR': { name: 'USD/EUR', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'EUR' },
    'BRL/ZAR': { name: 'BRL/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'BRL', quoteCurrency: 'ZAR' },
    'GBP/USD': { name: 'GBP/USD', pips: 10000, settlementDays: 2, baseCurrency: 'GBP', quoteCurrency: 'USD' },
    'EUR/GHS': { name: 'EUR/GHS', pips: 1000, settlementDays: 2, baseCurrency: 'EUR', quoteCurrency: 'GHS' },
    'USD/BRL': { name: 'USD/BRL', pips: 1000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'BRL' },
    'AUD/ZAR': { name: 'AUD/ZAR', pips: 100, settlementDays: 2, baseCurrency: 'AUD', quoteCurrency: 'ZAR' },
    'CHF/ZAR': { name: 'CHF/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'CHF', quoteCurrency: 'ZAR' },
    'JPY/ZAR': { name: 'JPY/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'JPY', quoteCurrency: 'ZAR' },
    'USD/ZMW': { name: 'USD/ZMW', pips: 10000, settlementDays: 1, baseCurrency: 'USD', quoteCurrency: 'ZMW' },
    'USD/KES': { name: 'USD/KES', pips: 10000, settlementDays: 1, baseCurrency: 'USD', quoteCurrency: 'KES' },
    'KES/USD': { name: 'KES/USD', pips: 10000, settlementDays: 1, baseCurrency: 'KES', quoteCurrency: 'USD' },
    'ZMW/USD': { name: 'ZMW/USD', pips: 10000, settlementDays: 2, baseCurrency: 'ZMW', quoteCurrency: 'USD' },
    'USD/BWP': { name: 'USD/BWP', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'BWP' },
    'USD/NGN': { name: 'USD/NGN', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'NGN' },
    'USD/MWK': { name: 'USD/MWK', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'MWK' },
    'ZAR/MWK': { name: 'ZAR/MWK', pips: 10000, settlementDays: 2, baseCurrency: 'ZAR', quoteCurrency: 'MWK' },
    'EUR/KES': { name: 'EUR/KES', pips: 10000, settlementDays: 2, baseCurrency: 'EUR', quoteCurrency: 'KES' },
    'ZAR/KES': { name: 'ZAR/KES', pips: 10000, settlementDays: 2, baseCurrency: 'ZAR', quoteCurrency: 'KES' },
    'ZAR/ZMW': { name: 'ZAR/ZMW', pips: 10000, settlementDays: 2, baseCurrency: 'ZAR', quoteCurrency: 'ZMW' },
    'ZMW/ZAR': { name: 'ZMW/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'ZMW', quoteCurrency: 'ZAR' },
    'MWK/ZAR': { name: 'MWK/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'MWK', quoteCurrency: 'ZAR' },
    'EUR/BWP': { name: 'EUR/BWP', pips: 10000, settlementDays: 2, baseCurrency: 'EUR', quoteCurrency: 'BWP' },
    'ZAR/BWP': { name: 'ZAR/BWP', pips: 10000, settlementDays: 2, baseCurrency: 'ZAR', quoteCurrency: 'BWP' },
    'BWP/ZAR': { name: 'BWP/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'BWP', quoteCurrency: 'ZAR' },
    'USD/NAD': { name: 'USD/NAD', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'NAD' },
    'USD/CHF': { name: 'USD/CHF', pips: 10000, settlementDays: 2, baseCurrency: 'USD', quoteCurrency: 'CHF' },
    'MUR/ZAR': { name: 'MUR/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'MUR', quoteCurrency: 'ZAR' },
    'NZD/ZAR': { name: 'NZD/ZAR', pips: 10000, settlementDays: 2, baseCurrency: 'NZD', quoteCurrency: 'ZAR' },
};
