import { CriterionFilterType, Query, Criterion } from './search';
import { Identifier } from './search/identifier';

export enum Direction {
    PAYABLE = 'PAYABLE',
    RECEIVABLE = 'RECEIVABLE',
}

export enum ImportExport {
    IMPORT = 'Import',
    EXPORT = 'Export',
}

export type FindRequest = {
    deleted?: boolean;
    filterType?: CriterionFilterType;
    query?: Query;
    criteria?: string[] | Criterion[];
};

export type FindResponse<T> = {
    records: T[];
    total: number;
};

export type RetrieveHistoryRequest = {
    identifier?: Identifier | string;
    // TODO API must be changed for this to be just 'identifier'
    serializedIdentifier?: Identifier | string;
};

export type RetrieveHistoryRequestNew = {
    filterType?: CriterionFilterType;
    query?: Query;
    criteria?: string[] | Criterion[];
};

export type RetrieveHistoryResponse<T> = {
    history: T[];
};

export const financialYears = ['ALL', 'CURRENT', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016'];
export const seasons = ['2021', '2022', '2023'];

export const traders = ['Pieter Oberholzer', 'Jaco Mulder', 'Client'];

export interface ServiceProvider {
    ServiceProviderName: string;
}
