import { UserProfile } from 'popcorn-js/security';
import React, { ReactElement, useContext, useState } from 'react';
import { Avatar, Card, CardContent, Link, Typography, useTheme } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { AppContext, AppContextT } from 'context';
import { CustomTheme } from 'theme/custom';
import { useStyletron } from 'styletron-react';
import { StyledPopper } from 'components/Popper/StyledPopper';
import { BaseButton, COLOR, VARIANT, SIZE } from 'components/BaseButton';

export const LogOutMenu = (props: { onLogout: () => void; userDetail?: UserProfile }): ReactElement => {
    const { userDetail, onLogout } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLImageElement | undefined>(undefined);

    const appContext = useContext<AppContextT>(AppContext);
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const mainColor = appContext.contextSwitched
        ? theme.palette.custom.stellcapBrand2
        : theme.palette.custom.stellcapBrand1;

    // TODO some in-line styled applied, where other approaches did not work

    return (
        <React.Fragment>
            <div>
                <Avatar
                    // edge="start"
                    aria-label="menu"
                    onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                        setAnchorEl(event?.currentTarget ? event.currentTarget : undefined);
                    }}
                    style={{
                        backgroundColor: mainColor.light,
                        opacity: 0.6,
                        color: mainColor.contrastText,
                    }}
                >
                    <Person />
                </Avatar>
            </div>
            <StyledPopper anchorEl={anchorEl} onClickAway={() => !!anchorEl && setAnchorEl(undefined)}>
                <Card>
                    <CardContent
                        className={css({
                            display: 'grid',
                            gridTemplateColumns: '120px 228px',
                            backgroundColor: theme.palette.background.default,
                        })}
                    >
                        <div className={css({ textAlign: 'center' })}>
                            <Avatar
                                style={{ width: '96px', height: '96px', border: '2px solid white', margin: '0px auto' }}
                            >
                                <Person className={css({ width: '100%', height: '100%' })} />
                            </Avatar>
                        </div>
                        <div className={css({ textAlign: 'center' })}>
                            <div className={css({ display: 'flex', flexDirection: 'column' })}>
                                <div>
                                    <div
                                        className={css({
                                            fontSize: '12px',
                                            textAlign: 'justify',
                                            fontWeight: 'bold',
                                            color: theme.palette.custom.text.label,
                                        })}
                                    >
                                        {userDetail?.firstName &&
                                        userDetail?.firstName !== '' &&
                                        userDetail?.lastName &&
                                        userDetail?.lastName !== ''
                                            ? userDetail.firstName + ' ' + userDetail.lastName
                                            : '(name not specified)'}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className={css({
                                            fontSize: '12px',
                                            textAlign: 'justify',
                                        })}
                                    >
                                        {userDetail?.emailAddress && userDetail.emailAddress !== ''
                                            ? userDetail.emailAddress
                                            : '(email address not specified)'}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={css({
                                    paddingTop: '10px',
                                    textAlign: 'justify',
                                })}
                            >
                                <BaseButton
                                    id={'LogOutMenu/sign-out'}
                                    text={'Sign Out'}
                                    onClick={onLogout}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.CONTEXT}
                                    size={SIZE.MEDIUM}
                                />
                            </div>
                            <div
                                className={css({
                                    borderStyle: 'solid',
                                    height: '0px',
                                    borderWidth: '0.1em',
                                    borderTopColor: theme.palette.background.paper,
                                    borderLeftColor: theme.palette.background.paper,
                                    borderRightColor: theme.palette.background.paper,
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                })}
                            />
                            <div>
                                <div
                                    className={css({
                                        fontSize: '12px',
                                        textAlign: 'justify',
                                        color: theme.palette.custom.text.body,
                                    })}
                                >
                                    STELLCAP Dealing is designed and built by Andile.
                                </div>
                            </div>
                            <div>
                                <Typography
                                    className={css({
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        color: theme.palette.text.primary,
                                    })}
                                >
                                    <Link
                                        className={css({
                                            marginLeft: '5px',
                                            color: theme.palette.text.primary,
                                            '&:hover': {
                                                color: theme.palette.text.primary,
                                            },
                                        })}
                                        href={'https://www.stellcap.co.za/'}
                                        target={'_blank'}
                                        underline={'none'}
                                    />
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </StyledPopper>
        </React.Fragment>
    );
};
