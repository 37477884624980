import { makeStyles, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import { AppContextT, AppContext } from 'context';
import React, { ReactElement, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import CloseIcon from '@material-ui/icons/Close';
import miniLogo from 'assets/img/stellcap-logo-mini.svg';
import { useStyletron } from 'styletron-react';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';

const useStyles = makeStyles((theme: CustomTheme) => ({
    appBar1: {
        position: 'relative',
        backgroundColor: theme.palette.custom.stellcapBrand1.main,
    },
    appBar2: {
        position: 'relative',
        backgroundColor: theme.palette.custom.stellcapBrand2.main,
    },
    title1: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.stellcapBrand1.contrastText,
    },
    title2: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    icon1: {
        color: theme.palette.custom.stellcapBrand1.contrastText,
    },
    icon2: {
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    lineBorder: {
        borderLeft: 'solid',
        borderLeftColor: theme.palette.common.white,
        borderLeftWidth: '1px',
    },
    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        marginRight: theme.spacing(2),
    },
}));

export const BaseAppBar = (props: {
    onClose: () => void;
    title: string;
    ShowLogo?: boolean;
    showCloseButton?: boolean;
    showActionButton?: boolean;
    buttonDisabled?: boolean;
    actionButtonOnClick?: () => void;
    buttonText?: string;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [css] = useStyletron();

    const appBarClass = appContext.contextSwitched ? classes.appBar2 : classes.appBar1;
    const titleClass = appContext.contextSwitched ? classes.title2 : classes.title1;
    const iconClass = appContext.contextSwitched ? classes.icon2 : classes.icon1;

    function displayLogo() {
        if (props.ShowLogo == true) {
            return (
                <img
                    alt="logo"
                    className={css({
                        overflow: 'hidden',
                        height: '40px',
                    })}
                    src={miniLogo}
                />
            );
        }
    }

    function showActionButton() {
        if (props.showActionButton == true) {
            return (
                <div className={classes.actionButtonWrapper}>
                    <BaseButton
                        id={'button'}
                        variant={VARIANT.OUTLINED}
                        size={SIZE.MEDIUM}
                        text={props.buttonText || 'Record info'}
                        color={COLOR.WHITE}
                        onClick={props.actionButtonOnClick}
                        disabled={props.buttonDisabled}
                    />
                </div>
            );
        }
    }

    function showCloseButton() {
        if (props.showCloseButton == true) {
            return (
                <div className={classes.lineBorder}>
                    <IconButton aria-label="close" edge="end" onClick={props.onClose}>
                        <CloseIcon className={iconClass} />
                    </IconButton>
                </div>
            );
        }
    }

    return (
        <AppBar className={appBarClass}>
            <Toolbar>
                {displayLogo()}
                <Typography className={titleClass} variant="h5">
                    {props.title}
                </Typography>
                {showActionButton()}
                {showCloseButton()}
            </Toolbar>
        </AppBar>
    );
};
