import React, { ChangeEvent, ReactElement } from 'react';
import { makeStyles, Card, MenuItem, FormControl } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { LightSelect, LightTextField } from './styledComponents';
import { seasons } from 'popcorn-js';

export const OptionReferences = ({
    notes,
    handleNotesChange,
}: {
    notes: string | undefined;
    handleNotesChange: React.Dispatch<React.SetStateAction<string | undefined>>;
}): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelDark}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect label={'Season'} disabled style={{ width: '100%' }}>
                            {seasons.map(
                                (s) =>
                                    s && (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ),
                            )}
                            <MenuItem key={'b'} value={''}>
                                {'blank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect label={'Product'} style={{ width: '100%' }} disabled />
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightTextField label={'Client Reference'} disabled />
                    <LightTextField label={'Client Notes'} disabled />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect label={'Trader'} disabled style={{ width: '100%' }} fullWidth />
                    </FormControl>
                    <LightTextField
                        id={'traderNotes'}
                        label={'Trader Notes'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleNotesChange(event.target.value)
                        }
                        value={notes || ''}
                    />
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelDark: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
