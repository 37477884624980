import React, { useContext, useEffect, useState, ReactElement, SyntheticEvent } from 'react';
import { getSignBasedOnType, Invoice } from 'popcorn-js/invoice';
import {
    InvoiceAmountRequested,
    InvoiceTradeLink,
    SettlementInstruction,
    Status,
} from 'popcorn-js/settlementInstruction';
import { Counterparty } from 'popcorn-js/counterparty';
import { CustomTheme } from 'theme/custom';
import { styled, useStyletron } from 'styletron-react';
import { displayAmount, processUnixDateForViewing } from 'utils';
import { AppContext, AppContextT } from 'context';
import DefaultHandler from 'popcorn-js/rick/handler';
import moment from 'moment';
import { Icon, IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';
import { FileCopy } from '@material-ui/icons';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import { ComponentLevelError } from 'components/Error/Error';
import { Clear as DeselectIcon, GetApp, Warning as PricesUnavaliableIcon } from '@material-ui/icons';
import { ComponentLevelLoader } from 'components/Loader/ComponentLevelLoader';
import InvoiceDetailDialog from 'components/invoice/InvoiceDetailDialogV2';
import Big from 'big.js';
import { useService, useServiceSync } from 'hooks/useService';
import { Downloader } from 'popcorn-js/settlementInstruction/downloader';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { saveAs } from 'file-saver';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { StandardCard } from 'components/Card/Card';
import { FindRequest, FindResponse } from 'popcorn-js';
import { Trade, TradeType } from 'popcorn-js/tradeV2';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { ActionButton } from 'components/ActionButton/ActionButton';
// <pp.. />
type InvoiceAndInvoiceAmountsRequested = {
    invoice: Invoice;
    amountRequested: InvoiceAmountRequested;
};

type InvoiceRequestedAmountCounterpartySummary = {
    counterparty: Counterparty;
    total: number;
    localTotal: number;
    invoicesAndInvoiceAmountsRequested: InvoiceAndInvoiceAmountsRequested[];
};
type InvoiceRequestedAmountCounterpartySummaryMap = {
    [key: string]: InvoiceRequestedAmountCounterpartySummary;
};

type TradeSummary = {
    amount: number;
    localAmount: number;
    externalReference: string | undefined;
    bank: string | undefined;
    type: TradeType | undefined;
    effectiveRate: number | undefined;
    allInRate: number | undefined;
};
type TradesSummaryMap = {
    [key: string]: TradeSummary;
};

const DividingLine = (props: { theme: CustomTheme }) => {
    const StyledComponent = styled('div', {
        margin: '10px 0',
        borderWidth: '1px 0 0 0',
        borderStyle: 'solid',
        borderColor: props.theme.palette.grey[300],
    });
    return <StyledComponent />;
};

const calculateLocalTotal = (sign: number, links: InvoiceTradeLink[]) => {
    let totalRateAmount = 0.0;
    for (const tradeLink of links) {
        // correct for negative link amounts
        const linkAmount = tradeLink.amount < 0 ? -1 * tradeLink.amount : tradeLink.amount;
        totalRateAmount += linkAmount * tradeLink.rate;
    }
    return sign * totalRateAmount;
};

const displayCurrencyAmount = (amount: number | undefined): string => {
    if (amount) {
        let sign = '';
        if (amount < 0) {
            sign = '-';
        }
        return `${sign} ${displayAmount(Big(Math.abs(amount)))}`;
    }
    return '--';
};

const displayRate = (value: number | undefined): string => {
    if (value) {
        return value.toFixed(4);
    }
    return '-';
};

export const Summary = (props: {
    SI: SettlementInstruction;
    showRates?: boolean;
    readOnly?: boolean;
    onInvoiceRemove?: (id: string) => void;
    onClose: () => void;
}): ReactElement => {
    const { SI: initialSI, readOnly, onInvoiceRemove, onClose, showRates } = props;

    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>();
    const [viewInvoice, setViewInvoice] = useState(false);
    const [itemListHeight, setItemListHeight] = useState<number>(0);
    const [
        invoiceRequestedAmountCounterpartySummaryMap,
        setInvoiceRequestedAmountCounterpartySummaryMap,
    ] = useState<InvoiceRequestedAmountCounterpartySummaryMap>({} as InvoiceRequestedAmountCounterpartySummaryMap);
    const [tradesSummaryMap, setTradesSummaryMap] = useState<TradesSummaryMap>({} as TradesSummaryMap);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [error, setError] = useState<string | undefined>(undefined);
    const [currentSI, setCurrentSI] = useState(initialSI);
    const [localToSIMTMRate, setLocalToSIMTMRate] = useState<number | undefined>();
    const [loading, setLoading] = useState(true);

    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();

    const [downloadSummaryStarted, setDownloadSummaryStarted] = useState<boolean>(false);
    const [downloadPDFSummaryStarted, setDownloadPDFSummaryStarted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [counterpartyFind] = useServiceSync<FindRequest, FindResponse<Counterparty>>(CounterpartyRecordkeeper.find);
    const [invoiceFind] = useServiceSync<FindRequest, FindResponse<Invoice>>(InvoiceRecordkeeper.find);
    const [tradeFind] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);

    /* ---------------------------------------------------------------------
     download summary start
     --------------------------------------------------------------------- */
    const [
        { response: downloadSIResponse, loading: downloadSILoading, error: downloadSIError },
        setDownloadSIRequest,
    ] = useService(undefined, Downloader.DownloadConfirmation);
    const [
        { response: downloadPDFSIResponse, loading: downloadPDFSILoading, error: downloadPDFSIError },
        setDownloadPDFSIRequest,
    ] = useService(undefined, Downloader.DownloadPDFConfirmation);
    const handleExcelDownloadSummary = () => {
        if (initialSI) {
            setDownloadSummaryStarted(true);
            setDownloadSIRequest({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: initialSI?.id } });
        }
    };
    const handlePDFDownloadSummary = () => {
        if (initialSI) {
            setDownloadPDFSummaryStarted(true);
            setDownloadPDFSIRequest({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: initialSI?.id } });
        }
    };
    useEffect(() => {
        if (downloadSIResponse && !downloadSILoading && downloadSummaryStarted) {
            setDownloadSummaryStarted(false);
            try {
                const binData = atob(downloadSIResponse.data || '');
                const bytes = new Array(binData.length);
                for (let i = 0; i < binData.length; i++) {
                    bytes[i] = binData.charCodeAt(i);
                }
                const blob = new Blob([new Uint8Array(bytes)], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
                });
                saveAs(blob, `Settlement Instruction Confirmation ${initialSI.number}.xlsx`);
            } catch (e) {
                console.error('error downloading SI summary', e);
                setErrorMessage(e);
            }
        }
    }, [downloadSIResponse, downloadSILoading, downloadSummaryStarted, initialSI.number]);
    useEffect(() => {
        if (downloadSIError && !downloadSILoading && downloadSummaryStarted) {
            setDownloadSummaryStarted(false);
            setErrorMessage('Failed to download SI summary: ' + downloadSIError);
        }
    }, [downloadSIError, downloadSILoading, downloadSummaryStarted]);

    useEffect(() => {
        if (downloadPDFSIResponse && !downloadPDFSILoading && downloadPDFSummaryStarted) {
            setDownloadPDFSummaryStarted(false);
            try {
                const binData = atob(downloadPDFSIResponse.data || '');
                const bytes = new Array(binData.length);
                for (let i = 0; i < binData.length; i++) {
                    bytes[i] = binData.charCodeAt(i);
                }
                const blob = new Blob([new Uint8Array(bytes)], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
                });
                saveAs(blob, `Settlement Instruction Confirmation ${initialSI.number}.pdf`);
            } catch (e) {
                console.error('error downloading PDF SI summary', e);
                setErrorMessage(e);
            }
        }
    }, [downloadPDFSIResponse, downloadPDFSILoading, downloadPDFSummaryStarted, initialSI.number]);
    useEffect(() => {
        if (downloadPDFSIError && !downloadPDFSILoading && downloadPDFSummaryStarted) {
            setDownloadPDFSummaryStarted(false);
            setErrorMessage('Failed to download SI summary: ' + downloadPDFSIError);
        }
    }, [downloadPDFSIError, downloadPDFSILoading, downloadPDFSummaryStarted]);

    /* ---------------------------------------------------------------------
     download summary end
     --------------------------------------------------------------------- */

    useEffect(() => {
        const findMTMRate = async (SI: SettlementInstruction): Promise<number> => {
            // then there is no need to retrieve spot rate
            if (SI.currency === appContext.localCurrency?.isoCode) {
                return 1;
            }
            const currencyPair = appContext.currencyPairs?.find(
                (ccyPair) =>
                    ccyPair.baseCurrency === SI.currency && ccyPair.quoteCurrency === appContext.localCurrency?.isoCode,
            );
            if (currencyPair) {
                try {
                    let rateRetrieveResult;
                    if (SI.date && SI.date <= moment().unix()) {
                        rateRetrieveResult = await DefaultHandler.RetrieveRate({
                            rateSubscription: {
                                currencyPairName: currencyPair.name,
                                date: 0,
                            },
                        });
                    } else {
                        rateRetrieveResult = await DefaultHandler.RetrieveRate({
                            rateSubscription: {
                                currencyPairName: currencyPair.name,
                                date: SI.date || 0,
                            },
                        });
                    }
                    return (
                        (rateRetrieveResult.priceSubscriptionSucceeded?.askRate +
                            rateRetrieveResult.priceSubscriptionSucceeded?.bidRate) /
                        2
                    );
                } catch (e) {
                    console.error('error retrieving spot rate for currency pair', e);
                    throw new Error('error retrieving spot rate for currency pair');
                }
            }
            throw new Error('currency pair not found');
        };
        const findSiInvoices = async (SI: SettlementInstruction): Promise<Invoice[]> => {
            const invoiceIds = (SI.invoiceAmountsRequested || []).map(
                (entry: InvoiceAmountRequested) => entry.invoiceId,
            );
            if (invoiceIds.length > 0) {
                const criteria: Criteria = invoiceIds.map((id) => ({
                    type: CriteriaType.ExactCriterion,
                    field: 'id',
                    text: id,
                }));

                try {
                    const findResponse = await invoiceFind({ criteria });
                    return [...(findResponse as { records: Invoice[]; total: number }).records];
                } catch (e) {
                    console.error('Could not find invoices: ', e.message || e);
                    throw e;
                }
            }
            return [] as Invoice[];
        };
        const findSiTrades = async (SI: SettlementInstruction): Promise<Trade[]> => {
            const tradeIds = (SI.invoiceTradeLinks || []).map((entry: InvoiceTradeLink) => entry.tradeId);
            if (tradeIds.length > 0) {
                const criteria: Criteria = tradeIds.map((id) => ({
                    type: CriteriaType.ExactCriterion,
                    field: 'id',
                    text: id,
                }));

                try {
                    const findResponse = await tradeFind({ criteria });
                    return [...(findResponse as { records: Trade[]; total: number }).records];
                } catch (e) {
                    console.error('Could not find invoices: ', e.message || e);
                    throw e;
                }
            }
            return [] as Trade[];
        };
        const findSiCounterparties = async (): Promise<Counterparty[]> => {
            try {
                const counterpartyFindResponse = await counterpartyFind({
                    criteria: [],
                });
                return counterpartyFindResponse.records || ([] as Counterparty[]);
            } catch (e) {
                console.error('Could not find request for fund counterparties: ' + e.message || e);
                throw e;
            }
        };

        const buildSummaries = async (
            SI: SettlementInstruction,
            siInvoices: Invoice[],
            siTrades: Trade[],
            siCounterparties: Counterparty[],
        ) => {
            const invoiceRequestedAmountCounterpartySummary: InvoiceRequestedAmountCounterpartySummaryMap = {} as InvoiceRequestedAmountCounterpartySummaryMap;
            if (SI.invoiceAmountsRequested) {
                for (const invoiceRequestedAmount of SI.invoiceAmountsRequested) {
                    const entryInvoice = siInvoices.find((inv: Invoice) => inv.id === invoiceRequestedAmount.invoiceId);
                    if (!entryInvoice) {
                        console.error('Could not find invoice in invoice cache.');
                        throw new Error('Could not find invoice in invoice cache.');
                    }
                    const sign = getSignBasedOnType(entryInvoice);

                    const invoiceCounterparty = siCounterparties.find(
                        (c: Counterparty) => c.id === entryInvoice.counterpartyId,
                    );
                    if (!invoiceCounterparty || !invoiceCounterparty.id) {
                        console.error('Could not find invoice counterparty in counterparty cache.');
                        throw new Error('Could not find invoice counterparty in counterparty cache.');
                    }
                    if (invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id] === undefined) {
                        invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id] = {
                            counterparty: invoiceCounterparty,
                            total: 0,
                            localTotal: 0,
                            invoicesAndInvoiceAmountsRequested: [] as InvoiceAndInvoiceAmountsRequested[],
                        };
                    }

                    // correct for previously mis-captured amounts
                    const amountRequested =
                        invoiceRequestedAmount.amount < 0
                            ? -1 * invoiceRequestedAmount.amount
                            : invoiceRequestedAmount.amount;

                    switch (SI.status) {
                        case Status.Draft:
                        case Status.Submitted:
                            invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].localTotal +=
                                sign * amountRequested * (localToSIMTMRate !== undefined ? localToSIMTMRate : 0.0);
                            break;
                        case Status.Complete:
                            {
                                const invoiceTradeLinks = SI.invoiceTradeLinks?.filter(
                                    (invoiceTradeLink: InvoiceTradeLink) =>
                                        invoiceTradeLink.invoiceId === invoiceRequestedAmount.invoiceId,
                                );
                                if (invoiceTradeLinks !== undefined) {
                                    invoiceRequestedAmountCounterpartySummary[
                                        invoiceCounterparty.id
                                    ].localTotal += calculateLocalTotal(sign, invoiceTradeLinks);
                                }
                            }
                            break;
                        default:
                        // ??
                    }

                    invoiceRequestedAmountCounterpartySummary[invoiceCounterparty.id].total += sign * amountRequested;

                    invoiceRequestedAmountCounterpartySummary[
                        invoiceCounterparty.id
                    ].invoicesAndInvoiceAmountsRequested?.push({
                        invoice: entryInvoice,
                        amountRequested: { ...invoiceRequestedAmount, amount: amountRequested },
                    });
                }
            }

            setInvoiceRequestedAmountCounterpartySummaryMap(invoiceRequestedAmountCounterpartySummary);

            const tradesSummaryMapNew: TradesSummaryMap = {} as TradesSummaryMap;
            if (SI.invoiceTradeLinks) {
                for (const invoiceTradeLink of SI.invoiceTradeLinks) {
                    const entryInvoice = siInvoices.find((inv: Invoice) => inv.id === invoiceTradeLink.invoiceId);
                    if (!entryInvoice) {
                        console.error('Could not find invoice in invoice cache.');
                        throw new Error('Could not find invoice in invoice cache.');
                    }
                    const sign = getSignBasedOnType(entryInvoice);
                    const entryTrade = siTrades.find((trd: Trade) => trd.id === invoiceTradeLink.tradeId);
                    if (!entryTrade) {
                        console.error('Could not find trade in trade cache.');
                        throw new Error('Could not find trade in trade cache.');
                    }
                    if (entryTrade && entryTrade.id !== undefined && entryTrade.dealRate) {
                        if (tradesSummaryMapNew[entryTrade.id] === undefined) {
                            tradesSummaryMapNew[entryTrade.id] = {
                                amount: sign * invoiceTradeLink.amount,
                                localAmount: sign * entryTrade.dealRate * invoiceTradeLink.amount,
                                externalReference: entryTrade.externalReference,
                                bank: entryTrade.bank,
                                type: entryTrade.type,
                                effectiveRate: entryTrade.effectiveRate,
                                allInRate: entryTrade.dealRate,
                            };
                        } else {
                            tradesSummaryMapNew[entryTrade.id].amount += sign * invoiceTradeLink.amount;
                            tradesSummaryMapNew[entryTrade.id].localAmount =
                                entryTrade?.dealRate * tradesSummaryMapNew[entryTrade.id].amount;
                        }
                    }
                }
            }
            setTradesSummaryMap(tradesSummaryMapNew);
        };

        const processNewSI = async (SI: SettlementInstruction) => {
            setLoading(true);
            try {
                setLocalToSIMTMRate(await findMTMRate(SI));
                const siInvoices = await findSiInvoices(SI);
                const siTrades = await findSiTrades(SI);
                const siCounterparties = await findSiCounterparties();
                buildSummaries(SI, siInvoices, siTrades, siCounterparties).finally();
                setCurrentSI(SI);
            } catch (e) {
                setError(e.message || e);
            }
            setLoading(false);
        };
        processNewSI(initialSI).finally();
    }, [initialSI, appContext, localToSIMTMRate]);

    const handleHideInvoiceDetail = () => {
        setViewInvoice(false);
    };

    const handleInvoiceSelect = (invoice: Invoice) => {
        setSelectedInvoice(invoice);
        setViewInvoice(true);
    };

    const renderContent = () => {
        if (error) {
            return (
                <div
                    className={css({
                        height: '100%',
                        display: 'grid',
                        alignContent: 'center',
                        paddingBottom: '30px',
                    })}
                >
                    <ComponentLevelError errorMessage={error} />
                </div>
            );
        }

        if (loading) {
            return renderLoading();
        }

        return renderMain();
    };

    const getItemListHeight = (element: string | Node | null) => {
        if (element === null) {
            return;
        }
        if (typeof element === 'string') {
            return;
        }
        if (element.parentElement === null) {
            return;
        }
        if (itemListHeight > 0) {
            // already set
            return;
        }
        try {
            if (element && element.parentElement) {
                setItemListHeight(element.parentElement.clientHeight - 200);
            }
        } catch (e) {
            console.error('error setting item list height');
        }
    };

    let totalAmount = Big(0);
    let totalAmountMulRate = Big(0);
    Object.keys(tradesSummaryMap).forEach((tradeId) => {
        const summary = tradesSummaryMap[tradeId];
        totalAmount = totalAmount.add(summary.amount || 0);
        const amountMulRate = Big(summary.amount).mul(summary.effectiveRate || 0);
        totalAmountMulRate = totalAmountMulRate.add(amountMulRate);
    });
    const weightedAvgEffRate = totalAmountMulRate.div(totalAmount.gt(0) ? totalAmount : 1).toFixed(4);

    const renderMain = () => {
        let total = 0;
        try {
            total += Object.values(invoiceRequestedAmountCounterpartySummaryMap).reduce((total, summary) => {
                return total + summary.total;
            }, 0);
        } catch (e) {
            console.error('error calculating total', e);
        }
        let localTotal = 0;
        try {
            localTotal += Object.values(invoiceRequestedAmountCounterpartySummaryMap).reduce(
                (localTotal, summary) => localTotal + summary.localTotal,
                0,
            );
        } catch (e) {
            console.error('error calculating total', e);
        }
        const currencyIsoCode =
            (appContext.currencies?.find((c) => c.isoCode === currentSI.currency) || {}).isoCode || '';
        const localCurrencyIsoCode =
            (appContext.currencies?.find((c) => c.isoCode === appContext.localCurrency?.isoCode) || {}).isoCode || '';

        const displayLocalCurrency = currentSI.currency !== appContext.localCurrency?.isoCode;

        return (
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                })}
                ref={(element: string | Element | null) => {
                    getItemListHeight(element);
                }}
            >
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={() => {
                        setErrorMessage(undefined);
                    }}
                    successMessage={undefined}
                    warningMessage={undefined}
                />

                {showRates && (
                    <>
                        <div
                            className={css({
                                display: 'grid',
                                gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                                padding: '0 10px',
                            })}
                        >
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                })}
                            >
                                <Typography variant={'subtitle1'}>{`Status: ${currentSI.status}`}</Typography>
                                <Typography variant={'subtitle1'}>{`Value Date: ${processUnixDateForViewing(
                                    currentSI.date,
                                )}`}</Typography>
                                <Typography variant={'subtitle1'}>
                                    {`${currencyIsoCode} Total: ${displayCurrencyAmount(total)}`}
                                </Typography>
                                {displayLocalCurrency && (
                                    <div>
                                        {localTotal !== 0 ? (
                                            <Typography variant={'subtitle1'}>
                                                {`ZAR Total: ${displayCurrencyAmount(localTotal)}`}
                                            </Typography>
                                        ) : localToSIMTMRate ? (
                                            <Typography variant={'subtitle1'}>
                                                {`ZAR Total: ${displayCurrencyAmount(total * localToSIMTMRate)}`}
                                            </Typography>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'auto 20px',
                                                }}
                                            >
                                                <div>
                                                    <Typography variant={'caption'}>{'ZAR Total:'}</Typography>
                                                </div>
                                                <div
                                                    className={css({
                                                        textAlign: 'right',
                                                        minWidth: 120,
                                                    })}
                                                >
                                                    <Tooltip title="Prices Unavailable">
                                                        <Icon
                                                            className={css({
                                                                padding: '0',
                                                                color: theme.palette.error.main,
                                                            })}
                                                        >
                                                            <PricesUnavaliableIcon />
                                                        </Icon>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                })}
                            >
                                <Typography variant={'subtitle1'}>
                                    {`Avg Deal Rate: ${displayRate(currentSI.dealRate)}`}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {`Avg Effective Rate: ${displayRate(currentSI.avgEffectiveRate)}`}
                                </Typography>
                            </div>
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                })}
                            >
                                <Typography variant={'subtitle1'}>
                                    {`Avg Costing Rate: ${displayRate(currentSI.avgCostingRate)}`}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {`Avg Capture Rate: ${displayRate(currentSI.avgCaptureRate)}`}
                                </Typography>
                            </div>
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                })}
                            >
                                <Typography variant={'subtitle1'}>
                                    {`P/L Costing: ${displayCurrencyAmount(currentSI.pnl?.withCosting)}`}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {`P/L Capture: ${displayCurrencyAmount(currentSI.pnl?.withCapture)}`}
                                </Typography>
                            </div>
                        </div>
                        <DividingLine theme={theme} />
                        {showRates && (
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingBottom: '5px',
                                })}
                            >
                                <Typography variant={'h6'}>Trades</Typography>
                            </div>
                        )}
                        {(() => {
                            if (Object.keys(tradesSummaryMap).length === 0) {
                                return (
                                    <Typography key={'empty'} style={{ textAlign: 'center' }} variant={'overline'}>
                                        {'No entries'}
                                    </Typography>
                                );
                            }
                            return (
                                <div>
                                    {Object.keys(tradesSummaryMap).length === 0 && (
                                        <Typography key={'empty'} style={{ textAlign: 'center' }} variant={'overline'}>
                                            {'No entries'}
                                        </Typography>
                                    )}
                                    {Object.keys(tradesSummaryMap).length !== 0 && (
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr ${
                                                    displayLocalCurrency ? '1fr' : ''
                                                } ${!readOnly ? '0.2fr' : ''} 1fr`,
                                                padding: '0 10px',
                                            }}
                                        >
                                            <Typography variant={'caption'} style={{ fontWeight: 600 }}>
                                                {'Reference'}
                                            </Typography>
                                            <Typography variant={'caption'} style={{ fontWeight: 600 }}>
                                                {'Type'}
                                            </Typography>
                                            <Typography variant={'caption'} style={{ fontWeight: 600 }}>
                                                {'Effective Rate'}
                                            </Typography>
                                            <Typography variant={'caption'} style={{ fontWeight: 600 }}>
                                                {'Deal Rate'}
                                            </Typography>
                                            <Typography
                                                variant={'caption'}
                                                style={{ fontWeight: 600 }}
                                            >{`Amount ${currencyIsoCode}`}</Typography>
                                            {displayLocalCurrency && (
                                                <Typography
                                                    variant={'caption'}
                                                    style={{ fontWeight: 600 }}
                                                >{`Amount ${localCurrencyIsoCode}`}</Typography>
                                            )}
                                            <Typography variant={'caption'} style={{ fontWeight: 600 }}>
                                                {'Bank'}
                                            </Typography>
                                        </div>
                                    )}
                                    {Object.keys(tradesSummaryMap).map((tradeId) => (
                                        <div
                                            key={tradeId}
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr ${
                                                    displayLocalCurrency ? '1fr' : ''
                                                } ${!readOnly ? '0.2fr' : ''} 1fr`,
                                                padding: '0 10px',
                                            }}
                                        >
                                            <Typography variant={'caption'}>
                                                {tradesSummaryMap[tradeId].externalReference}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {tradesSummaryMap[tradeId].type}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {displayRate(tradesSummaryMap[tradeId].effectiveRate)}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {displayRate(tradesSummaryMap[tradeId].allInRate)}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {displayAmount(tradesSummaryMap[tradeId].amount)}
                                            </Typography>
                                            {displayLocalCurrency && (
                                                <Typography variant={'caption'}>
                                                    {displayAmount(tradesSummaryMap[tradeId].localAmount)}
                                                </Typography>
                                            )}
                                            <Typography variant={'caption'}>
                                                {tradesSummaryMap[tradeId].bank}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            );
                        })()}
                    </>
                )}
                {!showRates && (
                    <>
                        <div
                            className={css({
                                display: 'flex',
                                flexDirection: 'column',
                            })}
                        >
                            <Typography variant={'subtitle1'}>{processUnixDateForViewing(currentSI.date)}</Typography>
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                })}
                            >
                                <Typography variant={'subtitle1'}>
                                    {`${currencyIsoCode} Total: ${displayCurrencyAmount(total)}`}
                                </Typography>
                                {displayLocalCurrency && (
                                    <div>
                                        {localTotal !== 0 ? (
                                            <Typography variant={'subtitle1'}>
                                                {`ZAR Total: ${displayCurrencyAmount(localTotal)}`}
                                            </Typography>
                                        ) : localToSIMTMRate ? (
                                            <Typography variant={'subtitle1'}>
                                                {`ZAR Total: ${displayCurrencyAmount(total * localToSIMTMRate)}`}
                                            </Typography>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'auto 20px',
                                                }}
                                            >
                                                <div>
                                                    <Typography variant={'caption'}>{'ZAR Total:'}</Typography>
                                                </div>
                                                <div
                                                    className={css({
                                                        textAlign: 'right',
                                                        minWidth: 120,
                                                    })}
                                                >
                                                    <Tooltip title="Prices Unavailable">
                                                        <Icon
                                                            className={css({
                                                                padding: '0',
                                                                color: theme.palette.error.main,
                                                            })}
                                                        >
                                                            <PricesUnavaliableIcon />
                                                        </Icon>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <DividingLine theme={theme} />
                {(() => {
                    if (Object.keys(invoiceRequestedAmountCounterpartySummaryMap).length === 0) {
                        return (
                            <Typography key={'empty'} style={{ textAlign: 'center' }} variant={'overline'}>
                                {'No entries'}
                            </Typography>
                        );
                    }
                    return (
                        <div>
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingBottom: '5px',
                                })}
                            >
                                <Typography variant={'h6'}>Invoices</Typography>
                            </div>
                            {Object.keys(invoiceRequestedAmountCounterpartySummaryMap).map((counterpartyId, idx) => (
                                <InvoiceRequiredAmountCounterpartySummaryItem
                                    SI={initialSI}
                                    tradeWeightedAvgEffectiveRate={weightedAvgEffRate}
                                    counterpartySummary={invoiceRequestedAmountCounterpartySummaryMap[counterpartyId]}
                                    displayLocalCurrency={displayLocalCurrency}
                                    invoiceTradeLinks={currentSI.invoiceTradeLinks}
                                    key={idx}
                                    localCurrencyISO={localCurrencyIsoCode}
                                    onInvoiceRemove={(id: string) =>
                                        onInvoiceRemove ? onInvoiceRemove(id) : undefined
                                    }
                                    onInvoiceSelect={handleInvoiceSelect}
                                    rate={localToSIMTMRate}
                                    readOnly={readOnly}
                                    siCurrencyISO={currencyIsoCode}
                                    showRates={showRates}
                                />
                            ))}
                        </div>
                    );
                })()}
            </div>
        );
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>
                {viewInvoice && selectedInvoice && (
                    <InvoiceDetailDialog
                        show={true}
                        invoice={selectedInvoice}
                        onClose={handleHideInvoiceDetail}
                        readOnly
                        counterparties={[]}
                    />
                )}
            </React.Fragment>
        );
    };

    const renderLoading = () => {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'grid',
                    alignContent: 'center',
                    paddingBottom: '30px',
                }}
            >
                <ComponentLevelLoader color={'black'} isLoading />
            </div>
        );
    };

    const actions = [];
    if (readOnly && currentSI.status === Status.Complete) {
        actions.push(
            <ActionButton
                disabled={false}
                helpText={'Download Confirmation'}
                icon={<GetApp />}
                id={'download-details'}
                onClick={() => handlePDFDownloadSummary()}
            />,
        );
    }

    return (
        <div>
            <ActionsMenu
                id={'download-list'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'pdf-download',
                        text: 'PDF Document',
                        icon: <PictureAsPdf style={{ paddingRight: '10px' }} />,
                        onClick: () => handlePDFDownloadSummary(),
                    },
                    {
                        id: 'excel-download',
                        text: 'Excel Sheet',
                        icon: <FileCopy style={{ paddingRight: '10px' }} />,
                        onClick: () => handleExcelDownloadSummary(),
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'Download Confirmation'}
            />
            <StandardCard
                cardHeaderProps={{
                    // fullHeight: true,
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/Summary/trades-title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Summary',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Summary/trades-title',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Confirmation',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined),
                            hide: !(readOnly && currentSI.status === Status.Complete),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'close',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Close',
                            onClick: onClose,
                            hide: !readOnly,
                        },
                    ],
                }}
            >
                <div className={css({ height: '75vh', overflow: 'auto' })}>{renderContent()}</div>
                {renderDialogs()}
            </StandardCard>
        </div>
    );
};

export default Summary;

const InvoiceRequiredAmountCounterpartySummaryItem = (props: {
    counterpartySummary: InvoiceRequestedAmountCounterpartySummary;
    tradeWeightedAvgEffectiveRate: string;
    displayLocalCurrency: boolean;
    readOnly?: boolean;
    onInvoiceRemove: (invoiceId: string) => void;
    onInvoiceSelect: (invoice: Invoice) => void;
    siCurrencyISO: string;
    localCurrencyISO: string;
    invoiceTradeLinks: InvoiceTradeLink[] | undefined;
    rate: number | undefined;
    SI: SettlementInstruction;
    showRates?: boolean;
}) => {
    const {
        counterpartySummary,
        displayLocalCurrency,
        readOnly,
        onInvoiceRemove: onInvoiceDeselect,
        onInvoiceSelect,
        siCurrencyISO,
        localCurrencyISO,
        invoiceTradeLinks,
        SI,
        showRates,
        tradeWeightedAvgEffectiveRate,
    } = props;

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();

    return (
        <div
            className={css({
                display: 'grid',
                gridTemplateRows: '30px auto auto 30px',
                paddingBottom: '20px',
            })}
        >
            <Typography
                variant={'subtitle1'}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {counterpartySummary.counterparty.name}
            </Typography>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: `1fr 1fr 1fr 1fr ${displayLocalCurrency ? '1fr' : ''} ${
                        showRates ? '1fr 1fr 1fr' : ''
                    }
                     ${!readOnly ? '0.2fr' : ''}`,
                    padding: '0 10px',
                }}
            >
                <Typography variant={'caption'}>{'Number'}</Typography>
                {showRates && <Typography variant={'caption'}>{'Direction'}</Typography>}
                <Typography variant={'caption'}>{'Due Date'}</Typography>
                {showRates && <Typography variant={'caption'}>{'Costing Rate'}</Typography>}
                {showRates && <Typography variant={'caption'}>{'Capture Rate'}</Typography>}
                <Typography variant={'caption'}>{'Trade Effective Rate'}</Typography>
                <Typography variant={'caption'} style={{ textAlign: 'right' }}>{`Amount ${siCurrencyISO}`}</Typography>
                {displayLocalCurrency && (
                    <Typography
                        variant={'caption'}
                        style={{ textAlign: 'right' }}
                    >{`Amount ${localCurrencyISO}`}</Typography>
                )}
            </div>
            <div>
                {counterpartySummary.invoicesAndInvoiceAmountsRequested.map((li, idx) => {
                    const sign = getSignBasedOnType(li.invoice);
                    const fxAmount = sign * li.amountRequested.amount;
                    // determine local amount
                    let localAmount = 0.0;
                    switch (SI.status) {
                        case Status.Draft:
                        case Status.Submitted:
                            localAmount = props.rate ? sign * li.amountRequested.amount * props.rate : 0.0;
                            break;
                        case Status.Complete:
                            {
                                const links = invoiceTradeLinks?.filter(
                                    (invoiceTradLink: InvoiceTradeLink) =>
                                        invoiceTradLink.invoiceId === li.amountRequested.invoiceId,
                                );
                                if (links !== undefined && links.length > 0) {
                                    localAmount = calculateLocalTotal(sign, links);
                                }
                            }
                            break;
                    }
                    return (
                        <div
                            className={css({
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                margin: '0',
                                borderRadius: '4px',
                                fontSize: '12px',
                                '&:hover': {
                                    backgroundColor: theme.palette.background.default,
                                },
                            })}
                            key={idx}
                            onClick={() => onInvoiceSelect(li.invoice)}
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                gridTemplateColumns: `1fr 1fr 1fr 1fr ${displayLocalCurrency ? '1fr' : ''} ${
                                    showRates ? '1fr 1fr 1fr' : ''
                                } ${!readOnly ? '0.2fr' : ''}`,
                                padding: '0 10px',
                            }}
                        >
                            <div>{li.invoice.number}</div>
                            {showRates && <div>{li.invoice.direction}</div>}
                            <div>{processUnixDateForViewing(li.invoice.dueDate)}</div>
                            {showRates && <div>{displayRate(li.invoice.costingRate)}</div>}
                            {showRates && <div>{displayRate(li.invoice.captureRate)}</div>}
                            <div>{tradeWeightedAvgEffectiveRate}</div>
                            <div style={{ textAlign: 'right' }}>{displayAmount(Big(fxAmount))}</div>
                            {displayLocalCurrency && (
                                <div
                                    className={css({
                                        textAlign: 'right',
                                        minWidth: 120,
                                    })}
                                >
                                    {displayAmount(Big(localAmount))}
                                </div>
                            )}
                            {!readOnly && (
                                <div>
                                    <IconButton
                                        size={'small'}
                                        onClick={(e) => {
                                            onInvoiceDeselect(li.invoice.id || '');
                                            e.stopPropagation();
                                        }}
                                    >
                                        <DeselectIcon fontSize={'small'} />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <DividingLine theme={theme} />
            <div
                className={css({
                    fontWeight: 'bold',
                    display: 'grid',
                    gridTemplateColumns: `1fr 1fr 1fr 1fr ${showRates ? '1fr 1fr 1fr' : ''} ${
                        displayLocalCurrency ? '1fr' : ''
                    } ${!readOnly ? '0.2fr' : ''}`,
                    padding: '0 10px',
                })}
            >
                <div>{/* empty cell placeholder */}</div>
                <div>{/* empty cell placeholder */}</div>
                {showRates && <div>{/* empty cell placeholder */}</div>}
                {showRates && <div>{/* empty cell placeholder */}</div>}
                {showRates && <div>{/* empty cell placeholder */}</div>}
                <div>{/* empty cell placeholder */}</div>
                <div style={{ textAlign: 'right' }}>{displayCurrencyAmount(counterpartySummary.total)}</div>
                {displayLocalCurrency && (
                    <div
                        className={css({
                            textAlign: 'right',
                        })}
                    >
                        {counterpartySummary.localTotal !== 0 ? (
                            displayCurrencyAmount(counterpartySummary.localTotal)
                        ) : props.rate ? (
                            displayCurrencyAmount(counterpartySummary.total * props.rate)
                        ) : (
                            <Tooltip title="Prices Unavailable">
                                <Icon
                                    className={css({
                                        padding: '0',
                                        color: theme.palette.error.main,
                                    })}
                                >
                                    <PricesUnavaliableIcon />
                                </Icon>
                            </Tooltip>
                        )}
                    </div>
                )}
                {!readOnly && <div>{/* empty cell placeholder */}</div>}
            </div>
        </div>
    );
};
