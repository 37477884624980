import React, { ChangeEvent, ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Card, FormControl, MenuItem } from '@material-ui/core';
import { LightNumberField, LightSelect, LightTextField } from './styledComponents';
import { BillingType } from 'popcorn-js/tradeV2';
import Big from 'big.js';

export const BillingInformation = ({
    bankRate,
    handleBankRateChange,
}: {
    bankRate: Big | undefined;
    handleBankRateChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<Big | undefined>>>;
}): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect label={'Billing Type'} disabled style={{ width: '100%' }}>
                            <MenuItem key={BillingType.ClientBilling} value={BillingType.ClientBilling}>
                                {'Client'}
                            </MenuItem>
                            <MenuItem key={BillingType.BankBilling} value={BillingType.BankBilling}>
                                {'Bank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Bank Rate'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleBankRateChange(event.target.value ? Big(event.target.value) : undefined)
                        }
                        value={bankRate ? bankRate.toFixed(4) : ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField label={'Intermediary Margin'} disabled style={{ width: '100%' }} />
                    <LightTextField label={'Margin Notes'} disabled style={{ width: '100%' }} />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField label={'Fee'} disabled style={{ width: '100%' }} />
                    <LightNumberField id={'adminFee'} label={'Admin Fee'} disabled style={{ width: '100%' }} />
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
