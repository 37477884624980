import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { Collapse, useTheme } from '@material-ui/core';
import { Client } from 'popcorn-js/party';
import { useServiceSync } from 'hooks/useService';
import DetailDialog from 'components/tradeV2/TradeDetailDialog';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { ImportExport, PartyType, Trade, TradeDirection, TradeStatus, TradeType } from 'popcorn-js/tradeV2';
import { displayAmount, displayDate, displayRate, displayRateSixDecimalPlaces, getMidDay } from 'utils';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, Item, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import { Handler } from 'popcorn-js/tradeV2/handler';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { Error as ErrorIcon } from '@material-ui/icons';
import Table from 'components/Table/Table';
import { AppContext, AppContextT } from 'context';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { isEqual, isValid, parseISO } from 'date-fns';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { Counterparty } from 'popcorn-js/counterparty';
import { Handler as Clienthandler } from 'popcorn-js/party/client/handler';
import { SwitchContextLink } from './SwitchContextLink';
import TradeTicket from 'components/tradeV2/ticket/ParentTicket';

const importExportOptions = [{ value: ImportExport.IMPORT }, { value: ImportExport.EXPORT }];

const tradeStatuses = [
    {
        value: TradeStatus.OPEN,
        label: TradeStatus.OPEN,
    },
    {
        value: TradeStatus.MATURED,
        label: TradeStatus.MATURED,
    },
];
const tradeDirections = [
    {
        value: TradeDirection.BUY,
        label: TradeDirection.BUY,
    },
    {
        value: TradeDirection.SELL,
        label: TradeDirection.SELL,
    },
];

const HedgeMaturingTable: React.FC = () => {
    const appContext = useContext<AppContextT>(AppContext);
    const serviceContextValue = useContext<ServiceContextT>(ServiceContext);
    const currencyPairs = appContext.currencyPairs || [];
    const partyType = appContext.partyType || PartyType.CLIENT;
    // services
    const [find] = useServiceSync<FindRequest, FindResponse<Trade>>(Handler.Find);
    const [clientFind] = useServiceSync<FindRequest, FindResponse<Client>>(Clienthandler.find);
    // notifications
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    // state
    const [selectedCurrencyPair, setSelectedCurrencyPair] = useState<CurrencyPair | undefined>();
    const [direction, setDirection] = useState<TradeDirection | undefined>();
    const [open, setOpen] = useState<boolean>(true);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
    const [ticketState, setTicketState] = useState<number>(ticketStates.nop);
    const [selected, setSelected] = React.useState<Trade[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [tradesResponse, setTradesResponse] = useState<Trade[] | undefined>();
    const [query, setQuery] = useState<Query>({
        sortBy: ['maturityDate', 'availableBalance.value', 'currencyPair'],
        order: ['asc', 'desc', 'asc'],
        limit: 50,
        offset: 0,
    });
    const [criteria, setCriteria] = useState<Criteria>([
        {
            type: CriteriaType.TimeCriterion,
            field: 'maturityDate',
            startDate: {
                ignore: false,
                date: moment().local().startOf('day').toISOString(),
                inclusive: true,
            },
            endDate: {
                ignore: true,
                date: new Date().toISOString(),
                inclusive: true,
            },
        },
        {
            type: CriteriaType.NumberCriterion,
            field: 'availableBalance.value',
            lessThan: {
                ignore: true,
            },
            moreThan: {
                amount: 0.0004,
                inclusive: false,
            },
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.FORWARD,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.EXTENSION,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.SPOT,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.DRAWDOWN,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.SWAP,
        },
        {
            type: CriteriaType.BoolCriterion,
            field: 'cancellation',
            value: false,
        },
    ]);
    // funcs
    const findTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await find({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setTradesResponse(result.records);
            setTradesTotal(result.total);
            const clients = await findTradesTradingParties(result.records);
            setTradesResponse(
                result.records.map((i: Trade) => ({
                    ...i,
                    counterparty: clients.find((c: Client) => c.partyCode === i.tradingPartyCode)?.name,
                })),
            );
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };
    const findTradesTradingParties = async (_trades: Trade[]): Promise<Client[]> => {
        const counterpartyFindCrit: Criteria = _trades
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'partyCode', text: b.tradingPartyCode }));
        const response = await clientFind({
            criteria: counterpartyFindCrit,
        });
        return response.records;
    };

    const handleCloseDialog = async () => {
        setDetailDialogOpen(false);
        setSelected([]);
        await findTrades(criteria, query);
    };

    const closeTicket = async () => {
        setTicketState(ticketStates.nop);
        setSelected([]);
        await findTrades(criteria, query);
    };

    const handleTabChange = (newValue: number) => {
        setShowFilterRow(false);
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                handleFilterChange([
                    {
                        type: CriteriaType.TimeCriterion,
                        field: 'maturityDate',
                        startDate: {
                            ignore: false,
                            date: moment().local().startOf('day').toISOString(),
                            inclusive: true,
                        },
                        endDate: {
                            ignore: true,
                            date: new Date().toISOString(),
                            inclusive: true,
                        },
                    },
                ]);
                break;
            case 1: {
                const now = moment().local().endOf('day');
                handleFilterChange([
                    {
                        type: CriteriaType.TimeCriterion,
                        field: 'maturityDate',
                        startDate: {
                            ignore: true,
                            date: new Date().toISOString(),
                            inclusive: true,
                        },
                        endDate: {
                            ignore: false,
                            date: moment(now).subtract(1, 'days').toISOString(),
                            inclusive: false,
                        },
                    },
                ]);
            }
        }
    };

    const handleFilterChange = useCallback(
        debounce(async (newCriteria: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            const tabCriteria = newCriteria.filter((f: Criterion) => f.field === 'maturityDate');
            if (tabCriteria.length === 0) {
                if (allCriteria.length > 0) {
                    {
                        if (financialYears[0] === 'ALL') {
                            newCriteria.push({
                                type: CriteriaType.TextCriterion,
                                field: 'financialYear',
                                text: '',
                            });
                        }
                    }
                }
                switch (tabValue) {
                    case 0:
                        newCriteria.push({
                            type: CriteriaType.TimeCriterion,
                            field: 'maturityDate',
                            startDate: {
                                ignore: false,
                                date: moment().local().startOf('day').toISOString(),
                                inclusive: true,
                            },
                            endDate: {
                                ignore: true,
                                date: new Date().toISOString(),
                                inclusive: true,
                            },
                        });
                        break;
                    case 1: {
                        const now = moment().local().endOf('day');
                        newCriteria.push({
                            type: CriteriaType.TimeCriterion,
                            field: 'maturityDate',
                            startDate: {
                                ignore: true,
                                date: new Date().toISOString(),
                                inclusive: true,
                            },
                            endDate: {
                                ignore: false,
                                date: moment(now).subtract(1, 'days').toISOString(),
                                inclusive: false,
                            },
                        });
                    }
                }
            }
            const tradeTypeCriteria = newCriteria.filter((f: Criterion) => f.field === 'type');
            if (tradeTypeCriteria.length === 0) {
                newCriteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: TradeType.FORWARD,
                });
                newCriteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: TradeType.SPOT,
                });
                newCriteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: TradeType.DRAWDOWN,
                });
                newCriteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: TradeType.EXTENSION,
                });
                newCriteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: TradeType.SWAP,
                });
            }
            const tradeTotalCriteria = newCriteria.filter((f: Criterion) => f.field === 'availableBalance.value');
            if (tradeTotalCriteria.length > 0) {
                const amount = get(tradeTotalCriteria[0], 'moreThan.amount');
                if (amount < 0.0004) {
                    tradeTotalCriteria.forEach(
                        (f: Criterion) =>
                            f.type === CriteriaType.NumberCriterion &&
                            (f.moreThan = {
                                amount: 0.0004,
                                inclusive: false,
                                ignore: false,
                            }),
                    );
                }
            } else {
                newCriteria.push({
                    type: CriteriaType.NumberCriterion,
                    field: 'availableBalance.value',
                    lessThan: {
                        ignore: true,
                    },
                    moreThan: {
                        amount: 0.0004,
                        inclusive: false,
                    },
                });
            }
            newCriteria.push({
                type: CriteriaType.BoolCriterion,
                field: 'cancellation',
                value: false,
            });
            setQuery(newQuery);
            setCriteria(newCriteria);
            await findTrades(newCriteria, newQuery);
        }, 100),
        [query],
    );

    const handleCancelClick = (trade: Trade) => {
        const newSelectedCurrencyPair = currencyPairs.find((c) => c.name === trade.currencyPair);
        let newDirection: TradeDirection = TradeDirection.BUY;
        if (trade.direction == TradeDirection.BUY) {
            newDirection = TradeDirection.SELL;
        }
        setSelectedCurrencyPair(newSelectedCurrencyPair);
        setDirection(newDirection);
        setTicketState(ticketStates.showingTradeCancellation);
    };
    const renderTickets = (): React.ReactElement => {
        switch (ticketState) {
            case ticketStates.showingTradeExtension:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeExtension}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        tradeType={TradeType.EXTENSION}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeCancellation:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeCancellation}
                        tradeType={TradeType.CANCELLATION}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeDrawdown:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeDrawdown}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        tradeType={TradeType.DRAWDOWN}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeNew:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeNew}
                        initialDirection={direction}
                        currencyPairToTrade={selectedCurrencyPair}
                        tradeType={TradeType.SPOT}
                        parentTrades={[]}
                        optionExercise={false}
                        autoFillMode
                    />
                );
            case ticketStates.showingTradeSwap:
                if (selectedCurrencyPair) {
                    /// TODO
                    return (
                        <ServiceContext.Provider value={{ ...serviceContextValue, tradeV2Handler: Handler }}>
                            <TradeTicket
                                closeTicket={closeTicket}
                                show={!!ticketStates.showingTradeSwap}
                                initialDirection={TradeDirection.BUY}
                                currencyPairToTrade={selectedCurrencyPair}
                                parentTrades={[]}
                                tradeType={TradeType.SWAP}
                                optionExercise={false}
                                autoFillMode={true}
                            />
                        </ServiceContext.Provider>
                    );
                } else {
                    return <span />;
                }
            case ticketStates.nop:
                return <span />;
            default:
                return <span />;
        }
    };

    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: [
                {
                    id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/FECsMaturing-available',
                    label: 'FECs Maturing',
                    value: 'FECs Maturing',
                },
                {
                    id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/FECs-matured-&-unlinked',
                    label: 'FECs Unlinked',
                    value: 'FECs Unlinked',
                },
            ],
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                handleTabChange(value);
            },
            value: tabValue,
            id: 'FECs-tabs',
        },
    ];

    const generateRowActions = (): Item[] => {
        const ExtendActionErrors: string[] = [];
        const DrawDownActionErrors: string[] = [];
        const CancellationErrors: string[] = [];

        if (partyType !== PartyType.CLIENT) {
            return [];
        }

        if (!(selected && selected.length > 0)) {
            return [];
        }

        const firstDirection = selected[0].direction;
        const firstMaturity = selected[0].maturityDate;
        const firstImportExport = selected[0].importExport;
        const firstBank = selected[0].bank;
        const firstCurrencyPair = selected[0].currencyPair;

        selected.forEach((selectedRow: Trade) => {
            if (selectedRow.availableBalance && selectedRow.availableBalance <= 0) {
                ExtendActionErrors.push('No Balance left');
                DrawDownActionErrors.push('No balance left');
            }
            if (selectedRow.direction !== firstDirection) {
                const msg = 'Directions must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }

            if (
                selectedRow.maturityDate &&
                firstMaturity &&
                isValid(parseISO((firstMaturity as unknown) as string)) &&
                isValid(parseISO((selectedRow.maturityDate as unknown) as string))
            ) {
                if (
                    !isEqual(
                        getMidDay(parseISO((selectedRow.maturityDate as unknown) as string)),
                        getMidDay(parseISO((firstMaturity as unknown) as string)),
                    )
                ) {
                    const msg = 'Maturity dates must be the same';
                    CancellationErrors.push(msg);
                }
            } else {
                const msg = 'Maturity dates not supplied';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.importExport !== firstImportExport) {
                const msg = 'Import/export must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.bank !== firstBank) {
                const msg = 'Trade banks must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.currencyPair !== firstCurrencyPair) {
                const msg = 'Trade currency pairs must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
        });

        let items: Item[] = [] as Item[];

        if (selected.length === 1) {
            items = [
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/view-details',
                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                    helpText: 'View Details',
                    onClick: () => setDetailDialogOpen(true),
                },
            ];
        }

        const canCancel = CancellationErrors.length === 0;
        const canExtend = ExtendActionErrors.length === 0;
        const canDrawdown = DrawDownActionErrors.length === 0;

        const drawdownTypes: (TradeType | undefined)[] = [
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const drawdownActionVisible = selected.every((trade: Trade) => drawdownTypes.includes(trade.type));
        if (drawdownActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/perform-drawdown',
                icon: ACTION_BUTTON_TYPE.DRAW_DOWN,
                helpText: canDrawdown ? 'Perform Drawdown' : 'Cannot Perform Drawdown, ' + DrawDownActionErrors.join(),
                onClick: () => {
                    if (canDrawdown) {
                        setTicketState(ticketStates.showingTradeDrawdown);
                    }
                },
            });
        }
        const extensionTypes: (TradeType | undefined)[] = [
            TradeType.DRAWDOWN,
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const extensionActionVisible = selected.every((trade: Trade) => extensionTypes.includes(trade.type));
        if (extensionActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/perform-extension',
                icon: ACTION_BUTTON_TYPE.EXTEND,
                helpText: canExtend ? 'Perform Extension' : 'Cannot Perform Extension, ' + ExtendActionErrors.join(),
                onClick: () => {
                    if (canExtend) {
                        setTicketState(ticketStates.showingTradeExtension);
                    }
                },
            });
        }

        const cancellationTypes: (TradeType | undefined)[] = [
            TradeType.DRAWDOWN,
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const cancellationActionVisible = selected.every((trade: Trade) => cancellationTypes.includes(trade.type));
        if (cancellationActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/perform-cancellation',
                icon: ACTION_BUTTON_TYPE.CANCEL,
                helpText: canCancel
                    ? 'Perform Cancellation'
                    : 'Cancellation parents Invalid, ' + CancellationErrors.join(),
                onClick: () => {
                    if (canCancel) {
                        handleCancelClick(selected[0]);
                    }
                },
            });
        }

        return items;
    };

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findTrades(criteria, newQuery).finally();
    };

    const handleChangeSorting = async (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        await findTrades(criteria, newQuery);
    };

    const handleSelectRow = (rowData: Trade) => {
        const ind = selected.findIndex((selectedTrd: Trade) => selectedTrd.id === rowData.id);
        const _selected = [...selected];
        if (ind === -1) {
            _selected.push(rowData);
        } else {
            _selected.splice(ind, 1);
        }
        setSelected(_selected);
    };

    const handleSelectAll = () => {
        const newSelected = [...selected];
        if (newSelected.length !== 0) {
            setSelected([]);
        } else if (tradesResponse) {
            tradesResponse.forEach((trade: Trade) => {
                newSelected.push(trade);
            });
            setSelected(newSelected);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const generateClientOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await clientFind({
                criteria,
                query: { sortBy: ['dueDate'], order: ['asc'], limit: 20, offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const amendSuccess = async () => {
        if (!selected?.length) {
            return;
        }
        const seletectedTradeId = selected[0].id;
        await findTrades(criteria, query);
        const selectedTrades = tradesResponse?.filter((trd) => trd.id === seletectedTradeId);
        const selectedTrade = selectedTrades?.length === 1 ? selectedTrades[0] : undefined;
        if (!selectedTrade) {
            handleCloseDialog();
            return;
        }
        setSelected([selectedTrade]);
    };

    return (
        <div id="hedgesManagementStationRoot">
            {detailDialogOpen && (
                <DetailDialog
                    closeDialog={handleCloseDialog}
                    open={detailDialogOpen}
                    trade={selected[0]}
                    amendSuccess={amendSuccess}
                />
            )}
            {renderTickets()}
            <StandardCard
                cardHeaderProps={{
                    selectedItems: selected.length,
                    tailoredState: selected.length > 0 ? STATES.SELECTED_ROW : undefined,
                    itemsLeft,
                    itemsRight: [
                        ...generateRowActions(),
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                handleFilterChange(criteria);
                                setShowFilterRow(!showFilterRow);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: 'Column Configuration',
                            onClick: () => setColConfigOpen(!colConfigOpen),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'ConsolidatedHedgeManagementStation/HedgeMaturingTable/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    {((): React.ReactNode => {
                        if (errorMessage) {
                            return (
                                <div>
                                    <ErrorIcon />
                                    <div style={{ textAlign: 'center' }}>{errorMessage}</div>
                                </div>
                            );
                        } else {
                            return (
                                <React.Fragment>
                                    <Table
                                        colConfigCloseFromCard={handleCloseColConfig}
                                        colConfigOpenFromCard={colConfigOpen}
                                        columns={[
                                            {
                                                title: 'Client',
                                                filter: {
                                                    asyncOptionsFetcher: generateClientOptions,
                                                    displayAccessor: 'name',
                                                    valueAccessor: 'partyCode',
                                                    type: CriteriaType.TextCriterion,
                                                },
                                                /* eslint-disable react/display-name */
                                                render: (rowData: Trade) => (
                                                    <SwitchContextLink
                                                        counterparty={rowData.counterparty || ''}
                                                        partyCode={rowData.tradingPartyCode || ''}
                                                    />
                                                ),
                                                field: 'tradingPartyCode',
                                            },

                                            {
                                                title: 'Trade Number',
                                                field: 'number',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.number) {
                                                        return rowData.number;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'External Ref',
                                                field: 'externalReference',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData) {
                                                        return rowData.externalReference;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Transaction ID',
                                                field: 'transactionID',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.transactionID) {
                                                        return rowData.transactionID;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Type',
                                                field: 'type',
                                                filter: {
                                                    type: CriteriaType.TextCriterion,
                                                    options: tradeTypesForColumn,
                                                    displayAccessor: 'value',
                                                    valueAccessor: 'value',
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData) {
                                                        return rowData.type;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Date',
                                                field: 'tradeDate',
                                                filter: { type: CriteriaType.TimeCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.tradeDate) {
                                                        return displayDate((rowData.tradeDate as unknown) as string);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Status',
                                                field: 'status',
                                                filter: {
                                                    options: tradeStatuses,
                                                    displayAccessor: 'value',
                                                    valueAccessor: 'value',
                                                    type: CriteriaType.TextCriterion,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.status) {
                                                        return rowData.status;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Currency',
                                                field: 'currencyPair',
                                                filter: {
                                                    options: currencyPairs,
                                                    displayAccessor: 'name',
                                                    valueAccessor: 'name',
                                                    type: CriteriaType.TextCriterion,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.currencyPair) {
                                                        return rowData.currencyPair;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'IM/EX',
                                                field: 'importExport',
                                                filter: {
                                                    type: CriteriaType.TextCriterion,
                                                    displayAccessor: 'value',
                                                    valueAccessor: 'value',
                                                    options: importExportOptions,
                                                },
                                                // eslint-disable-next-line react/display-name
                                                render: (rowData: Trade) => <StyledImExValue trade={rowData} />,
                                            },
                                            {
                                                title: 'ACM Trade',
                                                field: 'acm',
                                                filter: { type: CriteriaType.BoolCriterion },
                                                render: (rowData: Trade) => {
                                                    return rowData.acm ? 'TRUE' : 'FALSE';
                                                },
                                            },
                                            {
                                                title: 'Financial Year',
                                                field: 'financialYear',
                                                filter: {
                                                    options: financialYears.map((f) => ({ name: f })),
                                                    displayAccessor: 'name',
                                                    valueAccessor: 'name',
                                                    type: CriteriaType.TextCriterion,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.financialYear) {
                                                        return rowData.financialYear;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Bank',
                                                field: 'bank',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    return rowData.bank;
                                                },
                                            },
                                            {
                                                title: 'Direction',
                                                field: 'direction',
                                                filter: {
                                                    type: CriteriaType.TextCriterion,
                                                    displayAccessor: 'value',
                                                    valueAccessor: 'value',
                                                    options: tradeDirections,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.direction) {
                                                        return rowData.direction;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Maturity',
                                                field: 'maturityDate',
                                                filter: { type: CriteriaType.TimeCriterion },
                                                render: (rowData: Trade) => <StyledMaturingValue t={rowData} />,
                                            },
                                            {
                                                title: 'Trade Date',
                                                field: 'tradeDate',
                                                render: (rowData: Trade) => {
                                                    if (rowData.tradeDate) {
                                                        return displayDate((rowData.tradeDate as unknown) as string);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                                filter: { type: CriteriaType.TimeCriterion },
                                            },
                                            {
                                                title: 'Avail Balance',
                                                field: 'availableBalance.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    return displayAmount(rowData.availableBalance);
                                                },
                                            },
                                            {
                                                title: 'ACM Balance',
                                                field: 'acmBalance.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.acmBalance) {
                                                        return displayAmount(rowData.acmBalance);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Captured Spot Rate',
                                                field: 'capturedSpotRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.bankRate) {
                                                        return rowData.bankRate.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Interbank Rate',
                                                field: 'interbankRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.interbankRate) {
                                                        return rowData.interbankRate.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Bank Rate',
                                                field: 'bankRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.bankRate) {
                                                        return rowData.bankRate.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Quote Amount',
                                                field: 'quoteAmount.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.quoteAmount) {
                                                        return displayAmount(rowData.quoteAmount);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Notional',
                                                field: 'notionalAmount.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData) {
                                                        return displayAmount(rowData.notionalAmount);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Spot Price',
                                                field: 'spotPrice.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.spotPrice) {
                                                        return rowData.spotPrice.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Deal Rate',
                                                field: 'dealRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData) {
                                                        const retVal = displayRateSixDecimalPlaces(rowData.dealRate);
                                                        return (
                                                            <span style={{ float: 'left' }}>
                                                                {retVal ? retVal : 'undefined'}
                                                            </span>
                                                        );
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'All-In Rate',
                                                field: 'dealRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.dealRate) {
                                                        return rowData.dealRate.toFixed(6);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'EFF Rate',
                                                field: 'effectiveRate.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData) {
                                                        const retVal = displayRate(rowData.effectiveRate);
                                                        return (
                                                            <span style={{ float: 'left' }}>
                                                                {retVal ? retVal : 'undefined'}
                                                            </span>
                                                        );
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Net Cash Flow',
                                                field: 'netCashFlow.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.netCashFlow) {
                                                        return rowData.netCashFlow.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Realised PnL',
                                                field: 'realisedPnl.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.realisedPnl) {
                                                        return rowData.realisedPnl.toFixed(4);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Trader',
                                                field: 'trader',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.trader) {
                                                        return rowData.trader;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Trader Organisation',
                                                field: 'traderOrganisation',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.traderOrganisation) {
                                                        return rowData.traderOrganisation;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Portfolio Manager',
                                                field: 'portfolioManager',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.portfolioManager) {
                                                        return rowData.portfolioManager;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Relationship Manager',
                                                field: 'relationshipManager',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.relationshipManager) {
                                                        return rowData.relationshipManager;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Notes',
                                                field: 'notes',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.notes) {
                                                        return rowData.notes;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                        ]}
                                        count={tradesTotal}
                                        data={tradesResponse || []}
                                        defaultColConfig={[
                                            { header: 'Client', visible: true },
                                            { header: 'IM/EX', visible: true },
                                            { header: 'Direction', visible: true },
                                            { header: 'External Ref', visible: true },
                                            { header: 'Type', visible: true },
                                            { header: 'Currency', visible: true },
                                            { header: 'Date', visible: true },
                                            { header: 'Notional', visible: true },
                                            { header: 'Avail Balance', visible: true },
                                            { header: 'Maturity', visible: true },
                                            { header: 'Deal Rate', visible: true },
                                            { header: 'EFF Rate', visible: true },
                                            { header: 'Bank', visible: true },
                                            { header: 'Trade Number', visible: false },
                                            { header: 'Transaction ID', visible: false },
                                            { header: 'Status', visible: false },
                                            { header: 'ACM Trade', visible: false },
                                            { header: 'Financial Year', visible: false },
                                            { header: 'Trade Date', visible: false },
                                            { header: 'ACM Balance', visible: false },
                                            { header: 'Captured Spot Rate', visible: false },
                                            { header: 'Interbank Rate', visible: false },
                                            { header: 'Bank Rate', visible: false },
                                            { header: 'Quote Amount', visible: false },
                                            { header: 'Spot Price', visible: false },
                                            { header: 'All-In Rate', visible: false },
                                            { header: 'Net Cash Flow', visible: false },
                                            { header: 'Realised PnL', visible: false },
                                            { header: 'Trader', visible: false },
                                            { header: 'Trader Organisation', visible: false },
                                            { header: 'Portfolio Manager', visible: false },
                                            { header: 'Relationship Manager', visible: false },
                                            { header: 'Notes', visible: false },
                                        ]}
                                        handleChangePage={handleChangePage}
                                        loading={loading}
                                        onChangeSorting={handleChangeSorting}
                                        onFilterChange={handleFilterChange}
                                        onRowCheck={handleSelectRow}
                                        order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                        page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                        rowClickAction={handleSelectRow}
                                        rowDoubleClickAction={(rowData: Trade) => {
                                            setSelected([rowData]);
                                            setDetailDialogOpen(true);
                                        }}
                                        rowsPerPage={query.limit}
                                        rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                        onSelectAll={handleSelectAll}
                                        selected={selected}
                                        showCheckboxes
                                        showFilterRow={showFilterRow}
                                        sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                        tableID={'ConsolidatedHedgeWorkstation-table'}
                                        title={'Trades'}
                                    />
                                </React.Fragment>
                            );
                        }
                    })()}
                </Collapse>
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
        </div>
    );
};

const StyledImExValue = (props: { trade: Trade }) => {
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (props.trade.importExport === 'Import') {
        color = theme.palette.custom.import.main;
    }
    if (props.trade.importExport === 'Export') {
        color = theme.palette.custom.export.main;
    }
    return <span className={css({ color })}>{props.trade.importExport?.toUpperCase()}</span>;
};

const StyledMaturingValue = (props: { t: Trade }) => {
    const maturity = displayDate(props.t.maturityDate?.toString()).slice(0, 10);

    const getDay = new Date();
    const today = getDay.toISOString().slice(0, 10);

    const tomorrow = new Date(getDay);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const nextD = tomorrow.toISOString().slice(0, 10);

    const inTwoDays = new Date(getDay);
    inTwoDays.setDate(inTwoDays.getDate() + 2);
    const twoDaysAfter = inTwoDays.toISOString().slice(0, 10);

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (maturity === today) {
        color = theme.palette.custom.data.overdue.dark;
    }
    if (maturity === nextD || maturity === twoDaysAfter) {
        color = theme.palette.custom.stellcapBrand1.light;
    }
    return (
        <div>
            <span className={css({ color })}>{displayDate(props.t.maturityDate?.toString())}</span>
        </div>
    );
};

const tradeTypesForColumn = [
    { value: TradeType.SPOT },
    { value: TradeType.FORWARD },
    { value: TradeType.EXTENSION },
    { value: TradeType.DRAWDOWN },
    { value: TradeType.SWAP },
];

const ticketStates = {
    nop: 0,
    showingTradeNew: 1,
    showingTradeHistory: 2,
    showingTradeExtension: 3,
    showingTradeDrawdown: 4,
    showingTradeCancellation: 5,
    showingTradeSwap: 6,
};

export default HedgeMaturingTable;
