import React, { ReactElement, useContext } from 'react';
import { AppBar, Divider, IconButton, Toolbar, Tooltip, useTheme } from '@material-ui/core';
import { useStyletron } from 'styletron-react';
import { Autorenew, Menu as MenuIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { appRoutes, ViewRoute } from 'routes';
import dateFormat from 'dateformat';
import { AppContext, AppContextT } from 'context';
import { CustomTheme } from 'theme/custom';
import { LogOutMenu } from 'components/Navbar/LogOutMenu';
import { ConfigurationsMenu } from 'components/Navbar/ConfigurationsMenu';
import { ContextSwitcher } from 'components/Navbar/ContextSwitcher';

const findCurrentRoute = (routes: ViewRoute[]): ViewRoute | undefined => {
    const location = useLocation();
    for (const r of routes) {
        if (r.views && r.views.length !== 0) {
            const cr = findCurrentRoute(r.views || []);
            if (cr) {
                return cr;
            }
        }
        if (r.path === location.pathname) {
            return r;
        }
    }
    return;
};

export const Navbar = (props: {
    onSidebarToggle: () => void;
    sidebarOpenWidth: number;
    sidebarClosedWidth: number;
    sidebarOpen: boolean;
    onContextSwitch: (arg0: string) => void;
    onLogout: () => void;
}): ReactElement => {
    const { onSidebarToggle, sidebarOpen, sidebarOpenWidth, sidebarClosedWidth, onContextSwitch, onLogout } = props;
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();

    const route = findCurrentRoute(appRoutes);

    const breadCrumb = route?.name || '';

    const appContext = useContext<AppContextT>(AppContext);
    const showConfiguration = true;

    const mainColor = appContext.contextSwitched
        ? theme.palette.custom.stellcapBrand2
        : theme.palette.custom.stellcapBrand1;

    return (
        <div className={css({ flexGrow: 1, marginLeft: `${sidebarOpen ? sidebarOpenWidth : sidebarClosedWidth}px` })}>
            <AppBar position="static">
                <Toolbar
                    className={css({
                        backgroundColor: mainColor.main,
                    })}
                >
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={onSidebarToggle}>
                        <MenuIcon
                            className={css({
                                color: mainColor.contrastText,
                            })}
                        />
                    </IconButton>
                    <Divider
                        style={{
                            backgroundColor: mainColor.contrastText,
                            width: '1px',
                            marginRight: theme.spacing(2),
                            marginLeft: theme.spacing(1),
                        }}
                        flexItem
                    />
                    <div
                        className={css({
                            color: mainColor.contrastText,
                            fontWeight: 'bold',
                            fontSize: '12px',
                            width: '180px',
                            alignItems: 'center',
                        })}
                    >
                        {breadCrumb}
                    </div>
                    <Divider
                        style={{
                            backgroundColor: mainColor.contrastText,
                            width: '1px',
                            marginRight: theme.spacing(2),
                            marginLeft: theme.spacing(2),
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        }}
                        flexItem
                    />
                    <div className={css({ flexGrow: 1 })}>
                        <div
                            className={css({
                                color: mainColor.contrastText,
                                fontWeight: 'bold',
                                display: 'grid',
                                gridTemplateColumns: '50px 1fr',
                                alignItems: 'center',
                            })}
                        >
                            <Tooltip placement={'top'} title={'Refresh app'}>
                                <div>
                                    <IconButton className={css({})} onClick={() => window.location.reload()}>
                                        <Autorenew
                                            className={css({
                                                color: mainColor.contrastText,
                                            })}
                                        />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <div
                                className={css({
                                    color: mainColor.contrastText,
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                })}
                            >
                                {'UPDATED:  '}
                                {(() => {
                                    return dateFormat(new Date(), 'HH:MM TT');
                                })()}
                            </div>
                        </div>
                    </div>
                    <div
                        className={css({
                            color: mainColor.contrastText,
                            fontWeight: 'bold',
                            display: 'grid',
                            gridTemplateColumns: 'auto auto',
                            alignItems: 'center',
                        })}
                    >
                        <div
                            className={css({
                                color: mainColor.contrastText,
                                fontWeight: 'bold',
                                display: 'grid',
                                gridTemplateRows: 'auto auto',
                                justifyItems: 'end',
                                gridRowGap: '2px',
                                marginRight: '20px',
                            })}
                        >
                            <div
                                className={css({
                                    fontSize: '12px',
                                })}
                            >
                                {appContext.userProfile?.firstName + ' ' + appContext.userProfile?.lastName}
                            </div>
                            <div
                                className={css({
                                    fontSize: '14px',
                                })}
                            >
                                {appContext.currentContext?.name.toUpperCase()}
                            </div>
                        </div>
                        <div
                            className={css({
                                color: mainColor.contrastText,
                                fontWeight: 'bold',
                                display: 'grid',
                                gridTemplateColumns: 'auto auto auto auto',
                                alignItems: 'center',
                            })}
                        >
                            <LogOutMenu userDetail={appContext.userProfile} onLogout={onLogout} />
                            {showConfiguration && (
                                <ConfigurationsMenu routes={appRoutes} viewPermissions={appContext.viewPermissions} />
                            )}
                            <ContextSwitcher
                                extendedContext={appContext.switchableContext}
                                onContextSwitch={onContextSwitch}
                                context={appContext.currentContext}
                                originalContext={appContext.originalContext}
                            />
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};
