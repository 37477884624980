/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Tooltip, withStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import DnDListAssignSort from './DnDListAssignSort';
import miniLogo from 'assets/img/stellcap-logo-mini.svg';
import Fab from '@material-ui/core/Fab';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';

const styles = (theme: any) => ({
    root: {},
    dialogTitleWrapper: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '30px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.primary.contrastText,
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        minHeight: '20px',
        minWidth: '20px',
        height: '20px',
        width: '20px',
    },
    dialogTitleCloseIcon: {
        fontSize: 15,
    },
    dialogContent: {
        display: 'grid',
        backgroundColor: theme.palette.background.paper,
        gridTemplateRows: 'auto auto',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 10,
    },
    button: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    iconSmall: {
        fontSize: 20,
    },
});

const ColumnConfigDialog = (props: {
    show: boolean;
    columnConfig: any[] | undefined;
    closeDialog: () => void;
    onUpdate: (colConfig: any[]) => void;
    classes: any;
    defaultColumnConfig: any[] | undefined;
}) => {
    const { classes } = props;

    const [resetToggle, setResetToggle] = useState<boolean>(false);
    const [prevShow, setPrevShow] = useState<boolean>(props.show);
    const [originalColumnConfig, setOriginalColumnConfig] = useState<any[] | undefined>(props.columnConfig);
    const [hiddenColumns, setHiddenColumns] = useState<any[]>([] as string[]);
    const [visibleColumns, setVisibleColumns] = useState<any[]>([] as string[]);

    useEffect(() => {
        buildAssignedAndUnassignedLists(props.columnConfig);
    }, [props.columnConfig]);

    useEffect(() => {
        if (props.show && props.show !== prevShow) {
            buildAssignedAndUnassignedLists(originalColumnConfig);
            setResetToggle(!resetToggle);
            setPrevShow(props.show);
        }
    }, [props.show, originalColumnConfig, resetToggle]);

    const closeDialog = () => {
        if (props.closeDialog) {
            props.closeDialog();
        } else {
            console.error('no close function passed to ColumnConfigDialog');
        }
    };

    const buildAssignedAndUnassignedLists = (columnConfig: any[] | undefined) => {
        if (!columnConfig) {
            console.error('columnConfig passed to ColumnConfigDialog is not an array');
            return;
        }

        const newColumnConfig = columnConfig
            ? columnConfig.map((colConf) => {
                  return { ...colConf };
              })
            : columnConfig;

        const newVisibleCols: any[] = [];
        const newHiddenCols: any[] = [];

        newColumnConfig.forEach((colConf) => {
            if (colConf.visible) {
                newVisibleCols.push(colConf);
            } else {
                newHiddenCols.push(colConf);
            }
        });

        setVisibleColumns(newVisibleCols);
        setHiddenColumns(newHiddenCols);
    };

    const saveUpdatedColConfig = () => {
        const newColConfig: any[] = [];

        hiddenColumns.forEach((col) => {
            col.visible = false;
            newColConfig.push(col);
        });
        visibleColumns.forEach((col) => {
            col.visible = true;
            newColConfig.push(col);
        });

        setOriginalColumnConfig(
            newColConfig.map((colConfig) => {
                return { ...colConfig };
            }),
        );

        props.onUpdate(newColConfig);
    };

    const resetToDefault = () => {
        buildAssignedAndUnassignedLists(props.defaultColumnConfig);
        setResetToggle(!resetToggle);
    };

    const undoChanges = () => {
        buildAssignedAndUnassignedLists(originalColumnConfig);
        setResetToggle(!resetToggle);
    };

    return (
        <Dialog
            className={classes.root}
            onClick={(e) => e.stopPropagation()}
            onClose={closeDialog}
            open={props.show}
            scroll="paper"
        >
            <DialogTitle className={classes.dialogTitleWrapper}>
                <div className={classes.dialogTitle}>
                    <div className={classes.TBDLogoWrapper}>
                        <img alt="logo" className={classes.TBDLogoImg} src={miniLogo} />
                    </div>
                    <div className={classes.dialogTitleText}>Column Configuration</div>
                    <div className={classes.dialogTitleCloseBtnWrapper}>
                        <Tooltip placement="top" title="Close">
                            <Fab
                                aria-label="Close"
                                className={classes.dialogTitleCloseButton}
                                color="primary"
                                onClick={closeDialog}
                            >
                                <CloseIcon className={classes.dialogTitleCloseIcon} />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            </DialogTitle>
            <div className={classes.dialogContent}>
                <DnDListAssignSort
                    accessor={'header'}
                    assignedItems={visibleColumns}
                    destListTitle={'Visible Columns'}
                    onChange={(onChangeProps: { unassignedItems: any[]; assignedItems: any[] }) => {
                        setHiddenColumns(onChangeProps.unassignedItems);
                        setVisibleColumns(onChangeProps.assignedItems);
                    }}
                    resetToggle={resetToggle}
                    sourceListTitle={'Hidden Columns'}
                    unassignedItems={hiddenColumns}
                />
                <div className={classes.buttons}>
                    <BaseButton
                        id={'ColumnConfigDialog/undo-changes'}
                        text={'Undo Changes'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.LARGE}
                        onClick={undoChanges}
                    />
                    <BaseButton
                        id={'ColumnConfigDialog/reset-to-default'}
                        text={'Reset To Default'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.LARGE}
                        onClick={resetToDefault}
                        margin={'0 0 0 8px'}
                    />
                    <BaseButton
                        id={'ColumnConfigDialog/save'}
                        text={'Save'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.LARGE}
                        onClick={saveUpdatedColConfig}
                        margin={'0 8px 0 8px'}
                    />
                </div>
            </div>
        </Dialog>
    );
};

const StyledColumnConfigDialog = withStyles(styles)(ColumnConfigDialog);

export default StyledColumnConfigDialog;
