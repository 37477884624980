import React, { useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';
import { createStyles, Dialog, DialogContent, makeStyles, Tab, Tabs } from '@material-ui/core';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';

const tabs = {
    externalReference: 0,
    number: 1,
    counterparty: 2,
    originalAmountDue: 3,
    currency: 4,
    costingRate: 5,
    dueDate: 6,
    issueDate: 7,
};

export const UploadUserGuide = (props: { show: boolean; onAwayClick: () => void }): React.ReactElement => {
    const classes = useStyles(useTheme());
    const [tabIndex, setTabIndex] = useState<number>(0);

    return (
        <Dialog onClose={props.onAwayClick} open={props.show} scroll="paper" fullScreen>
            <BaseAppBar ShowLogo title={'How To Upload Data Template'} onClose={props.onAwayClick} showCloseButton />
            <DialogContent>
                <Tabs onChange={(e, i) => setTabIndex(i)} value={tabIndex}>
                    <Tab id="configTabBarBasicInformationTab" label="External Reference" />
                    <Tab id="configTabBarSubsidiaries" label="Number" />
                    <Tab id="configTabBarMoveUsers" label="Counterparty" />
                    <Tab id="configTabBarMoveUsers" label="Original Amount Due" />
                    <Tab id="configTabBarMoveUsers" label="Currency" />
                    <Tab id="configTabBarMoveUsers" label="Costing Rate" />
                    <Tab id="configTabBarMoveUsers" label="Due Date" />
                    <Tab id="configTabBarMoveUsers" label="Issue Date" />
                </Tabs>
                <div className={classes.detailInner}>
                    {(() => {
                        switch (tabIndex) {
                            case tabs.externalReference:
                                return (
                                    <div>
                                        <div className={classes.infoText}>
                                            Info: Provide a unique Invoice External Reference
                                        </div>
                                        <div className={classes.container}>Eg. 2111090002268</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>Cannot be blank, should be unique</li>
                                                <li>
                                                    The system allows you to create multiple invoices with different
                                                    external references with the same invoice number
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.number:
                                return (
                                    <div>
                                        <div className={classes.infoText}>Info: Provide an invoice/order Number</div>
                                        <div className={classes.container}>Eg. SIV0001773</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>
                                                    Cannot be blank, need not be unique
                                                </li>
                                                <li>
                                                    The system allows you to create multiple invoices with different
                                                    external references with the same invoice number.
                                                </li>
                                                <li>
                                                    With the same invoice external reference(invoice existing in SOL),
                                                    system only allows you to amend it’s invoice number i.e. cannot
                                                    create multiple invoices with the same invoice external reference
                                                    and different invoice numbers.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.counterparty:
                                return (
                                    <div>
                                        <div className={classes.infoText}>Info: Enter the Counterparty’s name</div>
                                        <div className={classes.container}>Eg. Stellcap</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>
                                                    Cannot be blank, should exist else create new counterparty
                                                </li>
                                                <li>
                                                    System allows you to create new counterparty if it doesn’t exist in
                                                    SOL)
                                                </li>
                                                <li>
                                                    If invoice is linked to an order, do not allow amend Counterparty
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.originalAmountDue:
                                return (
                                    <div>
                                        <div className={classes.infoText}>
                                            Info: Original Invoice amount. This Is only changed for corrections. Related
                                            Credit / debit notes are Uploaded separately.
                                        </div>
                                        <div className={classes.container}>Eg. 248,500.00</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>Should be a valid numeric decimal</li>
                                                <li className={classes.bulletRed}>
                                                    If Blank error reads- ‘amount due not set’
                                                </li>
                                                <li>
                                                    If invoice is linked to an order, unable to amend invoice type by
                                                    changing amount due sign and selecting same/opposite portfolio
                                                    Upload Purchase Invoices & Credit Notes/Sales Invoices & Credit
                                                    Notes button, with - ‘Error updating Invoices - failed to amend
                                                    invoice type: 1 orders linked’ on upload
                                                </li>
                                                <li>
                                                    Trying to amend invoice Amount Due, by changing to another valid
                                                    Amount Due(keeping the -ve/+ve sign the same) in upload invoice
                                                    file, changes it successfully
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.currency:
                                return (
                                    <div>
                                        <div className={classes.infoText}>
                                            Info: Enter the Currency Code USD / EUR / GBP
                                        </div>
                                        <div className={classes.container}>Eg. USD / EUR / GBP</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>
                                                    3 character ISO code should be found in any of the currency pairs
                                                    enabled for client/company eg. EUR, USD, GBP
                                                </li>
                                                <li>
                                                    If invoice is not linked to an order, able to amend currency,
                                                    changing to another valid currency in upload invoice file
                                                </li>
                                                <li>
                                                    If invoice is linked to an order, do not allow amend invoice
                                                    Currency
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.costingRate:
                                return (
                                    <div>
                                        {' '}
                                        <div className={classes.infoText}>
                                            Info: Conversion rate used when the invoice was costed
                                        </div>
                                        <div className={classes.container}>Eg. 16,5</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li className={classes.bulletRed}>
                                                    Should be valid numeric decimal (00,00)
                                                </li>
                                                <li className={classes.bulletRed}>
                                                    Cannot be blank, cannot be a negative value (-)
                                                </li>
                                                <li>
                                                    Amend costing rate by changing to another valid costing rate in
                                                    upload invoice files(sales invoices, purchase invoices), changes it
                                                    successfully
                                                </li>
                                                <li>Error message- ‘costing rate not set’</li>
                                                <li>
                                                    For create/amend with invalid costing rate(-ve/non-decimal) - Please
                                                    Resolve Issues and Try Again,
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.dueDate:
                                return (
                                    <div>
                                        <div className={classes.infoText}>Info: Invoice Due Date</div>
                                        <div className={classes.container}>Eg. 22/11/2021</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li>
                                                    Due Date - if blank, then system sets it to Issue Date + 60 days
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            case tabs.issueDate:
                                return (
                                    <div>
                                        <div className={classes.infoText}>Info: Invoice Issue Date</div>
                                        <div className={classes.container}>Eg. 22/11/2021</div>
                                        <div className={classes.bullets}>
                                            <ul>
                                                <li>Issue Date - if blank, then system sets it to upload date</li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            default:
                                return <div>invalid tab value</div>;
                        }
                    })()}
                    <div className={classes.tableHeader}>Template Layout Reference</div>
                    <div className={classes.tableContent}>
                        <div className={classes.tableColumnLeft}>External Reference</div>
                        <div className={classes.tableColumn}>Number</div>
                        <div className={classes.tableColumn}>Counterparty</div>
                        <div className={classes.tableColumn}>Original Amount Due</div>
                        <div className={classes.tableColumn}>Currency</div>
                        <div className={classes.tableColumn}>Costing Rate</div>
                        <div className={classes.tableColumn}>Due Date</div>
                        <div className={classes.tableColumn}>Issue Date</div>
                        <div className={classes.tableColumn}>Notes</div>
                    </div>
                    <div className={classes.tableContent}>
                        <div className={classes.tableColumnContentLeft}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            If the invoice number is Not unique, then client Needs to provide a Unique reference Number
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            Invoice Number
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            Supplier
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            Original Invoice amount. This Is only changed for corrections. Related Credit / debit notes
                            are Uploaded separately.
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            USD / EUR / GBP
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnMandatory}>Mandatory</div>
                            Conversion rate used When the invoice was Costed
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnOptional}>Optional</div>
                            <div className={classes.tableColumnMandatory}>Invoice Due Date</div>
                            If Due Date is blank, system sets Due Date=Issue Date+60 days
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnOptional}>Optional</div>
                            <div className={classes.tableColumnMandatory}>Date on which invoice was issued</div>
                            If Issue Date is blank, system sets Issue Date=upload date
                        </div>
                        <div className={classes.tableColumnContent}>
                            <div className={classes.tableColumnOptional}>Optional</div>
                            Free text field that Client can use
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        detailContainer: {},
        detailInner: {
            flexGrow: 1,
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
        },
        infoText: {
            marginBottom: theme.spacing(4),
            color: theme.palette.info.main,
            font: 'normal normal normal 14px/30px Roboto',
        },
        bulletRed: {
            marginBottom: theme.spacing(1),
            color: theme.palette.error.main,
        },
        bullets: {
            marginTop: theme.spacing(4),
            marginLeft: theme.spacing(3),
            width: '758px',
            font: 'normal normal normal 14px/30px Roboto',
        },
        container: {
            font: 'Bold 14px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            width: '161px',
            height: '35px',
            lineHeight: '35px',
            verticalAlign: 'middle',
            textAlign: 'center',
        },
        tableHeader: {
            paddingLeft: '8px',
            font: 'Bold 14px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            width: '1164px',
            height: '35px',
            lineHeight: '35px',
            verticalAlign: 'middle',
            marginTop: theme.spacing(4),
        },
        tableContent: {
            width: '1174px',
            display: 'flex',
        },
        tableColumn: {
            paddingLeft: '8px',
            backgroundColor: theme.palette.custom.paperExtended.paper3,
            font: 'normal normal 900 9px/16px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            borderLeft: '0px',
            borderTop: '0px',
            width: '1166px',
            height: '40px',
            lineHeight: '40px',
            verticalAlign: 'middle',
        },
        tableColumnLeft: {
            paddingLeft: '8px',
            backgroundColor: theme.palette.custom.paperExtended.paper3,
            font: 'normal normal 900 9px/16px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            borderTop: '0px',
            width: '1166px',
            height: '40px',
            lineHeight: '40px',
            verticalAlign: 'middle',
        },
        tableColumnContent: {
            paddingLeft: '8px',
            font: 'normal normal 900 9px/16px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            borderLeft: '0px',
            borderTop: '0px',
            width: '1166px',
            lineHeight: '20px',
            verticalAlign: 'middle',
        },
        tableColumnContentLeft: {
            paddingLeft: '8px',
            font: 'normal normal 900 9px/16px Roboto',
            border: `1px solid ${theme.palette.background.paper}`,
            borderTop: '0px',
            width: '1166px',
            lineHeight: '20px',
            verticalAlign: 'middle',
        },
        tableColumnMandatory: {
            font: 'normal normal 900 9px/16px Roboto',
            borderLeft: '1px',
            borderTop: '0px',
            lineHeight: '25px',
            verticalAlign: 'middle',
        },
        tableColumnOptional: {
            font: 'normal normal 900 9px/16px Roboto',
            borderLeft: '1px',
            borderTop: '0px',
            lineHeight: '25px',
            verticalAlign: 'middle',
            opacity: 0.6,
        },
    }),
);
