import { StyleObject } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

const styles = (theme: CustomTheme): StyleObject => ({
    root: {
        border: `solid 2px ${theme.palette.primary.light}`,
    },
    layout: {
        marginBottom: theme.spacing(2),
    },
    formFieldAccent: {
        color: theme.palette.custom.text.accent,
    },
    formFieldGreen: {
        fontWeight: 'bold',
        color: theme.palette.custom.data.data3B,
    },
    title: {
        backgroundColor: HexToRGBA('#ffffff', 0.1),
        color: theme.palette.text.primary,
        padding: theme.spacing(),
        border: '1px solid #26283c',
        marginBottom: theme.spacing(),
    },
    cardHeader: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(),
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 17,
        marginRight: '0px',
        marginTop: '0px',
    },
    action: {
        color: 'red',
        marginRight: '0px',
        marginTop: '0px',
    },
    leftAlign: {
        paddingLeft: theme.spacing(),
    },
    dateField: {
        paddingLeft: theme.spacing(),
        left: '-16px',
        position: 'relative',
    },
    fieldUnderline: {
        '&:before': {
            borderBottom: '1px solid #4B4D5E',
        },
    },
    progress: {
        color: theme.palette.custom.text.label,
        margin: 2,
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },

    dialogTitleWrapper: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.primary.contrastText,
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '40px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        minHeight: '20px',
        minWidth: '20px',
        height: '20px',
        width: '20px',
    },
    dialogTitleCloseIcon: {
        fontSize: 15,
    },
    dialogContent: {
        padding: '5 5 10 5',
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr',
    },
    stepContentWrapper: {
        padding: '5 5 10 5',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        justifyItems: 'center',
        alignItems: 'center',
    },
    stepperWrapper: {},
    stepper: {
        padding: '5 5 10 5',
    },
    button: {
        minWidth: '90px',
        width: '90px',
        height: '28px',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        fontSize: '11px',
    },
    errorIcon: {
        fontSize: 80,
    },
    confTitle: {
        padding: 2,
    },
    confBody: {
        padding: 2,
    },
});

export default styles;
