import React, { Fragment, ReactElement, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { displayAmount, processUnixDateTimeForViewing } from 'utils';
import Table from 'components/Table/Table';
import { AppContext, AppContextT } from 'context';
import { AuditEntry } from 'popcorn-js/audit';
import { useStyletron } from 'styletron-react';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { downloadTemplate, formatZonedDate } from '.';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import Big from 'big.js';
import { useServiceSync } from 'hooks/useService';
import { Payment, PaymentType } from 'popcorn-js/payment';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { PaymentUploader } from './PaymentUploader';
import { RemoveGLPaymentRequest, RemoveGLPaymentResponse } from 'popcorn-js/payment/handler';
import { Snackbar } from '@material-ui/core';
import { Alert } from 'components/tradeV2/ticket/styledComponents';
import { ConfirmRemovePayment } from './ConfirmRemovePayment';
import { IdentifierType } from 'popcorn-js/search/identifier';
import FileSaver from 'file-saver';
import { Downloader, DownloadRequest, DownloadResponse } from 'popcorn-js/payment/downloader';

export const PaymentStation = (): ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const { paymentHandler } = useContext<ServiceContextT>(ServiceContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [total, setTotal] = useState<number>(0);
    const initialQuery: Query = {
        sortBy: ['date', 'externalReference'],
        order: ['desc', 'asc'],
        limit: initialPageSize,
        offset: 0,
    };
    const [query, setQuery] = useState<Query>(initialQuery);
    const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [showColumnConfiguration, setShowColumnConfiguration] = useState<boolean>(false);
    const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>(false);
    const [selected, setSelected] = useState<Payment | undefined>();

    // service providers
    const [find] = useServiceSync<FindRequest, FindResponse<Payment>>(paymentHandler?.Find);
    const [removeGLPayment] = useServiceSync<RemoveGLPaymentRequest, RemoveGLPaymentResponse>(
        paymentHandler?.RemoveGLPayment,
    );
    const [DownloadPayments] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.Download);

    useEffect(() => {
        findPayments(initialCriteria).finally();
    }, []);

    const findPayments = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await find({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            setPayments(result.records);
            setTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const executeRemoveGLPayment = async () => {
        setLoading(true);
        try {
            await removeGLPayment({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected?.id },
            });
            setSuccessMessage('Successfully removed GL payment');
            setSelected(undefined);
            await findPayments();
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setLoading(false);
    };

    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findPayments(criteria, newQuery).finally();
    };
    const handleFilterChange = (newCriteria: Criteria) => {
        if (showFilter) {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        newCriteria.push({
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: '',
                        });
                    }
                }
            }
            setQuery(newQuery);
            setCriteria(newCriteria);
            findPayments(newCriteria, newQuery).finally();
        }
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['date', 'externalReference'],
            order: ['desc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findPayments(criteria, newQuery).finally();
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findPayments(criteria, newQuery).finally();
    };
    const downloadPayments = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadCFCDepositsResponse = await DownloadPayments({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadCFCDepositsResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Payments ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occured. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            <ActionsMenu
                id={'PaymentStation/more-options'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-payments',
                        text: 'Upload Payments',
                        onClick: () => {
                            setMoreActionsAnchorEl(undefined);
                            setActiveState(ActiveState.uploading);
                        },
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: 'Payments',
                            id: 'payments-title',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PaymentStation/remove',
                            icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                            helpText: 'Remove GL payment',
                            onClick: () => setShowConfirmRemove(true),
                            hide: !selected || selected?.type !== PaymentType.GL,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CFCDepositManagementStation/CFCDeposits/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Payments',
                            onClick: () => {
                                downloadPayments(criteria, query).finally();
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PaymentStation/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                setShowFilter(!showFilter);
                                handleFilterChange(initialCriteria);
                                findPayments(initialCriteria, initialQuery).finally();
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PaymentStation/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: 'Open column configuration',
                            onClick: () => setShowColumnConfiguration(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PaymentStation/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <Table
                    colConfigCloseFromCard={() => setShowColumnConfiguration(false)}
                    colConfigOpenFromCard={showColumnConfiguration}
                    columns={[
                        { title: 'Party', field: 'partyCode' },
                        {
                            title: 'Type',
                            field: 'type',
                            filter: {
                                options: [
                                    PaymentType.SI.toString(),
                                    PaymentType.GL.toString(),
                                    PaymentType.Balance.toString(),
                                    PaymentType.CFC.toString(),
                                ].map((value) => ({ value })),
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'External Reference',
                            field: 'externalReference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Date',
                            field: 'date',
                            render: (payment: Payment) => formatZonedDate(payment.date),
                            filter: {
                                type: CriteriaType.TimeCriterion,
                            },
                        },
                        {
                            title: 'Currency',
                            field: 'currency',
                            disableSort: true,
                            filter: {
                                options: appContext.currencies,
                                displayAccessor: 'isoCode',
                                valueAccessor: 'isoCode',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Financial Year',
                            field: 'financialYear',
                            disableSort: true,
                            filter: {
                                options: financialYears.map((f) => ({ name: f })),
                                displayAccessor: 'name',
                                valueAccessor: 'name',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'FX Amount',
                            field: 'fxAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('fxAmount', (fxAmount) => displayAmount(fxAmount as number)),
                        },
                        {
                            title: 'Local Amount',
                            field: 'localAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('localAmount', (localAmount) => displayAmount(localAmount as number)),
                        },
                        {
                            title: 'Verified',
                            field: 'verified',
                            filter: { type: CriteriaType.BoolCriterion },
                            render: (rowData: Payment) => {
                                return rowData.verified ? 'TRUE' : 'FALSE';
                            },
                        },
                        {
                            title: 'Deal Rate',
                            field: 'dealRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('dealRate', (costingRate: unknown) =>
                                (costingRate as number).toFixed(6),
                            ),
                        },
                        {
                            title: 'Capture Rate',
                            field: 'captureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('captureRate', (costingRate: unknown) =>
                                (costingRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Effective Rate',
                            field: 'effectiveRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('effectiveRate', (effectiveRate) =>
                                (effectiveRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Invoice External Reference',
                            field: 'invoiceExternalReference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Reference',
                            field: 'reference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Last Modified',
                            field: 'auditEntry.time',
                            render: safeRender('auditEntry', (auditEntry: unknown) =>
                                processUnixDateTimeForViewing((auditEntry as AuditEntry).time),
                            ),
                        },
                    ]}
                    count={total}
                    data={payments}
                    defaultColConfig={[
                        { header: 'Type', visible: true },
                        { header: 'External Reference', visible: true },
                        { header: 'Currency', visible: true },
                        { header: 'Financial Year', visible: true },
                        { header: 'Party', visible: true },
                        { header: 'Date', visible: true },
                        { header: 'Invoice External Reference', visible: true },
                        { header: 'Reference', visible: true },
                        { header: 'FX Amount', visible: true },
                        { header: 'Local Amount', visible: true },
                        { header: 'Verified', visible: true },
                        { header: 'Deal Rate', visible: true },
                        { header: 'Effective Rate', visible: true },
                        { header: 'Capture Rate', visible: true },
                        { header: 'Last Modified', visible: false },
                    ]}
                    showFilterRow={showFilter}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    initialCriteria={baseCriteriaConfig}
                    loading={loading}
                    onChangeSorting={handleChangeSorting}
                    onFilterChange={handleFilterChange}
                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                    rowsPerPage={query.limit}
                    rowsPerPageOptions={rowsPerPageOptions}
                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                    tableID={'PaymentStationTable'}
                    rowClickAction={(payment: Payment) =>
                        selected?.id === payment.id ? setSelected(undefined) : setSelected(payment)
                    }
                    onRowCheck={(payment: Payment) =>
                        selected?.id === payment.id ? setSelected(undefined) : setSelected(payment)
                    }
                    selected={selected ? [selected] : []}
                    onSelectAll={() => {
                        if (selected) {
                            setSelected(undefined);
                        }
                    }}
                    showCheckboxes
                />
            </StandardCard>
            {(!!successMessage || !!errorMessage) && (
                <Snackbar autoHideDuration={3000} onClose={handleHideAlert} open={!!errorMessage || !!successMessage}>
                    <Fragment>
                        {!!successMessage && (
                            <Alert onClose={() => setSuccessMessage(undefined)} severity="success">
                                {successMessage}
                            </Alert>
                        )}
                        {!!errorMessage && (
                            <Alert onClose={() => setErrorMessage(undefined)} severity="error">
                                {errorMessage}
                            </Alert>
                        )}
                    </Fragment>
                </Snackbar>
            )}
            <ConfirmRemovePayment
                open={showConfirmRemove}
                onClose={() => setShowConfirmRemove(false)}
                onConfirm={() => {
                    executeRemoveGLPayment().finally();
                    findPayments(criteria, query).finally();
                }}
            />
            {activeState === ActiveState.uploading && (
                <PaymentUploader
                    onClose={() => {
                        setActiveState(ActiveState.viewing);
                        findPayments().finally();
                    }}
                    show={activeState === ActiveState.uploading}
                />
            )}
        </div>
    );
};

const initialCriteria = [{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }];

export enum ActiveState {
    viewing = 'ACTIVE_STATE_VIEWING',
    uploading = 'ACTIVE_STATE_UPLOADING',
}

const safeRender = (accessor: string, formatter = (value: unknown) => value) => (rowData: Payment) => {
    try {
        return formatter(rowData[accessor as keyof Payment]);
    } catch (e) {
        return '-';
    }
};

const initialPageSize = 12;
const rowsPerPageOptions = [5, 10, 12, 17, 20, 25, 30];
const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
};
