/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { Button, Typography, useTheme } from '@material-ui/core';
import { Context, ExtendedContext } from 'popcorn-js/security';
import { PartyType } from 'popcorn-js/party';
import { ChevronLeft as ArrowLeft, KeyboardArrowDown as ArrowDown } from '@material-ui/icons';
import { AppContext, AppContextT } from 'context';
import { CustomTheme } from 'theme/custom';
import { ActionsMenu, Item } from 'components/ActionsMenu/ActionsMenu';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';

type ContextSwitcherProps = {
    context?: Context;
    originalContext?: Context;
    extendedContext?: ExtendedContext;
    onContextSwitch: (arg0: string) => void;
};

export const ContextSwitcher = (props: ContextSwitcherProps): ReactElement => {
    const { context, originalContext, extendedContext } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
    const [filterText, setFilterText] = useState<string>('');

    const menuItems: Item[] = [] as Item[];

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.party.partyCode;

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const mainColor = appContext.contextSwitched
        ? theme.palette.custom.stellcapBrand2
        : theme.palette.custom.stellcapBrand1;

    const showReturnButton = originalContext?.partyCode !== context?.partyCode;

    const allParties: Context[] = [];
    for (const context of extendedContext?.clients || []) {
        if (context.partyCode !== partyCode) {
            allParties.push(context);
        }
    }

    if (context?.partyType == PartyType.SYSTEM) {
        for (const context of extendedContext?.processingOrgs || []) {
            allParties.push(context);
        }
    }

    allParties.sort((a: Context, b: Context) => {
        let comparison = 0;
        if (a.name > b.name) {
            comparison = 1;
        } else if (a.name < b.name) {
            comparison = -1;
        }
        return comparison;
    });

    if (showReturnButton) {
        menuItems.push({
            id: 'back',
            text: <Typography variant={'subtitle1'}> Back</Typography>,
            icon: <ArrowLeft />,
            onClick: () => props.onContextSwitch(originalContext?.partyCode || ''),
        });
    }

    menuItems.push({
        id: 'filter',
        text: '',
        icon: (
            <BaseTextField
                id="ContextSwitcher/filter"
                autoFocus
                onChange={(event: any) => setFilterText(event.target.value)}
                InputProps={{ autoComplete: 'false' }}
            />
        ),
        onClick: () => undefined,
    });

    menuItems.push(
        ...allParties
            .filter((c: Context) =>
                filterText !== '' ? c.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) : true,
            )
            .map((p: Context) => ({
                id: p.partyCode,
                text: p.name,
                onClick: () => props.onContextSwitch(p.partyCode),
            })),
    );

    return (
        <React.Fragment>
            <Button
                classes={{
                    root: css({
                        marginTop: '7px',
                        marginLeft: '5px',
                        '&:hover': {},
                    }),
                    label: css({
                        fontSize: '12px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }),
                }}
                onClick={(event: any) => {
                    setAnchorEl(event.currentTarget ? event.currentTarget : null);
                }}
            >
                <ArrowDown
                    className={css({
                        color: mainColor.contrastText,
                        fontSize: '20px',
                        marginRight: '12px',
                    })}
                />
                <div
                    className={css({
                        color: mainColor.contrastText,
                    })}
                >
                    Switch Party
                </div>
            </Button>
            <ActionsMenu
                id={'ContextSwitcher'}
                title={'Switch Context'}
                anchorElement={anchorEl}
                onClose={() => {
                    setAnchorEl(undefined);
                }}
                items={menuItems}
            />
        </React.Fragment>
    );
};
