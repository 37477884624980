import { Option, OptionAmendInput, OptionDirection, OptionStatus, OptionType } from 'popcorn-js/options';
import { ImportExport } from 'popcorn-js';

export const OptionAmendInputFromOption = (option: Option): OptionAmendInput => {
    return {
        externalReference: option.externalReference,
        status: option.status,
        notionalAmount: option.notionalAmount,
        quoteAmount: option.quoteAmount,
        currencyPair: option.currencyPair,
        premium: option.premium,
        expiryDate: option.expiryDate,
        deliveryDate: option.deliveryDate,
        strikePrice: option.strikePrice,
        tradeDate: option.tradeDate,
        type: option.type,
        direction: option.direction,
        bank: option.bank,
        capturedSpotRate: option.capturedSpotRate,
        interbankRate: option.interbankRate,
        bankRate: option.bankRate,
        trader: option.trader,
        traderOrganisation: option.traderOrganisation,
        notes: option.notes,
        financialYear: option.financialYear,
        importExport: option.importExport,
    };
};

export const FieldIsEditable = (field: keyof Option, linkedEntities: boolean): boolean => {
    const aa: OptionAmendInput = {
        externalReference: 'string',
        status: OptionStatus.OPEN,
        notionalAmount: 1,
        quoteAmount: 1,
        currencyPair: 'string',
        premium: 1,
        expiryDate: new Date(),
        deliveryDate: new Date(),
        tradeDate: new Date(),
        type: OptionType.PUT,
        direction: OptionDirection.SELL,
        bank: 'string',
        capturedSpotRate: 1,
        interbankRate: 1,
        bankRate: 1,
        trader: 'string',
        traderOrganisation: 'string',
        financialYear: 'string',
        notes: 'string',
        importExport: ImportExport.EXPORT,
    };

    const canEdit = field in aa;
    if (!linkedEntities) {
        return canEdit;
    }
    const fieldsNotEditableIfLinked: Array<keyof Option> = [];
    const canEditLinked = !fieldsNotEditableIfLinked.includes(field);
    return canEdit && canEditLinked;
};

//
export const fieldLabels: Record<keyof Option, string> = {
    number: 'Number',
    externalReference: 'External\xa0Reference',
    status: 'Status',
    currencyPair: 'Currency\xa0Pair',
    premium: 'Premium',
    expiryDate: 'Expiry\xa0Date',
    deliveryDate: 'Delivery\xa0Date',
    strikePrice: 'Strike\xa0Price',
    tradeDate: 'Trade\xa0Date',
    type: 'Type',
    direction: 'Direction',
    importExport: 'Import\xa0Export',
    bank: 'Bank',
    interbankRate: 'Interbank\xa0Rate',
    bankRate: 'Bank\xa0Rate',
    trader: 'Trader',
    traderOrganisation: 'Trader\xa0Organisation',
    notes: 'Notes',
    notionalAmount: 'Notional\xa0Amount',
    putAmount: 'Put\xa0Amount',
    callAmount: 'Call\xa0Amount',
    financialYear: 'Financial\xa0Year',
    id: '',
    quoteAmount: 'Quote\xa0Amount',
    effectiveRate: 'Effective\xa0Rate',
    captureDate: 'Capture\xa0Date',
    processingOrgPartyCode: 'Processing\xa0Org',
    transactionID: '',
    tradingPartyCode: 'Trading\xa0Party\xa0Code',
    capturedSpotRate: 'Captured\xa0Spot\xa0Rate',
    auditEntry: '',
    billingType: '',
    intermediaryMargin: '',
    adminFee: '',
    clientFee: '',
    billedToBank: '',
    marginNotes: '',
    season: '',
    product: '',
    clientReference: '',
    clientNotes: '',
};

export enum State {
    nop = 0,
    error = 1,
    editingExisting = 2,
    loading = 3,
    showingHistory = 4,
}

export enum Event {
    init = State.nop,
    errorEvent = State.error,
    cancelEditExisting = State.nop,
    enterLoading = State.loading,
    doneLoading = State.nop,
    showHistory = State.showingHistory,
    exitHistory = State.nop,
}
