import { Collapse } from '@material-ui/core';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { StandardCard } from 'components/Card/Card';
import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { ErrorIcon } from 'icons';
import Table from 'components/Table/Table';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import CreateSIButton from './CreateSIButton';
import { AddNewCFC } from './AddNewCFC';
import { useService, useServiceSync } from 'hooks/useService';
import { financialYears, FindRequest, FindResponse, ImportExport } from 'popcorn-js';
import { CFCDeposit } from 'popcorn-js/cfcDeposit';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { displayAmount, displayDate, displayRate } from 'utils';
import { debounce } from 'lodash';
import { IdentifierType } from 'popcorn-js/search/identifier';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { CFCDepositUploader } from './DepositUploader';
import { downloadTemplate } from './index';
import { Downloader, DownloadRequest, DownloadResponse } from 'popcorn-js/cfcDeposit/downloader';
import FileSaver from 'file-saver';
import { AddNewSI } from '../SettlementInstruction/AddNewSI';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { Currency } from 'popcorn-js/currency';
import { Handler } from 'popcorn-js/settlementInstruction/handler';
import { AppContext, AppContextT } from 'context';
import { Edit } from '../SettlementInstruction/Edit';
import { SettlementInstruction } from 'popcorn-js/settlementInstruction';
import Big from 'big.js';
import SelectedRowsButton from 'components/SelectedRowsButton/selectedRowsButton';

interface CFCDepositProps {
    setReloadTriggerMain: () => void;
    reloadTriggerState?: boolean;
}
const initialPageSize = 12;

const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
};

const CFCDeposits: React.FC<CFCDepositProps> = (props: CFCDepositProps) => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const { setReloadTriggerMain, reloadTriggerState } = props;
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [showColumnConfiguration, setShowColumnConfiguration] = useState<boolean>(false);
    const [selected, setSelected] = useState<CFCDeposit[] | undefined>();
    const [selectedSI, setSelectedSI] = useState<SettlementInstruction | undefined>();
    const [importExport] = useState<ImportExport>(ImportExport.EXPORT);

    const [showCreateNewSI, setShowCreateNewSI] = useState<boolean>(false);
    const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const partyCurrencies =
        appContext.party?.currencyPairs?.map((id: string) => {
            const ccyPair = appContext.currencyPairs?.find((c: CurrencyPair) => c.name === id);
            const foreignCcyID = ccyPair?.baseCurrency;
            return appContext.currencies?.find((c: Currency) => c.isoCode === foreignCcyID);
        }) || [];

    // state
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [depositsTotal, setDepositTotal] = useState<number>(0);
    const [depositsResponse, setDepositsResponse] = useState<CFCDeposit[] | undefined>();
    const [query, setQuery] = useState<Query>({
        sortBy: ['depositDate', 'id'],
        order: ['asc', 'asc'],
        limit: initialPageSize,
        offset: 0,
    });
    const initialCriteria = Object.keys(baseCriteriaConfig).map((field) => {
        return baseCriteriaConfig[field];
    });
    const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
    // services
    const { cfcDepositHandler } = useContext<ServiceContextT>(ServiceContext);
    const [findCFCDeposits] = useServiceSync<FindRequest, FindResponse<CFCDeposit>>(cfcDepositHandler.Find);
    const [DownloadCFCDeposits] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.Download);
    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findCFCDeposits({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setDepositsResponse(result.records);
            setDepositTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };
    useEffect(() => {
        let show = true;
        if (selected !== undefined && selected.length !== 0) {
            for (const s of selected) {
                if (!show) {
                    continue;
                }
                if (s.siNumber !== '') {
                    show = false;
                    continue;
                }
                for (const s1 of selected) {
                    if (s.accountName !== s1.accountName) {
                        show = false;
                    }
                }
            }
            if (show) {
                setShowCreateNewSI(true);
            } else {
                setShowCreateNewSI(false);
            }
        } else {
            setShowCreateNewSI(false);
        }
    }, [selected]);

    useEffect(() => {
        findEntities(initialCriteria).finally();
    }, [reloadTriggerState]);
    const downloadCFCDeposits = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadCFCDepositsResponse = await DownloadCFCDeposits({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadCFCDepositsResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'CFC Deposits ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occured. Please Contact Your Administrator');
        }
        setLoading(false);
    };
    const handleChangeSorting = async (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };
    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };
    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['depositDate', 'id'],
            order: ['asc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };
    const handleFilterChange = useCallback(
        debounce(async (newCriteria: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (financialYears[0] === 'CURRENT') {
                //Add ALL to slice
                financialYears.unshift('ALL');
            }
            if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        //remove ALL from slice then push
                        const index = financialYears.indexOf('ALL');
                        if (index !== -1) {
                            financialYears.splice(index, 1);
                        }
                        newCriteria.push({
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: '',
                        });
                    }
                }
            }
            setQuery(newQuery);
            setCriteria(newCriteria);
            await findEntities(newCriteria, newQuery);
        }, 100),
        [query],
    );
    const closeDialog = () => {
        setOpenNew(false);
        setReloadTriggerMain();
    };
    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setErrorMessage(undefined);
        setConfirmationMethod(undefined);
    };
    const handleSelectRow = (rowData: CFCDeposit) => {
        const ind = (selected || []).findIndex((selectedCfc: CFCDeposit) => selectedCfc.id === rowData.id);
        const _selected = [...(selected || [])];
        if (ind === -1) {
            _selected.push(rowData);
        } else {
            _selected.splice(ind, 1);
        }
        setSelected && setSelected(_selected);
    };
    const handleSelectAll = () => {
        const newSelected = [...(selected || [])];
        if (newSelected.length !== 0) {
            setSelected && setSelected([]);
        } else if (depositsResponse) {
            depositsResponse.forEach((cfc: CFCDeposit) => {
                newSelected.push(cfc);
            });
            setSelected && setSelected(newSelected);
        }
    };
    /* ---------------------------------------------------------------------
    create new SI
    --------------------------------------------------------------------- */
    const [
        { response: createDraftResponse, loading: createDraftLoading, error: createDraftError },
        setCreateDraftRequest,
    ] = useService(undefined, Handler.CreateCFCDraft);
    const handleCreateNew = (createDraftInfo: {
        currency: string;
        date: number;
        importExport: ImportExport;
        cfcDeposits: CFCDeposit[];
    }) => {
        setSelected(undefined);
        setShowNewDialog(false);
        setCreateDraftRequest({
            ...createDraftInfo,
            partyCode: appContext.party?.partyCode,
            processingOrgPartyCode: appContext.parentPartyCode,
        });
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (createDraftResponse && !createDraftLoading) {
            setSuccessMessage('Successfully created draft SI');
            setSelectedSI(createDraftResponse.settlementInstruction);
            setEditOpen(true);
        }
    }, [createDraftResponse, createDraftLoading]);
    useEffect(() => {
        if (createDraftError && !createDraftLoading) {
            setErrorMessage('Failed to create draft SI: ' + createDraftError);
        }
    }, [createDraftError, createDraftLoading]);

    const showDeleteConfirmation = (rowInfo: CFCDeposit[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        if (rowInfo.length == 1) {
            setWarningMessage(`You are about to delete cfc deposit '${rowInfo[0].id}'. Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await cfcDepositHandler.Delete({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
                    });
                    setSuccessMessage('CFC Deposit Deleted');
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    setReloadTriggerMain();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const ids = [] as string[];
            for (const cfc of rowInfo) {
                if (cfc.id) {
                    ids.push(cfc.id);
                }
            }
            setWarningMessage(`You are about to delete cfc deposit '${rowInfo[0].id}'. Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await cfcDepositHandler.DeleteBatch({
                        identifiers: ids,
                    });
                    setSuccessMessage('CFC Deposits Deleted');
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    setReloadTriggerMain();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };
    return (
        <div id="cfcDepositManagementStationRoot">
            {openNew && <AddNewCFC closeDialog={closeDialog} show={openNew} />}
            {uploading && (
                <CFCDepositUploader
                    onClose={() => {
                        setUploading(false);
                        setReloadTriggerMain();
                    }}
                    show={uploading}
                />
            )}
            <ActionsMenu
                id={'CFCDepositManagementStation/CFCDeposits/more-options'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-cfc-deposits',
                        text: 'Upload CFC Deposits',
                        onClick: () => setUploading(true),
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'CFCDepositManagementStation/CFCDeposits/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'CFC Deposits',
                        },
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            id: 'CFCDepositManagementStation/CFCDeposits/selectedRowsButton',
                            element: (
                                <SelectedRowsButton
                                    disabled={false}
                                    onClick={() => setSelected([])}
                                    count={selected?.length ? selected?.length : 0}
                                />
                            ),
                            hide: selected?.length === 0 || !selected,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            id: 'CFCDepositManagementStation/CFCDeposits/create-si',
                            element: <CreateSIButton disabled={false} onClick={() => setShowNewDialog(true)} />,
                            hide: !showCreateNewSI,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CFCDepositManagementStation/CFCDeposits/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'Add CFC Deposit',
                            onClick: () => setOpenNew(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CFCDepositManagementStation/CFCDeposits/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Deposits',
                            onClick: () => {
                                downloadCFCDeposits(criteria, query).finally();
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(selected || ({} as CFCDeposit[])),
                            hide: (selected || []).length == 0,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CFCDepositManagementStation/CFCDeposits/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: 'Column Configuration',
                            onClick: () => setShowColumnConfiguration(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                setShowFilterRow(!showFilterRow);
                                setCriteria(initialCriteria);
                                setQuery({
                                    sortBy: ['depositDate', 'id'],
                                    order: ['asc', 'asc'],
                                    limit: initialPageSize,
                                    offset: 0,
                                });
                                findEntities(initialCriteria, {
                                    sortBy: ['depositDate', 'id'],
                                    order: ['asc', 'asc'],
                                    limit: initialPageSize,
                                    offset: 0,
                                }).finally();
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'CFCDepositManagementStation/CFCDeposits/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event?.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <Collapse in={true}>
                    {((): React.ReactNode => {
                        if (errorMessage) {
                            return (
                                <div>
                                    <ErrorIcon />
                                    <div style={{ textAlign: 'center' }}>{errorMessage}</div>
                                </div>
                            );
                        }
                        return (
                            <React.Fragment>
                                <Table
                                    colConfigCloseFromCard={() => setShowColumnConfiguration(false)}
                                    colConfigOpenFromCard={showColumnConfiguration}
                                    columns={[
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                options: [{ v: 'AVAILABLE' }, { v: 'CONVERTED' }],
                                                displayAccessor: 'v',
                                                valueAccessor: 'v',
                                            },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.status) {
                                                    return rowData.status.toLowerCase() == 'available' ? (
                                                        <div className={classes.availableRootContainer}>
                                                            <div className={classes.availableFontWrapper}>
                                                                AVAILABLE
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={classes.defaultFontWrapper}>CONVERTED</div>
                                                    );
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '50px',
                                        },
                                        {
                                            title: 'Account',
                                            field: 'accountName',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.accountName) {
                                                    return rowData.accountName;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'FX',
                                            field: 'currency',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                options: appContext.currencies,
                                                displayAccessor: 'isoCode',
                                                valueAccessor: 'isoCode',
                                            },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.currency) {
                                                    return rowData.currency;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'External Ref.',
                                            field: 'invoiceExternalReference',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.invoiceExternalReference) {
                                                    return rowData.invoiceExternalReference;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Invoice Number',
                                            field: 'invoiceNumber',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.invoiceNumber) {
                                                    return rowData.invoiceNumber;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Invoice Counterparty',
                                            field: 'invoiceCounterparty',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.invoiceCounterparty) {
                                                    return rowData.invoiceCounterparty;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Linked Order',
                                            field: 'linkedOrder',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.linkedOrder) {
                                                    return rowData.linkedOrder;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Deposit Date',
                                            field: 'depositDate',
                                            filter: { type: CriteriaType.TimeCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.depositDate) {
                                                    return displayDate((rowData.depositDate as unknown) as string);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'FX Amount',
                                            field: 'fxAmount.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.fxAmount && rowData.status) {
                                                    return rowData.status.toLowerCase() == 'available' ? (
                                                        <div className={classes.amountColor}>
                                                            {displayAmount(rowData.fxAmount)}
                                                        </div>
                                                    ) : (
                                                        displayAmount(rowData.fxAmount)
                                                    );
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Spot at Deposit',
                                            field: 'spotAtDeposit.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.spotAtDeposit) {
                                                    return displayRate(rowData.spotAtDeposit);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Reference',
                                            field: 'reference',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.reference) {
                                                    return rowData.reference;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Settlement Instruction',
                                            field: 'siNumber',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.siNumber) {
                                                    return rowData.siNumber;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Realised PnL',
                                            field: 'pnlTimingBenchmark.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.pnlTimingBenchmark) {
                                                    return displayAmount(rowData.pnlTimingBenchmark);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Spot at Conversion',
                                            field: 'spotAtConvert.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.spotAtConvert) {
                                                    return displayRate(rowData.spotAtConvert);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Conversion Date',
                                            field: 'conversionDate',
                                            filter: { type: CriteriaType.TimeCriterion },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.conversionDate) {
                                                    return displayDate(rowData.conversionDate);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Financial Year',
                                            field: 'financialYear',
                                            filter: {
                                                options: financialYears.map((f) => ({ name: f })),
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: CFCDeposit) => {
                                                if (rowData.financialYear) {
                                                    return rowData.financialYear;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                    ]}
                                    defaultColConfig={[
                                        { header: 'Status', visible: true },
                                        { header: 'Account', visible: true },
                                        { header: 'FX', visible: true },
                                        { header: 'External Ref.', visible: true },
                                        { header: 'Invoice Number', visible: true },
                                        { header: 'Invoice Counterparty', visible: true },
                                        { header: 'Linked Order', visible: true },
                                        { header: 'Deposit Date', visible: true },
                                        { header: 'FX Amount', visible: true },
                                        { header: 'Spot at Deposit', visible: true },
                                        { header: 'Reference', visible: true },
                                        { header: 'Settlement Instruction', visible: true },
                                        { header: 'Realised PnL', visible: true },
                                        { header: 'Spot at Conversion', visible: true },
                                        { header: 'Conversion Date', visible: true },
                                        { header: 'Financial Year', visible: false },
                                    ]}
                                    showCheckboxes
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    showFilterRow={showFilterRow}
                                    count={depositsTotal}
                                    data={depositsResponse || []}
                                    loading={loading}
                                    selected={selected ? selected : []}
                                    onSelectAll={handleSelectAll}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30, 200]}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    tableID={'cfcDeposits'}
                                    onRowCheck={handleSelectRow}
                                    rowClickAction={handleSelectRow}
                                    handleChangePage={handleChangePage}
                                    initialCriteria={baseCriteriaConfig}
                                />
                            </React.Fragment>
                        );
                    })()}
                </Collapse>
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
            {showNewDialog && (
                <AddNewSI
                    closeDialog={() => setShowNewDialog(false)}
                    currencies={partyCurrencies || []}
                    importExport={importExport}
                    onSubmit={(createDraftInfo: { currency: string; date: number }) =>
                        handleCreateNew({ ...createDraftInfo, importExport, cfcDeposits: selected || [] })
                    }
                    show={showNewDialog}
                    currency={(selected && selected[0].currency) || undefined}
                />
            )}
            {editOpen && (
                <Edit
                    initialSettlementInstruction={selectedSI || ({} as SettlementInstruction)}
                    onClose={() => {
                        setEditOpen(false);
                    }}
                    onSubmit={() => setReloadTriggerMain()}
                    onUpdate={() => void 0}
                    open={editOpen}
                />
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    availableRootContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        height: '25px',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: theme.palette.custom.export.main,
        borderWidth: '1.7px',
    },
    availableFontWrapper: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.custom.export.main,
        fontSize: '9.5px',
    },
    amountColor: {
        color: theme.palette.custom.export.main,
    },
    defaultFontWrapper: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '9.5px',
        alignItems: 'center',
    },
}));

export default CFCDeposits;
