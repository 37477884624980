import React, { SyntheticEvent, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButton, VARIANT, SIZE } from 'components/BaseButton';
import { useTheme } from '@material-ui/core';
import { GetApp as Download } from '@material-ui/icons';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import FileSaver, { saveAs } from 'file-saver';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import { Client, ProcessingOrg } from 'popcorn-js/party';
import { useServiceSync } from 'hooks/useService';
import Generator, {
    GenerateNetCashflowReportResponse,
    GenerateOpenHedgeReportRequest,
    GenerateOpenHedgeReportResponse,
    GeneratePortfolioReportRequest,
    GeneratePortfolioReportResponse,
} from 'popcorn-js/report';
import { AppContext, AppContextT } from 'context';
import { format } from 'date-fns';
import { Downloader, DownloadRequest, DownloadResponse } from 'popcorn-js/rick/downloader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

interface ReportsButtonProps {
    disabled?: boolean;
    onClick?: (e?: string) => void;
}

const ReportsButton: React.FC<ReportsButtonProps> = (props: ReportsButtonProps) => {
    const classes = useStyles();
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    const currentParty = (appContext.party as Client | ProcessingOrg) || ({} as Client);
    const [DownloadPortfolioReport] = useServiceSync<GeneratePortfolioReportRequest, GeneratePortfolioReportResponse>(
        Generator.GeneratePortfolioReport,
    );

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const handleDownloadPortfolio = async () => {
        const currencies: string[] = [];
        for (let i = 0; i < currentParty.currencyPairs.length; i++) {
            currencies[i] = currentParty.currencyPairs[i].split('/')[0];
        }

        try {
            const downloadPortfolioReportResponse = await DownloadPortfolioReport({
                currencies: currencies.sort(),
            });
            // convert base64 to byte array
            const binData = atob(downloadPortfolioReportResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            FileSaver.saveAs(blob, `Gross Exposure Report ${moment().format(SystemDateFormat)}.xlsx`);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const [GenerateOpenHedges] = useServiceSync<GenerateOpenHedgeReportRequest, GenerateOpenHedgeReportResponse>(
        Generator.GenerateOpenHedgesReport,
    );

    const [GenerateNetTradeCashflow] = useServiceSync<Record<string, never>, GenerateNetCashflowReportResponse>(
        Generator.GenerateNetCashflowReport,
    );

    const handleDownloadOpenHedges = async () => {
        try {
            const generateOpenHedgesResponse = await GenerateOpenHedges({});
            // convert base64 to byte array
            const binData = atob(generateOpenHedgesResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Open Hedges Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const handleDownloadNetTradeCashflow = async () => {
        try {
            const generateNetTradeCashflowResponse = await GenerateNetTradeCashflow({});
            // convert base64 to byte array
            const binData = atob(generateNetTradeCashflowResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Net Trade Cashflow Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const [downloadDailyRatesExcel] = useServiceSync<DownloadRequest, DownloadResponse>(
        Downloader.downloadDailyRatesExcel,
    );
    const exportDailyRatesExcel = async () => {
        try {
            const dailyRatesExcel = await downloadDailyRatesExcel({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(dailyRatesExcel.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Extract.xlsx');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const [downloadRatesCache] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.downloadRatesCache);
    const exportInstances = async () => {
        try {
            const cacheRatesResult = await downloadRatesCache({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(cacheRatesResult.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Full.xlsx');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    return (
        <div className={classes.root}>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                successMessage={successMessage}
            />
            <div className={classes.buttonWrapper}>
                <BaseButton
                    id={'ReportsButton/View'}
                    disabled={props.disabled}
                    variant={VARIANT.NO_OUTLINE}
                    size={SIZE.MEDIUM}
                    onClick={(event: SyntheticEvent<HTMLElement> | undefined) => {
                        setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined);
                    }}
                    text={'REPORTS'}
                />

                <div className={classes.icon}>
                    <Download className={css({ color: theme.palette.text.primary })} />
                </div>
                <ActionsMenu
                    id={'Dashboard/Reports/download-options'}
                    anchorElement={moreOptionsAnchorEl}
                    onClose={() => setMoreActionsAnchorEl(undefined)}
                    title={'Download Options'}
                    items={[
                        {
                            id: 'open-hedges-report',
                            text: 'Open Hedges Report',
                            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                                event?.preventDefault();
                                handleDownloadOpenHedges().then();
                            },
                        },
                        {
                            id: 'gross-exposure-per-month',
                            text: 'Gross Exposure per Month',
                            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                                event?.preventDefault();
                                handleDownloadPortfolio().then();
                            },
                        },
                        {
                            id: 'net-trade-cashflow',
                            text: 'Net Trade Cashflow',
                            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                                event?.preventDefault();
                                handleDownloadNetTradeCashflow().then();
                            },
                        },
                        {
                            id: 'rates-key-currency-pairs',
                            text: 'Rates - Key Currency Pairs',
                            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                                event?.preventDefault();
                                exportDailyRatesExcel().then();
                            },
                        },
                        {
                            id: 'rates-all-currency-pairs',
                            text: 'Rates - All Currency Pairs',
                            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                                event?.preventDefault();
                                exportInstances().then();
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default ReportsButton;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '160px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
    },

    buttonWrapper: {
        justifySelf: 'center',
        fontSize: '14px',
        border: 'solid #FFFFFF52 ',
        borderRadius: '6px',
        width: '130px',
        height: '35px',
        alignItems: 'center',
        display: 'flex',
        '&:hover': {
            backgroundColor: '#FFFFFF52',
            opacity: 0.6,
        },
        zIndex: 1000,
    },
    icon: {
        marginRight: '50px',
        marginTop: '4px',
        zIndex: 1001,
        position: 'relative',
    },
}));
