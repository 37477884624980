import React, { useContext } from 'react';
import { Link } from '@material-ui/core';
import { AppContext, AppContextT } from 'context';

export const SwitchContextLink = (props: { counterparty: string; partyCode: string }): JSX.Element => {
    const { switchContext, party } = useContext<AppContextT>(AppContext);
    const title = props.counterparty?.toUpperCase();
    return (
        <Link
            disabled={party.partyCode === props.partyCode}
            component="button"
            variant="body2"
            onClick={(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                switchContext(props.partyCode).finally();
            }}
        >
            {title}
        </Link>
    );
};
