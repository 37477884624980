import { restRPC } from 'utils/restrpc';
import { Revenue } from 'popcorn-js/revenue';
import { marshalCriteria } from '../search/marshaller';
import { Criteria, Criterion, CriterionFilterType, Query } from '../search';

export type FindRevenueRequest = {
    deleted?: boolean;
    filterType?: CriterionFilterType;
    query?: Query;
    criteriaTrade?: string[] | Criterion[];
    criteriaOption?: string[] | Criterion[];
};

export type FindRevenueResponse = {
    records: Revenue[];
    total: number;
};

export interface HandlerI {
    ServiceProviderName: string;

    Find(request: FindRevenueRequest): Promise<FindRevenueResponse>;
}

export const RevenueHandler: HandlerI = {
    ServiceProviderName: 'Revenue-Handler',

    Find(request: FindRevenueRequest): Promise<FindRevenueResponse> {
        const serializedCriteriaTrade = request.criteriaTrade
            ? marshalCriteria(request.criteriaTrade as Criteria)
            : undefined;
        const serializedCriteriaOption = request.criteriaOption
            ? marshalCriteria(request.criteriaOption as Criteria)
            : undefined;
        return restRPC<FindRevenueRequest, FindRevenueResponse>({
            method: `${RevenueHandler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteriaTrade: serializedCriteriaTrade,
                criteriaOption: serializedCriteriaOption,
            },
        });
    },
};
