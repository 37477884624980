import React, { useState, ReactElement } from 'react';
import { useTheme } from '@material-ui/core';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from 'recharts';
import { CustomTheme } from 'theme/custom';
import Generator, { GenerateMaturityLadderRequest, TotalOnDate } from 'popcorn-js/report';
import { makeStyles } from '@material-ui/styles';
import { useService } from 'hooks/useService';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: CustomTheme) => ({
    maturityHeader: {
        color: theme.palette.custom.stellcapBrand1.light,
        fontSize: '12px',
        padding: theme.spacing(1),
        marginLeft: '14px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    maturityWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '100%',
        marginTop: '0px',
        padding: '8px',
        width: '330px',
        paddingLeft: '0px',
    },
    barchartHeadings: {
        marginLeft: '-5px',
        marginRight: '4px',
    },
    maturityValuesLoading: {
        gridColumn: '3/3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        display: 'flex',
        flexDirection: 'row',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.light,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

const MaturityLadder = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const TickFormatter = (tick: number) => {
        return (tick / 1000).toFixed(0);
    };

    function populateLegend(
        graphKeys: { key: string; legendName: string; color: string }[],
    ): { value: string; color: string }[] {
        const legendPayload: { value: string; color: string }[] = [];
        for (const entry of graphKeys) {
            if (!legendPayload.find(({ value }) => value === entry.legendName)) {
                legendPayload.push({ value: entry.legendName, color: entry.color });
            }
        }
        return legendPayload;
    }

    const colorArray = [
        theme.palette.custom.data.data3.main,
        theme.palette.custom.data.data4.main,
        theme.palette.custom.data.data1.main,
        theme.palette.custom.data.data2.main,
    ];
    const [importFlag, setImportFlag] = useState(false);
    const [exportFlag, setExportFlag] = useState(false);

    function transformLadderViewData(
        data: TotalOnDate[],
    ): { ladderKeys: { key: string; legendName: string; color: string }[] } {
        const ladderKeys: { key: string; legendName: string; color: string }[] = [];
        let i = 0;
        for (const entry of data) {
            if (entry.date.length === 10) {
                entry.date = entry.date.substring(5);
            }
            for (const key of Object.keys(entry.currencyTotal || {})) {
                const n = key.indexOf('/');
                const name = key.substring(0, n !== -1 ? n : key.length);
                const ImportExport = key.slice(-1);
                const newKey = {
                    key: 'currencyTotal.' + key,
                    color: colorArray[i],
                    legendName: name,
                };
                if (ImportExport === 'I' && !importFlag) {
                    setImportFlag(true);
                }
                if (ImportExport === 'E' && !exportFlag) {
                    setExportFlag(true);
                }
                if (ladderKeys.length !== 0) {
                    let found = false;
                    let foundName = false;
                    for (let j = 0; j < ladderKeys.length; j++) {
                        if (ladderKeys[j].key === newKey.key) {
                            found = true;
                            break;
                        }
                        if (ladderKeys[j].legendName === newKey.legendName) {
                            newKey.color = ladderKeys[j].color;
                            foundName = true;
                            break;
                        }
                    }
                    if (!found) {
                        ladderKeys.push(newKey);
                        if (!foundName) {
                            i++;
                        }
                    }
                } else {
                    ladderKeys.push(newKey);
                    i++;
                }
            }
        }
        return { ladderKeys };
    }

    const [{ response, loading, error }] = useService(
        {} as GenerateMaturityLadderRequest,
        Generator.GenerateMaturityLadderReport,
    );

    if (!response && loading) {
        return (
            <div className={classes.maturityWrapper}>
                <div
                    className={classes.maturityValuesLoading}
                    style={{
                        backgroundColor: theme.palette.custom.paperExtended.paper2,
                        padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
                        paddingTop: '180px',
                    }}
                >
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    } else if (response && !loading && error === '') {
        const maturityLadderData = response.totals;
        const graphData = transformLadderViewData(maturityLadderData);
        return (
            <div className={classes.maturityWrapper}>
                <div className={classes.maturityHeader}>MATURITY LADDER</div>
                <ResponsiveContainer height={373} width="95%">
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `auto 1fr`,
                        }}
                    >
                        <div className={classes.barchartHeadings}>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateRows: `${importFlag ? '85px' : ''} 75px 70px  ${
                                        exportFlag ? '60px' : ''
                                    }`,
                                    alignItems: 'self-end',
                                    width: '35px',
                                }}
                            >
                                {importFlag && (
                                    <div
                                        style={{
                                            transform: 'rotate(-90deg)',
                                            fontSize: '12px',
                                            letterSpacing: '0px',
                                            color: theme.palette.custom.import.main,
                                            opacity: 1,
                                        }}
                                    >
                                        IMPORTS
                                    </div>
                                )}
                                <div
                                    style={{
                                        transform: 'rotate(-90deg)',
                                        fontSize: '12px',
                                        letterSpacing: '0px',
                                    }}
                                >
                                    (Thousands)
                                </div>
                                <div
                                    style={{
                                        transform: 'rotate(-90deg)',
                                        fontSize: '12px',
                                        letterSpacing: '0px',
                                    }}
                                >
                                    Amount Due
                                </div>
                                {exportFlag && (
                                    <div
                                        style={{
                                            transform: 'rotate(-90deg)',
                                            fontSize: '12px',
                                            letterSpacing: '0px',
                                            color: theme.palette.custom.export.main,
                                            opacity: 1,
                                        }}
                                    >
                                        EXPORTS
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <BarChart
                                data={maturityLadderData}
                                height={328}
                                margin={{
                                    top: 32,
                                    right: 32,
                                    left: 0,
                                    bottom: 24,
                                }}
                                width={290}
                                stackOffset={'sign'}
                            >
                                <XAxis
                                    orientation="top"
                                    axisLine={false}
                                    dataKey="date"
                                    interval={0}
                                    tickMargin={10}
                                    textAnchor="start"
                                    angle={-45}
                                    tick={{
                                        fill: theme.palette.grey[100],
                                        fontSize: '10px',
                                    }}
                                />
                                <YAxis
                                    allowDecimals
                                    tickCount={7.5}
                                    axisLine={false}
                                    interval={0}
                                    tickFormatter={TickFormatter}
                                    tickLine={false}
                                    tick={{ fill: theme.palette.grey[100], fontSize: '11px' }}
                                />
                                <CartesianGrid stroke={theme.palette.custom.icons.main} vertical={false} />
                                <Tooltip cursor={{ fill: theme.palette.primary.contrastText }} content={<div />} />
                                {(graphData.ladderKeys || []).map((k) => {
                                    return <Bar dataKey={k.key} fill={k.color} key={k.key} stackId={k.legendName} />;
                                })}
                                <ReferenceLine stroke={theme.palette.text.secondary} y={0} />
                            </BarChart>
                            <BarChart
                                height={60}
                                width={populateLegend(graphData.ladderKeys).length * 75}
                                margin={{ top: 0, right: 8, bottom: 16, left: 0 }}
                            >
                                <Legend
                                    payload={populateLegend(graphData.ladderKeys)}
                                    width={populateLegend(graphData.ladderKeys).length * 75}
                                    wrapperStyle={{
                                        background: theme.palette.background.paper,
                                        borderRadius: '3px',
                                        padding: '8px',
                                    }}
                                />
                            </BarChart>
                        </div>
                    </div>
                </ResponsiveContainer>
            </div>
        );
    } else {
        return (
            <div className={classes.maturityValuesLoading}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>{error}</div>
            </div>
        );
    }
};
export default MaturityLadder;
