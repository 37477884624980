import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StandardCard } from 'components/Card/Card';
import { createStyles, Link, makeStyles, Tooltip as MUITooltip, useTheme, withStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseButton, BaseButtonGroup, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { DisplayClientTotals } from 'views/UploadClientData/DisplayClientTotals';
import { Info as InfoIcon } from '@material-ui/icons';
import { UploadClientDataButton } from './UploadClientDataButton';
import { UploadClientDataGrid } from './UploadClientDataGrid';
import { ImportExport } from 'popcorn-js/tradeV2';
import { Client, IsClient, ProcessingOrg } from 'popcorn-js/party';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import {
    FindClientHistoryPositionResponse,
    GenerateClientPositionRequest,
    GenerateClientPositionResponse,
} from 'popcorn-js/dayEndPosition/handler';
import { DayEndPosition, DayEndPositionPerCurrency, ExposureType } from 'popcorn-js/dayEndPosition';
import { displayAmountWithFraction } from '../Client/util';
import { FindRequest } from 'popcorn-js';
import { CriteriaType } from 'popcorn-js/search';
import moment, { now } from 'moment';
import { SystemDateFormat } from 'constants/formats';
import { Category } from 'components/upload';
import InvoiceUploadContainer from 'components/invoice/upload/InvoiceUploadContainer';
import OrderUploadContainer from 'components/order/upload/OrderUploadContainer';
import { downloadTemplate as downloadInvoicesTemplate } from 'components/invoice/upload';
import { downloadTemplate as downloadOrdersTemplate } from 'components/order/upload';
import { ScaleLoader as Spinner } from 'react-spinners';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { UploadUserGuide } from './UploadUserGuide';

type dayEndPositionPerCurrencyFormatted = {
    id: string;
    dateLatest: string;
    dateSecondLatest: string;
};
export const UploadClientData = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.warning.contrastText,
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(MUITooltip);
    const appContext = useContext<AppContextT>(AppContext);
    const { dayEndPositionHandler } = useContext<ServiceContextT>(ServiceContext);
    const party = (appContext.party || {}) as Client | ProcessingOrg;
    const initialImportExport = (party: Client | ProcessingOrg) => {
        let importExport = ImportExport.IMPORT;
        if (IsClient(party)) {
            if (party.export && !party.import) {
                importExport = ImportExport.EXPORT;
            }
        }
        return importExport;
    };
    const [importExport, setImportExport] = useState<ImportExport | undefined>(initialImportExport(party));
    const [loading, setLoading] = useState<boolean>(false);
    const [dayEndPositions, setDayEndPositions] = useState<DayEndPosition[] | undefined>([]);
    const [uploadedToday, setUploadedToday] = useState<DayEndPosition[] | undefined>([]);
    const [invoiceRows, setInvoiceRows] = useState<dayEndPositionPerCurrencyFormatted[] | undefined>([]);
    const [orderRows, setOrderRows] = useState<dayEndPositionPerCurrencyFormatted[] | undefined>([]);
    const [notesRows, setNotesRows] = useState<dayEndPositionPerCurrencyFormatted[] | undefined>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [uploadingInvoices, setUploadingInvoices] = useState<boolean>(false);
    const [uploadingNotes, setUploadingNotes] = useState<boolean>(false);
    const [uploadingOrders, setUploadingOrders] = useState<boolean>(false);
    const [showUserGuide, setShowUserGuide] = useState<boolean>(false);
    const [invoiceOrderCategory, setInvoiceOrderCategory] = useState<Category | undefined>(
        importExport === ImportExport.IMPORT ? Category.Purchase : Category.Sales,
    );
    const [notesCategory, setNotesCategory] = useState<Category | undefined>(
        importExport === ImportExport.IMPORT ? Category.PurchaseNotes : Category.SalesNotes,
    );
    const uploadClientHeaderImport = 'Upload Purchase Orders / Invoices / Notes';
    const uploadClientHeaderExport = 'Upload Sales Orders / Invoices / Notes';

    const dateLatest = moment(now()).subtract(1, 'days').format(SystemDateFormat);
    const dateSecondLatest = moment(now()).subtract(2, 'days').format(SystemDateFormat);
    const initialCriteria = [
        { type: CriteriaType.TextCriterion, field: 'importExport', text: importExport },
        { type: CriteriaType.TextCriterion, field: 'date', text: dateLatest },
        { type: CriteriaType.TextCriterion, field: 'date', text: dateSecondLatest },
    ];

    const columns = [
        {
            field: 'id',
            width: 110,
            renderHeader: () => (
                <div
                    style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                    }}
                >
                    {'Date'}
                </div>
            ),
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            color: theme.palette.text.primary,
                            fontWeight: 'bold',
                            marginLeft: '8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value.toString() : ''}
                    </div>
                );
            },
        },
        {
            field: 'dateLatest',
            width: 155,
            renderHeader: () => (
                <div
                    style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                    }}
                >
                    {dateLatest}
                </div>
            ),
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            color: '#FFFFFF99',
                            fontWeight: 'normal',
                        }}
                    >
                        {cellValues.value ? cellValues.value.toString() : ''}
                    </div>
                );
            },
        },
        {
            field: 'dateSecondLatest',
            width: 155,
            renderHeader: () => (
                <div
                    style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                    }}
                >
                    {dateSecondLatest}
                </div>
            ),
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            color: '#FFFFFF99',
                            fontWeight: 'normal',
                        }}
                    >
                        {cellValues.value ? cellValues.value.toString() : ''}
                    </div>
                );
            },
        },
    ];

    // service providers
    const [generateClientPosition] = useServiceSync<GenerateClientPositionRequest, GenerateClientPositionResponse>(
        dayEndPositionHandler?.GenerateClientPosition,
    );
    const [generateUploadedToday] = useServiceSync<GenerateClientPositionRequest, GenerateClientPositionResponse>(
        dayEndPositionHandler?.GenerateUploadedToday,
    );
    const [findClientHistoryPosition] = useServiceSync<FindRequest, FindClientHistoryPositionResponse>(
        dayEndPositionHandler?.FindClientHistoryPosition,
    );
    useEffect(() => {
        generateClientPositions(importExport).finally();
        generateUploadToday(importExport).finally();
        findClientHistoryPositions().finally();
    }, [importExport]);

    const handleUploadSuccess = async () => {
        generateClientPositions(importExport).finally();
        generateUploadToday(importExport).finally();
    };
    const generateClientPositions = async (_importExport?: ImportExport) => {
        setLoading(true);
        try {
            const result = await generateClientPosition({
                importExport: _importExport || importExport,
            });
            setDayEndPositions(result.dayEndPositions);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const generateUploadToday = async (_importExport?: ImportExport) => {
        setLoading(true);
        try {
            const result = await generateUploadedToday({
                importExport: _importExport || importExport,
            });
            setUploadedToday(result.dayEndPositions);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };
    const formatDayEndPosition = (dayEndCurrency: DayEndPositionPerCurrency[]) => {
        const formattedDayEndPosition: dayEndPositionPerCurrencyFormatted[] = [];
        if (dayEndCurrency) {
            for (const dataGridValue of dayEndCurrency) {
                formattedDayEndPosition.push({
                    dateLatest: displayAmountWithFraction(dataGridValue.dateLatest),
                    dateSecondLatest: displayAmountWithFraction(dataGridValue.dateSecondLatest),
                    id: dataGridValue.id,
                });
            }
        }
        return formattedDayEndPosition;
    };

    const findClientHistoryPositions = async () => {
        setLoading(true);
        try {
            const result = await findClientHistoryPosition({
                criteria: [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'exposureType',
                        text: ExposureType.Invoices,
                    },
                    ...initialCriteria,
                ],
                query: {
                    sortBy: ['date'],
                    order: ['desc'],
                    offset: 0,
                },
            });
            setInvoiceRows(formatDayEndPosition(result.dayEndPositionsPerCurrency || []));
        } catch (e) {
            setErrorMessage(e);
        }
        try {
            const result = await findClientHistoryPosition({
                criteria: [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'exposureType',
                        text: ExposureType.Orders,
                    },
                    ...initialCriteria,
                ],
                query: {
                    sortBy: ['date'],
                    order: ['desc'],
                    offset: 0,
                },
            });
            setOrderRows(formatDayEndPosition(result.dayEndPositionsPerCurrency || []));
        } catch (e) {
            setErrorMessage(e);
        }
        try {
            const result = await findClientHistoryPosition({
                criteria: [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'exposureType',
                        text: ExposureType.Notes,
                    },
                    ...initialCriteria,
                ],
                query: {
                    sortBy: ['date'],
                    order: ['desc'],
                    offset: 0,
                },
            });
            setNotesRows(formatDayEndPosition(result.dayEndPositionsPerCurrency || []));
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    {importExport === ImportExport.IMPORT ? uploadClientHeaderImport : uploadClientHeaderExport}
                </div>
                <div className={classes.selectOption}>Select Option:</div>
                <div className={classes.toggleButton}>
                    <BaseButtonGroup>
                        <BaseButton
                            id={`UploadClientData/Import`}
                            variant={VARIANT.CONTAINED}
                            color={importExport === ImportExport.IMPORT ? COLOR.IMPORT : COLOR.INACTIVE}
                            size={SIZE.MEDIUM}
                            onClick={() => {
                                setImportExport(ImportExport.IMPORT);
                                setInvoiceOrderCategory(Category.Purchase);
                                setNotesCategory(Category.PurchaseNotes);
                            }}
                            text={'Import'}
                        />
                        <BaseButton
                            id={`UploadClientData/Export`}
                            variant={VARIANT.CONTAINED}
                            color={importExport === ImportExport.EXPORT ? COLOR.EXPORT : COLOR.INACTIVE}
                            size={SIZE.MEDIUM}
                            onClick={() => {
                                setImportExport(ImportExport.EXPORT);
                                setInvoiceOrderCategory(Category.Sales);
                                setNotesCategory(Category.SalesNotes);
                            }}
                            text={'Export'}
                        />
                    </BaseButtonGroup>
                </div>
            </div>
            <div className={classes.contentLayout}>
                {uploadingInvoices && (
                    <InvoiceUploadContainer
                        category={invoiceOrderCategory}
                        enableDownloadTemplate
                        onAwayClick={() => setUploadingInvoices(false)}
                        showImportDialog={() => setUploadingInvoices(true)}
                        uploadSuccess={handleUploadSuccess}
                    />
                )}
                {uploadingNotes && (
                    <InvoiceUploadContainer
                        category={notesCategory}
                        enableDownloadTemplate
                        onAwayClick={() => setUploadingNotes(false)}
                        showImportDialog={() => setUploadingNotes(true)}
                        uploadSuccess={handleUploadSuccess}
                    />
                )}
                {uploadingOrders && (
                    <OrderUploadContainer
                        category={invoiceOrderCategory}
                        enableDownloadTemplate={true}
                        onAwayClick={() => setUploadingOrders(false)}
                        showImportDialog={() => setUploadingOrders(true)}
                        uploadSuccess={handleUploadSuccess}
                    />
                )}
                {showUserGuide && <UploadUserGuide show={showUserGuide} onAwayClick={() => setShowUserGuide(false)} />}
                <NotificationSweetAlert errorMessage={errorMessage} onClose={() => setErrorMessage(undefined)} />
                <div id="UploadClientDataRoot" className={classes.cardContentRoot}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'UploadClientData/title2',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div
                                            className={
                                                importExport === ImportExport.IMPORT
                                                    ? classes.importHeaderStyle
                                                    : classes.exportHeaderStyle
                                            }
                                        >
                                            Orders
                                        </div>
                                    ),
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'UploadClientData/button',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div className={classes.uploadButtonWrapper}>
                                            <UploadClientDataButton
                                                disabled={false}
                                                onClick={() => setUploadingOrders(true)}
                                            />
                                        </div>
                                    ),
                                },
                            ],
                        }}
                    >
                        <>
                            {!loading && (
                                <div className={classes.contentWrapper}>
                                    <div className={classes.cardLayout}>
                                        <div className={classes.clientTotals}>
                                            <DisplayClientTotals variant={'heading'} heading={'Uploaded Today:'} />
                                            {(uploadedToday || [])
                                                .filter((position) => position.exposureType === ExposureType.Orders)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className={classes.border}>
                                            <DisplayClientTotals variant={'heading'} heading={'Total:'} />
                                            {(dayEndPositions || [])
                                                .filter((position) => position.exposureType === ExposureType.Orders)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            <div style={{ height: '20px' }} />
                                        </div>
                                    </div>
                                    <div className={classes.banner}>
                                        <div className={classes.bannerText}>End of Day Balances</div>
                                    </div>
                                    <div className={classes.tableWrapper}>
                                        <UploadClientDataGrid columns={columns} rows={orderRows || []} />
                                    </div>
                                    <div className={classes.templateWrapper}>
                                        <div className={classes.needHelp}>
                                            <div>
                                                <LightTooltip placement={'right-end'} title={''}>
                                                    <InfoIcon className={classes.helpIcon} />
                                                </LightTooltip>
                                            </div>
                                            Need Help?
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => setShowUserGuide(true)}
                                                >
                                                    {'View User Guide'}
                                                </Link>
                                            </div>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => downloadOrdersTemplate()}
                                                >
                                                    {'Download Template'}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div className={classes.loadingLayout}>
                                    <Spinner color={'#FFF'} loading={loading} />
                                </div>
                            )}
                        </>
                    </StandardCard>
                </div>
                <div id="UploadClientDataRoot" className={classes.cardContentRoot}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'UploadClientData/title2',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div
                                            className={
                                                importExport === ImportExport.IMPORT
                                                    ? classes.importHeaderStyle
                                                    : classes.exportHeaderStyle
                                            }
                                        >
                                            Invoices
                                        </div>
                                    ),
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'UploadClientData/button',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div className={classes.uploadButtonWrapper}>
                                            <UploadClientDataButton
                                                disabled={false}
                                                onClick={() => setUploadingInvoices(true)}
                                            />
                                        </div>
                                    ),
                                },
                            ],
                        }}
                    >
                        <>
                            {!loading && (
                                <div className={classes.contentWrapper}>
                                    <div className={classes.cardLayout}>
                                        <div className={classes.clientTotals}>
                                            <DisplayClientTotals variant={'heading'} heading={'Uploaded Today:'} />
                                            {(uploadedToday || [])
                                                .filter((position) => position.exposureType === ExposureType.Invoices)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className={classes.border}>
                                            <DisplayClientTotals variant={'heading'} heading={'Total:'} />
                                            {(dayEndPositions || [])
                                                .filter((position) => position.exposureType === ExposureType.Invoices)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            <div style={{ height: '20px' }} />
                                        </div>
                                    </div>
                                    <div className={classes.banner}>
                                        <div className={classes.bannerText}>End of Day Balances</div>
                                    </div>
                                    <div className={classes.tableWrapper}>
                                        <UploadClientDataGrid columns={columns} rows={invoiceRows || []} />
                                    </div>
                                    <div className={classes.templateWrapper}>
                                        <div className={classes.needHelp}>
                                            <div>
                                                <LightTooltip placement={'right-end'} title={''}>
                                                    <InfoIcon className={classes.helpIcon} />
                                                </LightTooltip>
                                            </div>
                                            Need Help?
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => setShowUserGuide(true)}
                                                >
                                                    {'View User Guide'}
                                                </Link>
                                            </div>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => downloadInvoicesTemplate()}
                                                >
                                                    {'Download Template'}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div className={classes.loadingLayout}>
                                    <Spinner color={'#FFF'} loading={loading} />
                                </div>
                            )}
                        </>
                    </StandardCard>
                </div>
                <div id="UploadClientDataRoot" className={classes.debitCreditNoteCard}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'UploadClientData/title2',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div
                                            className={
                                                importExport === ImportExport.IMPORT
                                                    ? classes.importHeaderStyle
                                                    : classes.exportHeaderStyle
                                            }
                                        >
                                            Debit/(Credit) Notes
                                        </div>
                                    ),
                                },
                            ],
                            itemsRight: [
                                {
                                    id: 'UploadClientData/button',
                                    type: ITEM_VARIATION.ELEMENT,
                                    element: (
                                        <div className={classes.uploadButtonWrapper}>
                                            <UploadClientDataButton
                                                disabled={false}
                                                onClick={() => setUploadingNotes(true)}
                                            />
                                        </div>
                                    ),
                                },
                            ],
                        }}
                    >
                        <>
                            {!loading && (
                                <div className={classes.contentWrapper}>
                                    <div className={classes.cardLayout}>
                                        <div className={classes.clientTotals}>
                                            <DisplayClientTotals variant={'heading'} heading={'Uploaded Today:'} />
                                            {(uploadedToday || [])
                                                .filter((position) => position.exposureType === ExposureType.Notes)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className={classes.border}>
                                            <DisplayClientTotals variant={'heading'} heading={'Total:'} />
                                            {(dayEndPositions || [])
                                                .filter((position) => position.exposureType === ExposureType.Notes)
                                                .map((position, idx) => {
                                                    return (
                                                        <div key={idx} style={{ display: 'flex', marginTop: '10px' }}>
                                                            <DisplayClientTotals
                                                                variant={'currencyCode'}
                                                                heading={position.currency}
                                                            />
                                                            <DisplayClientTotals
                                                                variant={'total'}
                                                                heading={displayAmountWithFraction(position.total)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            <div style={{ height: '20px' }} />
                                        </div>
                                    </div>
                                    <div className={classes.banner}>
                                        <div className={classes.bannerText}>End of Day Balances</div>
                                    </div>
                                    <div className={classes.tableWrapper}>
                                        <UploadClientDataGrid columns={columns} rows={notesRows || []} />
                                    </div>

                                    <div className={classes.templateWrapper}>
                                        <div className={classes.needHelp}>
                                            <div>
                                                <LightTooltip placement={'right-end'} title={''}>
                                                    <InfoIcon className={classes.helpIcon} />
                                                </LightTooltip>
                                            </div>
                                            Need Help?
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => setShowUserGuide(true)}
                                                >
                                                    {'View User Guide'}
                                                </Link>
                                            </div>
                                            <div className={classes.downloadTemplate}>
                                                <Link
                                                    disabled={false}
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => downloadInvoicesTemplate()}
                                                >
                                                    {'Download Template'}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div className={classes.loadingLayout}>
                                    <Spinner color={'#FFF'} loading={loading} />
                                </div>
                            )}
                        </>
                    </StandardCard>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        root: {
            height: '100%',
            overflowY: 'hidden',
            justifyContent: 'center',
            width: '1170px',
            overflowX: 'hidden',
        },
        header: {
            height: '40px',
            justifyItems: 'center',
            alignItems: 'right',
            margin: '0px 10px 0px 0px',
            display: 'flex',
            width: '1152px',
        },
        contentLayout: {
            display: 'flex',
            marginBottom: '30px',
            height: 'auto',
            width: '1167px',
        },
        cardLayout: {
            display: 'flex',
            overflowY: 'hidden',
        },
        headerText: {
            fontSize: '20px',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            padding: theme.spacing(1),
            width: '500px',
        },
        selectOption: {
            marginLeft: '343px',
            fontSize: '12px',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            padding: theme.spacing(2),
            alignItems: 'right',
        },
        toggleButton: {
            fontSize: '20px',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            marginLeft: '10px',
        },
        importHeaderStyle: {
            fontSize: '18px',
            marginLeft: '16px',
            color: theme.palette.custom.import.main,
        },
        exportHeaderStyle: {
            fontSize: '18px',
            marginLeft: '16px',
            color: theme.palette.custom.export.main,
        },
        border: { backgroundColor: theme.palette.background.paper, width: '6px' },
        cardContentRoot: {
            width: '374px',
            margin: '10px 15px 0px 0px',
        },
        debitCreditNoteCard: {
            width: '374px',
            marginTop: '10px',
        },
        contentWrapper: {
            height: '600px',
            justifyItems: 'center',
            backgroundColor: theme.palette.custom.paperExtended.paper2,
        },
        clientTotals: {
            width: '180px',
        },
        banner: {
            height: '20px',
            backgroundColor: theme.palette.background.paper,
            textAlign: 'left',
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
        },
        bannerText: {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            marginLeft: '5px',
        },
        tableWrapper: {
            height: '280px',
        },
        templateWrapper: {
            height: '60px',
            marginTop: '20px',
        },
        needHelp: {
            justifyItems: 'left',
            display: 'flex',
            marginLeft: '10px',
        },
        downloadTemplate: {
            display: 'flex',
            marginLeft: '43px',
            textDecoration: 'underline',
            textDecorationColor: '#3CBED7',
            width: '180px',
        },
        helpIcon: {
            width: '18px',
            height: '18px',
            color: theme.palette.text.secondary,
            alignContent: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            verticalAlign: 'middle',
            margin: '0px 5px 0px 10px',
        },
        uploadButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
            justifySelf: 'flex-end',
            marginBottom: '1px',
        },
        headerStyle: {
            marginLeft: '16px',
            fontSize: '18px',
        },
        loadingLayout: {
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
    }),
);
