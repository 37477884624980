import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useStyletron } from 'styletron-react';
import { Dialog, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { InvoiceTradeLink, SettlementInstruction, Status } from 'popcorn-js/settlementInstruction';
import { useService, useServiceSync } from 'hooks/useService';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { Trade } from 'popcorn-js/tradeV2';
import {
    DeleteForeverRequest,
    DeleteForeverResponse,
    Handler,
    RestoreRequest,
    RestoreResponse,
} from 'popcorn-js/settlementInstruction/handler';
import { financialYears, FindRequest, FindResponse, ImportExport } from 'popcorn-js';
import { Identifier, IdentifierType } from 'popcorn-js/search/identifier';
import { Downloader } from 'popcorn-js/settlementInstruction/downloader';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { Currency } from 'popcorn-js/currency';
import { processUnixDateForViewing } from 'utils';
import Big from 'big.js';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { CategoryMenu } from 'components/CategoryMenu/CategoryMenu';
import Table, { columnT } from 'components/Table/Table';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AddNewSI } from 'views/SettlementInstruction/AddNewSI';
import { Edit } from 'views/SettlementInstruction/Edit';
import { ACTION_BUTTON_TYPE, Item, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import { VARIANT } from 'components/BaseButton';
import Summary from 'views/SettlementInstruction/Summary';
import { StandardCard } from 'components/Card/Card';
import { debounce } from 'lodash';
import { displayCurrencyAmount, displayRate } from 'views/SettlementInstruction/util';
import { saveAs } from 'file-saver';

enum TabValue {
    Completed = 0,
    Unlinked = 1,
    Deleted = 2,
}

const pageSize = 12;
const completedSIsBaseCriteria: Record<string, Criterion> = {
    status: { type: CriteriaType.ExactCriterion, text: Status.Complete, field: 'status' },
    financialYear: { type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
};
const completedSIsBaseOrder = 'desc';
const completedSIsBaseSortyBy = 'date';
const unlinkedSIsBaseCriteria: Record<string, Criterion> = {
    status: { type: CriteriaType.TextNeCriterion, text: Status.Complete, field: 'status' },
    financialYear: { type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
};
const unlinkedSIsBaseOrder = 'desc';
const unlinkedSIsBaseSortyBy = 'date';
const deletedSIsBaseCriteria: Record<string, Criterion> = {
    status: { type: CriteriaType.ExactCriterion, text: Status.Draft, field: 'status' },
    financialYear: { type: CriteriaType.ExactCriterion, text: 'CURRENT', field: 'financialYear' },
};
const deletedSIsBaseOrder = 'desc';
const deletedSIsBaseSortyBy = 'date';

const baseCriteriaRecordToArray = (r: Record<string, Criterion>): Criteria =>
    Object.keys(r).map((field) => {
        return r[field] as Criterion;
    });

const StyledImExValue = (props: { SI: SettlementInstruction }) => {
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (props.SI.importExport === 'Import') {
        color = theme.palette.custom.import.main;
    }
    if (props.SI.importExport === 'Export') {
        color = theme.palette.custom.export.main;
    }
    return <span className={css({ color })}>{props.SI.importExport?.toUpperCase()}</span>;
};

type ModifiedSI = SettlementInstruction & {
    linkedTrades?: string;
    linkedTradesExternalReference?: string;
};

export const Workstation = (): React.ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);

    // notifications
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [confirmCallback, setConfirmCallback] = useState<() => void>(() => () => undefined);
    const [confirmationMessage, setConfirmationMessage] = useState<string | undefined>();
    //
    const initialQuery = {
        sortBy: ['date'],
        order: ['desc'] as QueryOrderT[],
        limit: pageSize,
        offset: 0,
    };
    const [query, setQuery] = useState<Query>(initialQuery);
    const [criteria, setCriteria] = useState<Criteria>(baseCriteriaRecordToArray(completedSIsBaseCriteria));
    const [filterCriteria, setFilterCriteria] = useState<Criteria | undefined>();
    const [sIs, setSIs] = useState<ModifiedSI[] | undefined>();
    const [total, setTotal] = useState<number>(0);
    const [statusOptions, setStatusOptions] = useState<{ v: string }[]>([{ v: Status.Complete }]);
    // general state
    const [activeTab, setActiveTab] = useState<TabValue>(0);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [newAnchorEl, setNewAnchorEl] = useState<HTMLElement | undefined>();
    const [showSISummaryDialog, setShowSISummaryDialog] = useState<boolean>(false);
    const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    // only do single selection for now
    const [selected, setSelected] = useState<SettlementInstruction | undefined>();
    // used in the creation of a new invoice, as the menu and dialog are separated (possibly combine into single component later...)
    const [importExport, setImportExport] = useState<ImportExport>(ImportExport.IMPORT);
    // boolean state to indicate whether a specific service invocation has started
    const [deleteStarted, setDeleteStarted] = useState<boolean>(false);
    const [cancelStarted, setCancelStarted] = useState<boolean>(false);
    const [deleteForeverStarted, setDeleteForeverStarted] = useState<boolean>(false);
    const [restoreStarted, setRestoreStarted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<ModifiedSI[] | undefined>();
    const [responseTotal, setResponseTotal] = useState<number>(0);
    const [deleteForeverResponse, setDeleteForeverResponse] = useState<DeleteForeverResponse | undefined>();
    const [restoreResponse, setRestoreResponse] = useState<RestoreResponse | undefined>();
    const cols = [
        { header: 'Number', visible: true },
        { header: 'IM/EX', visible: true },
        { header: 'Value Date', visible: true },
        { header: 'Currency', visible: true },
        { header: 'Avg Cost', visible: true },
        { header: 'Avg Capture', visible: true },
        { header: 'Avg Effective', visible: true },
        { header: 'Avg Deal', visible: true },
        { header: 'FX Amount', visible: true },
        { header: `${appContext.localCurrency?.isoCode} Amount`, visible: true },
        { header: 'P/L Costing', visible: true },
        { header: 'Client', visible: true },
        { header: 'Status', visible: true },
        { header: 'Linked Trades', visible: true },
        { header: 'FX Source', visible: true },

        // hidden
        { header: 'P/L Capture', visible: false },
        { header: 'Financial Year', visible: false },
        { header: 'Linked Trades Int Ref', visible: false },
    ];

    /* find SIs --------------------------------------------------------------------- */

    const [findSIs] = useServiceSync<FindRequest, FindResponse<SettlementInstruction>>(Handler.Find);

    const findEntities = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await findSIs({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            setResponseData(result.records);
            setResponseTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        } finally {
            setLoading(false);
        }
    };

    const getBaseCriteria = useCallback((): Record<string, Criterion> => {
        switch (activeTab) {
            case TabValue.Completed: {
                return completedSIsBaseCriteria;
            }
            case TabValue.Unlinked: {
                return unlinkedSIsBaseCriteria;
            }
            case TabValue.Deleted: {
                return deletedSIsBaseCriteria;
            }
        }
    }, [activeTab]);

    useEffect(() => {
        switch (activeTab) {
            case TabValue.Completed:
                setStatusOptions([{ v: Status.Complete }]);
                break;
            case TabValue.Unlinked: {
                const values: { v: string }[] = [] as { v: string }[];
                for (const s in Status) {
                    if (![Status.Complete].includes(s as Status)) {
                        values.push({ v: s });
                    }
                }
                setStatusOptions(values);
                break;
            }
            case TabValue.Deleted: {
                setStatusOptions([{ v: Status.Draft }]);
                break;
            }
        }
    }, [activeTab]);

    // reset sets criteria and find request to base values based on the selected tab
    const reset = useCallback(
        (tab: TabValue) => {
            setFilterCriteria(undefined);
            switch (tab) {
                case TabValue.Completed: {
                    setQuery({
                        limit: pageSize,
                        offset: 0,
                        sortBy: [completedSIsBaseSortyBy],
                        order: [completedSIsBaseOrder],
                    });
                    const _criteria = baseCriteriaRecordToArray(completedSIsBaseCriteria);
                    setCriteria(_criteria);
                    findEntities(_criteria, query, activeTab === 2).finally();
                    break;
                }
                case TabValue.Unlinked: {
                    setQuery({
                        limit: pageSize,
                        offset: 0,
                        sortBy: [unlinkedSIsBaseSortyBy],
                        order: [unlinkedSIsBaseOrder],
                    });
                    const _criteria = baseCriteriaRecordToArray(unlinkedSIsBaseCriteria);
                    setCriteria(_criteria);
                    findEntities(_criteria, query, activeTab === 2).finally();
                    break;
                }
                case TabValue.Deleted: {
                    setQuery({
                        limit: pageSize,
                        offset: 0,
                        order: [deletedSIsBaseOrder],
                        sortBy: [deletedSIsBaseSortyBy],
                    });
                    const _criteria = baseCriteriaRecordToArray(deletedSIsBaseCriteria);
                    setCriteria(_criteria);
                    findEntities(_criteria, query, activeTab === 2).finally();
                    break;
                }
            }
        },
        [activeTab],
    );

    // this effect calls reset each time the tab is changed
    useEffect(() => {
        reset(activeTab);
        setSelected(undefined);
        setShowFilter(false);
    }, [activeTab, reset]);

    const reduceUniqueTradeIDs = (links: InvoiceTradeLink[]): string[] => {
        const idSet = new Set();
        const ids = [] as string[];
        for (const link of links) {
            if (!idSet.has(link.tradeId)) {
                ids.push(link.tradeId);
                idSet.add(link.tradeId);
            }
        }
        return ids;
    };

    const generateTradeCriteria = (records: SettlementInstruction[]): Criterion[] => {
        const tradeCriteria = [] as Criterion[];
        const idSet = new Set();
        for (const SI of records) {
            if (SI && SI.invoiceTradeLinks) {
                for (const link of SI.invoiceTradeLinks) {
                    if (!idSet.has(link.tradeId)) {
                        tradeCriteria.push({ type: CriteriaType.ExactCriterion, field: 'id', text: link.tradeId });
                        idSet.add(link.tradeId);
                    }
                }
            }
        }
        return tradeCriteria;
    };

    // handles the service responses and updates the state
    useEffect(() => {
        if (responseData && responseTotal) {
            const tradeCriteria = generateTradeCriteria(responseData);
            if (tradeCriteria.length > 0) {
                TradeHandler.Find({ criteria: tradeCriteria })
                    .then((findResponse: FindResponse<Trade>) => {
                        const trades = findResponse.records as Trade[];
                        const newSIs = [] as ModifiedSI[];
                        for (const SI of responseData) {
                            if (SI.invoiceTradeLinks && SI.invoiceTradeLinks.length > 0) {
                                const tradeLink = SI.invoiceTradeLinks[0];
                                const trade = trades?.find((t: Trade) => t.id === tradeLink?.tradeId);
                                if (trade) {
                                    const tradeIDs = reduceUniqueTradeIDs(SI.invoiceTradeLinks);
                                    const suffix = tradeIDs.length > 1 ? ` +${tradeIDs.length - 1}` : '';
                                    newSIs.push({
                                        ...SI,
                                        linkedTrades: trade.externalReference + suffix,
                                        linkedTradesExternalReference: trade.externalReference + suffix,
                                    });
                                    continue;
                                }
                            }
                            newSIs.push({ ...SI, linkedTrades: '-', linkedTradesExternalReference: '-' });
                        }
                        setSIs(newSIs);
                        setTotal(responseTotal);
                    })
                    .finally(() => setLoading(false));
            } else {
                setSIs(responseData);
                setTotal(responseTotal);
                setLoading(false);
            }
        }
    }, [responseData, responseTotal]);

    /* general handlers --------------------------------------------------------------------- */

    const handleChangePage = (_event: unknown, page: number) => {
        const offset = query.limit ? query.limit * page : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, activeTab === 2).finally();
    };
    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [field],
            order: [order],
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, activeTab === 2).finally();
    };

    const handleFilterChange = useCallback(
        debounce(async (filterCrit: Criteria) => {
            const allCriteria = filterCrit.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        filterCrit.push({
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: '',
                        });
                    }
                }
            }
            if (filterCrit && filterCrit.length > 0) {
                setFilterCriteria(filterCrit);
            } else {
                setFilterCriteria(undefined);
                if (allCriteria.length > 0) {
                    {
                        if (financialYears[0] === 'ALL') {
                            filterCrit.push({
                                type: CriteriaType.TextCriterion,
                                field: 'financialYear',
                                text: '',
                            });
                        }
                    }
                }
            }
            setCriteria(filterCrit);
            const newQuery = {
                ...query,
                offset: 0,
            };
            setQuery(newQuery);
            await findEntities(filterCrit, newQuery, activeTab === 2);
        }, 100),
        [query],
    );

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['date'],
            order: ['desc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery, activeTab === 2).finally();
    };

    const handleSelectRow = (si: SettlementInstruction) => {
        if (selected && selected.id === si.id) {
            setSelected(undefined);
        } else {
            setSelected(si);
        }
    };

    const handleTabChange = (tab: TabValue) => {
        setActiveTab(tab);
    };
    const getLoading = () => {
        return loading || deleteSILoading || cancelLoading;
    };

    /* delete SI --------------------------------------------------------------------- */
    const [
        { response: deleteSIResponse, loading: deleteSILoading, error: deleteSIError },
        setDeleteSIRequest,
    ] = useService(undefined, Handler.Delete);
    const handleDeleteSI = () => {
        if (selected) {
            setConfirmCallback(() => () => {
                setDeleteStarted(true);
                setSelected(undefined);
                setDeleteSIRequest({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected?.id } });
            });
            setWarningMessage(`Are you sure you want to delete this SI (${selected.number})?`);
            setSelected(undefined);
        }
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (deleteSIResponse && !deleteSILoading && deleteStarted) {
            setDeleteStarted(false);
            setSuccessMessage('Successfully deleted SI');
        }
        reset(activeTab);
    }, [deleteSIResponse, deleteSILoading, reset, deleteStarted, activeTab]);
    useEffect(() => {
        if (deleteSIError && !deleteSILoading && deleteStarted) {
            setDeleteStarted(false);
            setErrorMessage('Failed to delete SI: ' + deleteSIError);
        }
        reset(activeTab);
    }, [deleteSIError, deleteSILoading, deleteStarted]);

    /* restore --------------------------------------------------------------------- */
    const [restoreSi] = useServiceSync<RestoreRequest, RestoreResponse>(Handler.Restore);

    const restoreEntity = async (_identifier?: Identifier) => {
        setLoading(true);
        try {
            const result = await restoreSi({
                identifier: _identifier,
            });
            setRestoreResponse(result);
        } catch (e) {
            setErrorMessage('Failed to restore SI: ' + e);
        } finally {
            setLoading(false);
        }
    };
    const handleRestore = () => {
        if (selected) {
            setConfirmCallback(() => () => {
                setRestoreStarted(true);
                setSelected(undefined);
                restoreEntity({ type: IdentifierType.ID_IDENTIFIER, id: selected?.id }).finally();
            });
            setWarningMessage(`Are you sure you want to restore this SI (${selected.number})?`);
            setSelected(undefined);
        }
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (restoreResponse && !loading && restoreStarted) {
            setRestoreStarted(false);
            setSuccessMessage('Successfully restored SI');
            reset(activeTab);
        }
    }, [restoreResponse, loading, reset, restoreStarted, activeTab]);

    /* delete forever --------------------------------------------------------------------- */
    const [sIDeleteForever] = useServiceSync<DeleteForeverRequest, DeleteForeverResponse>(Handler.DeleteForever);
    const deleteEntityForever = async (_identifier?: Identifier) => {
        setLoading(true);
        try {
            const result = await sIDeleteForever({
                identifier: _identifier,
            });
            setDeleteForeverResponse(result);
        } catch (e) {
            setErrorMessage('Failed to delete SI forever: ' + e);
        } finally {
            setLoading(false);
        }
    };
    const handleDeleteForever = () => {
        if (selected) {
            setConfirmCallback(() => () => {
                setDeleteForeverStarted(true);
                setSelected(undefined);
                deleteEntityForever({ type: IdentifierType.ID_IDENTIFIER, id: selected?.id }).finally();
            });
            setWarningMessage(`Are you sure you want to delete this SI (${selected.number}) forever?`);
            setSelected(undefined);
        }
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (deleteForeverResponse && !loading && deleteForeverStarted) {
            setDeleteForeverStarted(false);
            setSuccessMessage('Successfully deleted SI forever');
            reset(activeTab);
        }
    }, [deleteForeverResponse, loading, reset, deleteForeverStarted, activeTab]);

    /* ---------------------------------------------------------------------
     create new
     --------------------------------------------------------------------- */
    const [
        { response: createDraftResponse, loading: createDraftLoading, error: createDraftError },
        setCreateDraftRequest,
    ] = useService(undefined, Handler.CreateDraft);

    const handleCreateNew = (createDraftInfo: { currency: string; date: number; importExport: ImportExport }) => {
        setSelected(undefined);
        setImportExport(importExport);
        setShowNewDialog(false);
        setCreateDraftRequest({
            ...createDraftInfo,
            partyCode: appContext.party?.partyCode,
            processingOrgPartyCode: appContext.parentPartyCode,
        });
    };
    // this effect reacts to a successful service response,
    useEffect(() => {
        if (createDraftResponse && !createDraftLoading) {
            setSuccessMessage('Successfully created draft SI');
            setSelected(createDraftResponse.settlementInstruction);
            setEditOpen(true);
        }
    }, [createDraftResponse, createDraftLoading]);
    useEffect(() => {
        if (createDraftError && !createDraftLoading) {
            setErrorMessage('Failed to create draft SI: ' + createDraftError);
        }
    }, [createDraftError, createDraftLoading]);

    /* ---------------------------------------------------------------------
     cancel
     --------------------------------------------------------------------- */
    const [{ response: cancelResponse, loading: cancelLoading, error: cancelError }, setCancelRequest] = useService(
        undefined,
        Handler.Cancel,
    );

    const handleCancel = () => {
        if (selected) {
            setConfirmCallback(() => () => {
                setCancelStarted(true);
                setCancelRequest({
                    settlementInstructionIdentifier: { type: IdentifierType.ID_IDENTIFIER, id: selected?.id },
                });
            });
            setConfirmationMessage('Are you sure you want to unlink this Settlement Instruction?');
            setSelected(undefined);
        }
    };
    useEffect(() => {
        if (cancelResponse && !cancelLoading && cancelStarted) {
            setCancelStarted(false);
            setSuccessMessage('Successfully cancelled SI');
            setSelected(undefined);
            reset(activeTab);
        }
    }, [cancelResponse, cancelLoading, cancelStarted, activeTab, reset]);
    useEffect(() => {
        if (cancelError && !cancelLoading && cancelStarted) {
            setCancelStarted(false);
            setErrorMessage('Failed to cancel SI: ' + cancelError);
        }
    }, [cancelError, cancelLoading, cancelStarted]);

    /* ---------------------------------------------------------------------
     download list
     --------------------------------------------------------------------- */
    const [
        { response: downloadListResponse, loading: downloadListLoading, error: downloadListError },
        setDownloadListRequest,
        resetDownloadListResponse,
    ] = useService(undefined, Downloader.DownloadList);
    const handleDownloadList = () => {
        setDownloadListRequest({
            criteria,
            query: query,
            deleted: activeTab === TabValue.Deleted,
        });
    };
    useEffect(() => {
        if (downloadListResponse && !downloadListLoading) {
            resetDownloadListResponse();
            try {
                const binData = atob(downloadListResponse.data || '');
                const bytes = new Array(binData.length);
                for (let i = 0; i < binData.length; i++) {
                    bytes[i] = binData.charCodeAt(i);
                }
                const blob = new Blob([new Uint8Array(bytes)], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
                });
                let fileName = '';
                const dateStr = format(new Date(), 'yyyy-MM-dd');
                switch (activeTab) {
                    case TabValue.Completed:
                    case TabValue.Unlinked:
                        if (filterCriteria) {
                            fileName = `Filtered SIs ${TabValue[activeTab]} ${dateStr}.xlsx`;
                        } else {
                            fileName = `All SIs ${TabValue[activeTab]} ${dateStr}.xlsx`;
                        }
                        break;
                    case TabValue.Deleted:
                        if (filterCriteria) {
                            fileName = `Filtered Deleted SIs ${dateStr}.xlsx`;
                        } else {
                            fileName = `Deleted SIs ${dateStr}.xlsx`;
                        }
                        break;
                    default:
                    // ?
                }
                saveAs(blob, fileName);
            } catch (e) {
                console.error('error downloading SI list', e);
                setErrorMessage(e);
            }
        }
    }, [downloadListResponse, downloadListLoading, activeTab, filterCriteria, resetDownloadListResponse]);
    useEffect(() => {
        if (downloadListError && !downloadListLoading) {
            setErrorMessage('Failed to download SI list: ' + downloadListError);
        }
    }, [downloadListError, downloadListLoading]);

    // handleUpdate handles updates from editinging an SI, replacing the SI in the list with the updated one
    const handleUpdate = (SI: SettlementInstruction) => {
        const newSIs = sIs ? [...sIs] : ([] as SettlementInstruction[]);
        const idx = newSIs.findIndex((elem: SettlementInstruction) => elem.id === SI.id);
        newSIs[idx] = SI;
        setSIs(newSIs);
    };

    const handleSubmit = (SI: SettlementInstruction) => {
        const newSIs = sIs ? [...sIs] : ([] as SettlementInstruction[]);
        const idx = newSIs.findIndex((elem: SettlementInstruction) => elem.id === SI.id);
        newSIs[idx] = SI;
        setSIs(newSIs);
    };

    const partyCurrencies =
        appContext.party?.currencyPairs?.map((id: string) => {
            const ccyPair = appContext.currencyPairs?.find((c: CurrencyPair) => c.name === id);
            const foreignCcyID = ccyPair?.baseCurrency;
            return appContext.currencies?.find((c: Currency) => c.isoCode === foreignCcyID);
        }) || [];

    const renderDialogs = () => {
        return (
            <>
                {showSISummaryDialog && selected && (
                    <Dialog
                        fullWidth
                        maxWidth={'lg'}
                        onClose={() => setShowSISummaryDialog(false)}
                        open={showSISummaryDialog}
                    >
                        <Summary
                            SI={selected}
                            readOnly
                            onClose={() => setShowSISummaryDialog(false)}
                            showRates={true}
                        />
                    </Dialog>
                )}
                {showNewDialog && (
                    <AddNewSI
                        closeDialog={() => setShowNewDialog(false)}
                        currencies={partyCurrencies || []}
                        importExport={importExport}
                        onSubmit={(createDraftInfo: { currency: string; date: number }) =>
                            handleCreateNew({ ...createDraftInfo, importExport })
                        }
                        show={showNewDialog}
                        currency={undefined}
                    />
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={() => {
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                        setConfirmationMessage(undefined);
                    }}
                    onConfirm={() => {
                        confirmCallback();
                        setSuccessMessage(undefined);
                        setWarningMessage(undefined);
                        setErrorMessage(undefined);
                        setConfirmCallback(() => undefined);
                        setConfirmationMessage(undefined);
                    }}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                    confirmationMessage={confirmationMessage}
                    headerConfimationMessage={'Unlink Settlement Instruction?'}
                />
                {editOpen && (
                    <Edit
                        initialSettlementInstruction={selected || ({} as SettlementInstruction)}
                        onClose={() => {
                            setEditOpen(false);
                            reset(activeTab);
                        }}
                        onSubmit={handleSubmit}
                        onUpdate={handleUpdate}
                        open={editOpen}
                    />
                )}
                <ActionsMenu
                    id={'SettlementInstruction/Workstation/more-options'}
                    anchorElement={moreOptionsAnchorEl}
                    onClose={() => setMoreActionsAnchorEl(undefined)}
                    title={'More Options'}
                    items={[
                        activeTab !== TabValue.Deleted
                            ? {
                                  id: 'view-deleted',
                                  text: 'View Deleted SIs',
                                  onClick: () => setActiveTab(TabValue.Deleted),
                              }
                            : undefined,
                        {
                            id: 'column-configuration',
                            text: 'Column Configuration',
                            onClick: () => setColConfigOpen(true),
                        },
                    ]}
                />
                <CategoryMenu
                    anchorEl={newAnchorEl}
                    exportItems={[{ text: 'EXPORT SETTLEMENT INSTRUCTION' }]}
                    importItems={[{ text: 'IMPORT SETTLEMENT INSTRUCTION' }]}
                    onClickItem={(_: string, importExport: ImportExport) => {
                        setImportExport(importExport);
                        setSelected(undefined);
                        setShowNewDialog(true);
                        setNewAnchorEl(undefined);
                    }}
                    onClose={() => setNewAnchorEl(undefined)}
                    title={'Create New'}
                />
            </>
        );
    };

    let itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: [
                {
                    id: 'SettlementInstruction/Workstation/completed',
                    label: 'SIs Completed',
                    value: 'SIs Completed',
                },
                {
                    id: 'SettlementInstruction/Workstation/unlinked',
                    label: 'SIs Unlinked',
                    value: 'SIs Unlinked',
                },
            ],
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                handleTabChange(value as TabValue);
            },
            value: activeTab,
            id: 'si-tabs',
        },
    ];
    if (activeTab === TabValue.Deleted) {
        itemsLeft = [
            {
                id: 'Reports/Subsidiaries/title',
                type: ITEM_VARIATION.TITLE,
                text: 'Deleted SIs',
            },
        ];
    }

    const initialCriteria = getBaseCriteria();

    const columns = [
        {
            title: 'Number',
            field: 'number',
            filter: { type: CriteriaType.TextCriterion },
            render: (rowData: SettlementInstruction) => rowData.number,
        },
        {
            title: 'Value Date',
            field: 'date',
            filter: { type: CriteriaType.DateCriterion },
            render: (rowData: SettlementInstruction) => processUnixDateForViewing(rowData.date),
        },
        {
            title: 'Currency',
            field: 'currency',
            filter: {
                type: CriteriaType.TextCriterion,
                options: appContext.assignedCurrencyPairs?.map((ccyPair: CurrencyPair) => {
                    const foreignCcyISOCode = ccyPair?.baseCurrency;
                    return { name: ccyPair?.name, value: foreignCcyISOCode };
                }),
                displayAccessor: 'name',
                valueAccessor: 'value',
            },
            render: (rowData: SettlementInstruction) => {
                const foreignCcy = appContext.currencies?.find((c: Currency) => c.isoCode === rowData.currency);
                if (appContext.localCurrency) {
                    return `${foreignCcy?.isoCode}/${appContext.localCurrency.isoCode}`;
                }
                return `${foreignCcy?.isoCode}/<UNKNOWN>`;
            },
        },
        {
            title: 'Status',
            field: 'status',
            filter: {
                type: CriteriaType.TextCriterion,
                options: statusOptions,
                displayAccessor: 'v',
                valueAccessor: 'v',
                disabled: true,
            },
            render: (rowData: SettlementInstruction) => rowData.status,
        },
        {
            title: 'Client',
            field: 'partyCode',
            filter: { type: CriteriaType.TextCriterion },
            render: (rowData: SettlementInstruction) => rowData.partyCode,
        },
        {
            title: 'Financial Year',
            field: 'financialYear',
            filter: {
                options: financialYears.map((f) => ({ name: f })),
                displayAccessor: 'name',
                valueAccessor: 'name',
                type: CriteriaType.TextCriterion,
            },
            render: (rowData: SettlementInstruction) => rowData.financialYear,
        },
        {
            title: 'IM/EX',
            field: 'importExport',
            filter: {
                type: CriteriaType.TextCriterion,
                options: [{ v: 'Import' }, { v: 'Export' }],
                displayAccessor: 'v',
                valueAccessor: 'v',
            },
            // eslint-disable-next-line react/display-name
            render: (rowData: SettlementInstruction) => <StyledImExValue SI={rowData} />,
        },
        {
            title: 'FX Amount',
            field: 'fxAmount.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) => displayCurrencyAmount(SI.fxAmount, SI.currency),
        },
        {
            title: `${appContext.localCurrency?.isoCode} Amount`,
            field: 'localCurrencyAmount.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) =>
                displayCurrencyAmount(SI.localCurrencyAmount, appContext.localCurrency?.isoCode),
        },
        {
            title: 'Avg Deal',
            field: 'dealRate.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) => displayRate(SI.dealRate),
        },
        {
            title: 'Avg Cost',
            field: 'avgCostingRate.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) => displayRate(SI.avgCostingRate),
        },
        {
            title: 'Avg Capture',
            field: 'avgCostingRate.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) => displayRate(SI.avgCaptureRate),
        },
        {
            title: 'Avg Effective',
            field: 'avgEffectiveRate.value',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) => displayRate(SI.avgEffectiveRate),
        },
        {
            title: 'Linked Trades',
            disableSort: true,
            render: (rowData: ModifiedSI) => rowData.linkedTrades || '-',
        },
        {
            title: 'Linked Trades Ext Ref',
            disableSort: true,
            render: (rowData: ModifiedSI) => rowData.linkedTradesExternalReference || '-',
        },
        {
            title: 'FX Source',
            field: 'fxSource',
            filter: {
                type: CriteriaType.TextCriterion,
                options: [{ v: 'Stellcap trade' }, { v: 'Client trade' }],
                displayAccessor: 'v',
                valueAccessor: 'v',
            },
            render: (rowData: SettlementInstruction) => rowData.fxSource,
        },
        {
            title: 'P/L Costing',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) =>
                displayCurrencyAmount(SI.pnl?.withCosting, appContext.localCurrency?.isoCode),
        },
        {
            title: 'P/L Capture',
            filter: { type: CriteriaType.NumberCriterion },
            align: 'right',
            render: (SI: SettlementInstruction) =>
                displayCurrencyAmount(SI.pnl?.withCapture, appContext.localCurrency?.isoCode),
        },
    ];

    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            {renderDialogs()}
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selected && STATES.SELECTED_ROW,
                    itemsLeft,
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/return',
                            icon: ACTION_BUTTON_TYPE.RETURN,
                            helpText: 'Return',
                            onClick: () => {
                                setActiveTab(TabValue.Unlinked);
                            },
                            hide: ![TabValue.Deleted].includes(activeTab),
                        },
                        {
                            id: 'SettlementInstruction/Workstation/restore',
                            type: ITEM_VARIATION.BUTTON,
                            onClick: handleRestore,
                            buttonVariant: VARIANT.OUTLINED,
                            text: 'Restore',
                            hide: !(selected && [TabValue.Deleted].includes(activeTab)),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/view-details',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View Details',
                            onClick: () => {
                                setShowSISummaryDialog(true);
                            },
                            hide: !(
                                selected?.status &&
                                [Status.Draft, Status.Submitted, Status.Complete].includes(selected.status)
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Forever',
                            onClick: handleDeleteForever,
                            hide: !(selected && [TabValue.Deleted].includes(activeTab)),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: handleDeleteSI,
                            hide: !(
                                selected?.status &&
                                [TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
                                [Status.Draft].includes(selected.status)
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: () => setEditOpen(true),
                            hide: !(
                                selected?.status &&
                                [TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
                                [Status.Draft].includes(selected.status)
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/cancel',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel',
                            onClick: handleCancel,
                            hide: !(
                                selected?.status &&
                                [TabValue.Completed, TabValue.Unlinked].includes(activeTab) &&
                                [Status.Submitted, Status.Complete].includes(selected.status)
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/add',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'Add New SI',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setNewAnchorEl(event?.currentTarget ? event.currentTarget : undefined),
                            hide: ![TabValue.Completed, TabValue.Unlinked].includes(activeTab),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                if (showFilter) {
                                    setFilterCriteria(undefined);
                                }
                                setShowFilter(!showFilter);
                                reset(activeTab);
                                setSelected(undefined);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/download-list',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download List',
                            onClick: handleDownloadList,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/Workstation/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        return (
                            <div>
                                <Table
                                    colConfigCloseFromCard={() => setColConfigOpen(false)}
                                    colConfigOpenFromCard={colConfigOpen}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    columns={columns as columnT<SettlementInstruction>[]}
                                    count={total}
                                    data={sIs ? sIs : ([] as SettlementInstruction[])}
                                    defaultColConfig={cols}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    loading={getLoading()}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    onRowCheck={handleSelectRow}
                                    onSelectAll={() => setSelected(undefined)}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    rowClickAction={handleSelectRow}
                                    rowDoubleClickAction={(rowData: SettlementInstruction) => {
                                        setSelected(rowData);
                                        setShowSISummaryDialog(true);
                                    }}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                    selected={selected ? [selected] : []}
                                    showCheckboxes
                                    showFilterRow={showFilter}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    tableID={'SI-station'}
                                    title={''}
                                    initialCriteria={{ ...initialCriteria }}
                                />
                            </div>
                        );
                    })()}
                </div>
            </StandardCard>
        </div>
    );
};
