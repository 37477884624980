/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import { Downloader as InvoiceDownloader, DownloadRequest, DownloadResponse } from 'popcorn-js/invoice/downloader';
import { Handler as InvoiceHandler } from 'popcorn-js/invoice/handler';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import { Direction, financialYears, FindRequest, FindResponse } from 'popcorn-js/';
import { Counterparty } from 'popcorn-js/counterparty';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import InvoiceDetailDialogV2 from 'components/invoice/InvoiceDetailDialogV2';
import { History } from 'components/invoice/History';
import { displayAmount, displayAmountWithSign, processUnixDateForViewing, processUnixDateTimeForViewing } from 'utils';
import { saveAs } from 'file-saver';
import { Invoice, InvoiceStatus, InvoiceType } from 'popcorn-js/invoice';
import Table from 'components/Table/Table';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { CategoryMenu } from 'components/CategoryMenu/CategoryMenu';
import { AppContext, AppContextT } from 'context';
import InvoiceForm from 'components/invoice/InvoiceForm';
import { AuditEntry } from 'popcorn-js/audit';
import { useStyletron } from 'styletron-react';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import InvoiceUploadContainer from 'components/invoice/upload/InvoiceUploadContainer';
import { downloadTemplate } from 'components/invoice/upload';
import { Category } from 'components/upload';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, Item, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import Big from 'big.js';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import { SystemDateTimeFormat } from 'constants/formats';
import { Client, IsClient, ProcessingOrg } from 'popcorn-js/party';
import SelectedRowsButton from 'components/SelectedRowsButton/selectedRowsButton';

export enum ActiveState {
    viewing = 'ACTIVE_STATE_VIEWING',
    editing = 'ACTIVE_STATE_EDITING',
    creating = 'ACTIVE_STATE_CREATING',
    uploading = 'ACTIVE_STATE_UPLOADING',
    viewDelete = 'ACTIVE_STATE_VIEWING_DELETE',
}

const invoiceStatuses = Object.values(InvoiceStatus).map((value) => ({ value }));
const invoiceImportExport = ['IMPORT', 'EXPORT'].map((value) => ({ value }));
const invoiceTypesSales = [
    {
        value: InvoiceType.SalesInvoiceType,
        label: InvoiceType.SalesInvoiceType,
    },
    {
        value: InvoiceType.SalesDebitNoteType,
        label: InvoiceType.SalesDebitNoteType,
    },
    {
        value: InvoiceType.SalesCreditNoteType,
        label: InvoiceType.SalesCreditNoteType,
    },
    {
        value: InvoiceType.SalesRefundType,
        label: InvoiceType.SalesRefundType,
    },
    {
        value: InvoiceType.SalesAdjustmentType,
        label: InvoiceType.SalesAdjustmentType,
    },
    {
        value: InvoiceType.SalesPaymentType,
        label: InvoiceType.SalesPaymentType,
    },
    {
        value: InvoiceType.SalesReversalType,
        label: InvoiceType.SalesReversalType,
    },
];
const invoiceTypesPurchase = [
    {
        value: InvoiceType.PurchaseInvoiceType,
        label: InvoiceType.PurchaseInvoiceType,
    },
    {
        value: InvoiceType.PurchaseDebitNoteType,
        label: InvoiceType.PurchaseDebitNoteType,
    },
    {
        value: InvoiceType.PurchaseCreditNoteType,
        label: InvoiceType.PurchaseCreditNoteType,
    },
    {
        value: InvoiceType.PurchaseRefundType,
        label: InvoiceType.PurchaseRefundType,
    },
    {
        value: InvoiceType.PurchaseAdjustmentType,
        label: InvoiceType.PurchaseAdjustmentType,
    },
    {
        value: InvoiceType.PurchasePaymentType,
        label: InvoiceType.PurchasePaymentType,
    },
    {
        value: InvoiceType.PurchaseReversalType,
        label: InvoiceType.PurchaseReversalType,
    },
];
const invoiceDirections = [
    {
        value: Direction.PAYABLE,
        label: Direction.PAYABLE,
    },
    {
        value: Direction.RECEIVABLE,
        label: Direction.RECEIVABLE,
    },
];

const safeRender = (accessor: string, formatter = (value: unknown) => value) => (rowData: Invoice) => {
    try {
        return formatter(rowData[accessor as keyof Invoice]);
    } catch (e) {
        return '-';
    }
};

const initialPageSize = 12;
const rowsPerPageOptions = [5, 10, 12, 17, 20, 25, 30];
const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
};

export const InvoiceStation = (): ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const party = (appContext.party || {}) as Client | ProcessingOrg;
    const initialTab = (party: Client | ProcessingOrg) => {
        let tab = 0;
        if (IsClient(party)) {
            if (party.export && !party.import) {
                tab = 1;
            }
        }
        return tab;
    };
    const [tabValue, setTabValue] = React.useState(initialTab(party));

    const initialiseCriteria = (party: Client | ProcessingOrg) => {
        const criteria: Criteria = [{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }];
        if (IsClient(party)) {
            if (tabValue === 1) {
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesInvoiceType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesCreditNoteType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesDebitNoteType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesAdjustmentType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesPaymentType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesRefundType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.SalesReversalType,
                });
            } else {
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseInvoiceType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseCreditNoteType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseDebitNoteType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseAdjustmentType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchasePaymentType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseRefundType,
                });
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: InvoiceType.PurchaseReversalType,
                });
            }
        } else {
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseInvoiceType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseCreditNoteType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseDebitNoteType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseAdjustmentType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchasePaymentType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseRefundType,
            });
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: InvoiceType.PurchaseReversalType,
            });
        }
        return criteria;
    };
    const [loading, setLoading] = useState<boolean>(false);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [selected, setSelected] = useState<Invoice[] | undefined>();
    const [showInvoiceHistory, setShowInvoiceHistory] = useState<boolean>(false);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [invoices, setInvoices] = useState<Invoice[] | undefined>();
    const [totalInvoices, setTotalInvoices] = useState<number>(0);
    const [createNewAnchorEl, setCreateNewAnchorEl] = useState<undefined | Element | ((element: Element) => Element)>(
        undefined,
    );
    const [uploadAnchorEl, setUploadAnchorEl] = useState<undefined | Element | ((element: Element) => Element)>(
        undefined,
    );
    const [invoiceCategory, setInvoiceCategory] = useState<Category | undefined>();
    const [invoiceType, setInvoiceType] = useState<InvoiceType | undefined>(undefined);
    const initialQuery: Query = {
        sortBy: ['dueDate', 'externalReference'],
        order: ['asc', 'asc'],
        limit: initialPageSize,
        offset: 0,
    };
    const [query, setQuery] = useState<Query>(initialQuery);
    const [counterparties, setCounterparties] = useState<Counterparty[]>([]);
    const [criteria, setCriteria] = useState<Criteria>(initialiseCriteria(party));
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [showColumnConfiguration, setShowColumnConfiguration] = useState<boolean>(false);
    // service providers
    const [invoiceFind] = useServiceSync<FindRequest, FindResponse<Invoice>>(InvoiceRecordkeeper.find);
    const [counterpartyFind] = useServiceSync<FindRequest, FindResponse<Counterparty>>(CounterpartyRecordkeeper.find);
    const [downloadInvoices] = useServiceSync<DownloadRequest, DownloadResponse>(InvoiceDownloader.downloadInvoices);

    useEffect(() => {
        findInvoices().finally();
    }, []);

    useEffect(() => {
        setSelected(undefined);
    }, [activeState]);
    const findInvoices = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await invoiceFind({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            const _counterparties = await findInvoiceCounterparties(result.records);
            setInvoices(
                result.records.map((i: Invoice) => ({
                    ...i,
                    counterparty: _counterparties.find((c: Counterparty) => c.id === i.counterpartyId)?.name,
                })),
            );
            setCounterparties(_counterparties);
            setTotalInvoices(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const findInvoiceCounterparties = async (_invoices: Invoice[]): Promise<Counterparty[]> => {
        setLoading(true);
        const counterpartyFindCrit: Criteria = _invoices
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'id', text: b.counterpartyId }));

        const counterpartyFindResponse =
            counterpartyFindCrit.length > 0
                ? await counterpartyFind({ criteria: counterpartyFindCrit })
                : { records: [] };

        setLoading(false);
        return counterpartyFindResponse.records;
    };

    const exportInvoices = async (_criteria: Criteria) => {
        setLoading(true);
        try {
            const invoiceExportResult = await downloadInvoices({ criteria: _criteria });
            // convert base64 to byte array
            const binData = atob(invoiceExportResult.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'invoice_export.xlsx');
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.message || error);
        } finally {
            setLoading(false);
        }
    };
    const showDeleteForeverConfirmation = (rowInfo: Invoice[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        let invNumber = '';
        rowInfo.forEach((inv: Invoice) => {
            if (invNumber.length == 0) {
                invNumber += inv.number;
            }
        });
        if (rowInfo.length === 1) {
            setWarningMessage(`You are about to delete invoice ${invNumber} forever.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await InvoiceHandler.DeleteForever({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
                    });
                    setSuccessMessage(`Invoice ${invNumber} Delete Forever`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    await findInvoices(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const ids = [] as string[];
            for (const inv of rowInfo) {
                if (inv.id) {
                    ids.push(inv.id);
                }
            }
            setWarningMessage(`You are about to delete ${ids.length} invoices, forever.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                const deleteBatch: any[] = rowInfo.map((inv: any) => {
                    return {
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: inv.id },
                        invoice: inv,
                    };
                });
                try {
                    await InvoiceHandler.DeleteBatchForever({
                        deleteBatch: deleteBatch,
                    });
                    setSuccessMessage(`${ids.length} Invoices Deleted Forever`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    await findInvoices(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };
    const showDeleteConfirmation = (rowInfo: Invoice[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        let rowNumber = '';
        rowInfo.forEach((inv: Invoice) => {
            if (rowNumber.length == 0) {
                rowNumber += inv.number;
            }
        });
        if (rowInfo.length == 1) {
            setWarningMessage(`You are about to delete invoice ${rowNumber}.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await InvoiceHandler.Delete({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
                    });
                    setSuccessMessage(`Invoice ${rowNumber} Deleted`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    findInvoices();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const externalReferences = [] as string[];
            for (const inv of rowInfo) {
                if (inv.externalReference) {
                    externalReferences.push(inv.externalReference);
                }
            }
            setWarningMessage(
                `You are about to delete ${externalReferences.length} invoices.\n Do you want to continue?`,
            );
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await InvoiceHandler.DeleteBatch({
                        externalReferences: externalReferences,
                    });
                    setSuccessMessage(`${externalReferences.length} Invoices Deleted`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    findInvoices();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };

    const allowBatchRestore = (rowInfo: Invoice[]) => {
        let rowNumber = '';
        rowInfo.forEach((inv: Invoice) => {
            if (rowNumber.length == 0) {
                rowNumber += inv.number;
            }
        });
        if (rowInfo.length === 1) {
            setLoading(true);
            setSelected(undefined);
            InvoiceRecordkeeper.restore({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
            })
                .then(() => {
                    setSuccessMessage(`Invoice ${rowNumber} Restored`);
                    findInvoices(criteria, query, true);
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        } else {
            setLoading(true);
            setSelected(undefined);
            const restoreBatch: any[] = rowInfo.map((inv: any) => {
                return {
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: inv.id },
                    invoice: inv,
                };
            });
            InvoiceHandler.RestoreBatch({
                restoreBatch: restoreBatch,
            })
                .then(() => {
                    setSuccessMessage(`${restoreBatch.length} Invoices Restored`);
                    findInvoices(criteria, query, true);
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        }
    };

    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setErrorMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const generateCounterpartyOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await counterpartyFind({
                criteria,
                query: { sortBy: ['dueDate', 'externalReference'], order: ['asc', 'asc'], limit: 20, offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const renderDialogs = () => {
        return (
            <span>
                {createNewAnchorEl && (
                    <CategoryMenu
                        title={'Create New'}
                        anchorEl={createNewAnchorEl}
                        onClose={() => setCreateNewAnchorEl(undefined)}
                        onClickItem={(value: string) => {
                            setCreateNewAnchorEl(undefined);
                            setInvoiceType(value as InvoiceType);
                            setActiveState(ActiveState.creating);
                        }}
                        importItems={[{ text: 'Purchase Document', value: InvoiceType.PurchaseInvoiceType }]}
                        exportItems={[{ text: 'Sales Document', value: InvoiceType.SalesInvoiceType }]}
                    />
                )}
                {uploadAnchorEl && (
                    <CategoryMenu
                        title={'Upload'}
                        anchorEl={uploadAnchorEl}
                        onClose={() => setUploadAnchorEl(undefined)}
                        onClickItem={(value: string) => {
                            setUploadAnchorEl(undefined);
                            setInvoiceCategory(value as Category);
                            setActiveState(ActiveState.uploading);
                        }}
                        importItems={[
                            { text: 'Purchase Invoices & Credit Notes', value: Category.Purchase },
                            { text: 'Purchase Debit Notes & Credit Notes', value: Category.PurchaseNotes },
                        ]}
                        exportItems={[
                            { text: 'Sales Invoices & Credit Notes', value: Category.Sales },
                            { text: 'Sales Debit Notes & Credit Notes', value: Category.SalesNotes },
                        ]}
                    />
                )}
                {showDetail && selected && (
                    <InvoiceDetailDialogV2
                        counterparties={counterparties}
                        invoice={selected[0]}
                        onClose={() => setShowDetail(false)}
                        show={showDetail}
                        updateInvoiceSuccess={() => findInvoices()}
                    />
                )}
                {activeState === ActiveState.creating && (
                    <InvoiceForm
                        createCounterpartyError={(e) => setErrorMessage(`Failed to create counterparty ${e}`)}
                        createCounterpartySuccess={() => setSuccessMessage('Counterparty Created')}
                        createInvoiceError={(e) => setErrorMessage(`Failed to create invoice ${e}`)}
                        createInvoiceSuccess={() => {
                            setSuccessMessage('Invoice Created');
                            findInvoices();
                        }}
                        hideInvoiceForm={() => setActiveState(ActiveState.viewing)}
                        showForm={activeState === ActiveState.creating}
                        type={invoiceType || InvoiceType.PurchaseInvoiceType}
                        validateInvoiceError={(e) => setErrorMessage(`Failed to validate invoice ${e}`)}
                    />
                )}
                {activeState === ActiveState.uploading && (
                    <InvoiceUploadContainer
                        category={invoiceCategory}
                        enableDownloadTemplate
                        onAwayClick={() => setActiveState(ActiveState.viewing)}
                        showImportDialog={() => setActiveState(ActiveState.uploading)}
                        uploadSuccess={findInvoices}
                    />
                )}
                {showInvoiceHistory && selected && (
                    <History
                        invoice={selected[0]}
                        onHide={() => setShowInvoiceHistory(false)}
                        open={showInvoiceHistory}
                    />
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </span>
        );
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery, activeState === ActiveState.viewDelete);
    };
    const handleFilterChange = (newCrit: Criteria) => {
        const newQuery = {
            ...query,
            offset: 0,
        };
        const allCriteria = newCrit.filter(
            (f: Criterion) =>
                (f.field === 'financialYear' && f.type === CriteriaType.TextCriterion && f.text && f.text === 'ALL') ||
                (f.field === 'financialYear' && f.type === CriteriaType.ExactCriterion && f.text && f.text === 'ALL'),
        );
        const tabCrit = newCrit.filter((f: Criterion) => f.field === 'type');
        const finYearCrit = newCrit.filter((f: Criterion) => f.field === 'financialYear');
        if (finYearCrit.length === 0) {
            newCrit.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' });
        } else if (allCriteria.length > 0) {
            {
                if (financialYears[0] === 'ALL') {
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: '',
                    });
                }
            }
        }
        if (tabCrit.length === 0) {
            switch (tabValue) {
                case 0:
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseInvoiceType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseCreditNoteType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseDebitNoteType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseAdjustmentType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchasePaymentType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseRefundType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseReversalType,
                    });
                    break;
                case 1: {
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesInvoiceType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesCreditNoteType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesDebitNoteType,
                    });
                    criteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesAdjustmentType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesPaymentType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesRefundType,
                    });
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesReversalType,
                    });
                }
            }
        }
        setQuery(newQuery);
        setCriteria(newCrit);
        findInvoices(newCrit, newQuery, activeState === ActiveState.viewDelete);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['dueDate', 'externalReference'],
            order: ['asc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery, activeState === ActiveState.viewDelete);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery, activeState === ActiveState.viewDelete);
    };
    const displayRate = (value: number | undefined): string => {
        if (value) {
            return value.toFixed(4);
        }
        return '-';
    };
    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
        setSelected([]);
        switch (newValue) {
            case 0:
                handleFilterChange([
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseInvoiceType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseCreditNoteType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseDebitNoteType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseAdjustmentType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchasePaymentType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseRefundType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.PurchaseReversalType,
                    },
                    { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
                ]);
                break;
            case 1: {
                handleFilterChange([
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesInvoiceType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesCreditNoteType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesDebitNoteType,
                    },

                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesAdjustmentType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesPaymentType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesRefundType,
                    },
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: InvoiceType.SalesReversalType,
                    },
                    { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
                ]);
            }
        }
    };
    const handleSelectRow = (rowData: Invoice) => {
        const index = (selected || []).findIndex((selectedInv: Invoice) => selectedInv.id === rowData.id);
        const _selected = [...(selected || [])];
        if (index === -1) {
            _selected.push(rowData);
        } else {
            _selected.splice(index, 1);
        }
        setSelected && setSelected(_selected);
    };
    const handleSelectAll = () => {
        const newSelected = [...(selected || [])];
        if (newSelected.length !== 0) {
            setSelected && setSelected([]);
        } else if (invoices) {
            invoices.forEach((inv: Invoice) => {
                newSelected.push(inv);
            });
            setSelected && setSelected(newSelected);
        }
    };
    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: [
                {
                    id: 'InvoiceStation/purchase',
                    label: 'Purchase',
                    value: 'Purchase',
                },
                {
                    id: 'InvoiceStation/sales',
                    label: 'Sales',
                    value: 'Sales',
                },
            ],
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                handleTabChange(value);
            },
            value: tabValue,
            id: 'fec-tabs',
        },
        {
            type: ITEM_VARIATION.ELEMENT,
            id: 'InvoiceStation/Invoices/selectedRowButton',
            element: (
                <SelectedRowsButton
                    disabled={false}
                    onClick={() => setSelected([])}
                    count={selected?.length ? selected?.length : 0}
                />
            ),
            hide: selected?.length === 0 || !selected,
        },
    ];

    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            {renderDialogs()}
            <ActionsMenu
                id={'InvoiceStation/more-options'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-invoices',
                        text: 'Upload Invoices / Notes',
                        onClick: (event: SyntheticEvent<HTMLLIElement> | undefined) =>
                            setUploadAnchorEl(event?.currentTarget),
                    },
                    {
                        id: 'download-invoices',
                        text: 'Download Invoices',
                        onClick: () => {
                            exportInvoices(criteria).finally();
                        },
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />

            <StandardCard
                cardHeaderProps={{
                    itemsLeft,
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/history',
                            icon: ACTION_BUTTON_TYPE.HISTORY,
                            helpText: 'History',
                            onClick: () => {
                                setSelected(selected || ({} as Invoice[]));
                                setShowInvoiceHistory(true);
                            },
                            hide: selected?.length !== 1 || !selected || activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/return',
                            icon: ACTION_BUTTON_TYPE.RETURN,
                            helpText: 'Return',
                            onClick: () => {
                                setActiveState(ActiveState.viewing);
                                setShowFilter(false);
                                setQuery(initialQuery);
                                findInvoices(initialiseCriteria(party), initialQuery, false);
                            },
                            hide: activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(selected || ({} as Invoice[])),
                            hide: !selected || selected?.length === 0 || activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Forever',
                            onClick: () => showDeleteForeverConfirmation(selected || ({} as Invoice[])),
                            hide: !selected || selected?.length === 0 || activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/restore',
                            icon: ACTION_BUTTON_TYPE.RESTORE,
                            helpText: 'Restore',
                            onClick: () => allowBatchRestore(selected || ({} as Invoice[])),
                            hide: !selected || selected?.length === 0 || activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/view-details',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View Details',
                            onClick: () => {
                                setSelected(selected || ({} as Invoice[]));
                                setShowDetail(true);
                            },
                            hide: selected?.length !== 1 || !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                setShowFilter(!showFilter);
                                handleFilterChange([]);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'New',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setCreateNewAnchorEl(event?.currentTarget),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setShowColumnConfiguration(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/view-deleted',
                            icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                            helpText: ' View Deleted Items',
                            onClick: () => {
                                setActiveState(ActiveState.viewDelete);
                                setQuery(initialQuery);
                                findInvoices(criteria, initialQuery, true);
                            },
                            hide: activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceStation/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) =>
                                setMoreActionsAnchorEl(event?.currentTarget ? event.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <Table
                    colConfigCloseFromCard={() => setShowColumnConfiguration(false)}
                    colConfigOpenFromCard={showColumnConfiguration}
                    columns={[
                        {
                            title: 'Type',
                            field: 'type',
                            filter: {
                                options: tabValue === 0 ? invoiceTypesPurchase : invoiceTypesSales,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Number',
                            field: 'number',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        { title: 'Party', field: 'partyCode' },
                        {
                            title: 'Financial Year',
                            field: 'financialYear',
                            filter: {
                                options: financialYears.map((f) => ({ name: f })),
                                displayAccessor: 'name',
                                valueAccessor: 'name',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Counterparty',
                            disableSort: true,
                            filter: {
                                asyncOptionsFetcher: generateCounterpartyOptions,
                                displayAccessor: 'name',
                                valueAccessor: 'id',
                                type: CriteriaType.TextCriterion,
                            },
                            render: (rowData: Invoice) => rowData.counterparty,
                            field: 'counterpartyId',
                        },
                        {
                            title: 'Original Amount Due',
                            field: 'originalAmountDue.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Invoice) => displayAmountWithSign(rowData, rowData.originalAmountDue),
                        },
                        {
                            title: 'Balance Due',
                            field: 'balanceDue.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('balanceDue', (balanceDue) => displayAmount(balanceDue as number)),
                        },
                        {
                            title: 'Currency',
                            field: 'currency',
                            disableSort: true,
                            filter: {
                                options: appContext.currencies,
                                displayAccessor: 'isoCode',
                                valueAccessor: 'isoCode',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Cost Currency',
                            field: 'costCurrency',
                            disableSort: true,
                            filter: {
                                options: appContext.currencies,
                                displayAccessor: 'isoCode',
                                valueAccessor: 'isoCode',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Costing Rate',
                            field: 'costingRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('costingRate', (costingRate: unknown) =>
                                (costingRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Due Date',
                            field: 'dueDate',
                            render: safeRender('dueDate', processUnixDateForViewing),
                            filter: { type: CriteriaType.DateCriterion },
                        },
                        {
                            title: 'Shipping Date',
                            field: 'shippingDate',
                            filter: { type: CriteriaType.DateCriterion },
                            render: safeRender('shippingDate', (shippingDate) =>
                                processUnixDateForViewing(shippingDate),
                            ),
                        },
                        {
                            title: 'External Reference',
                            field: 'externalReference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Shipment Reference',
                            field: 'shipmentReference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Notes',
                            field: 'notes',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            filter: {
                                options: invoiceStatuses,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Approved Amount',
                            field: 'approvedAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('approvedAmount', (approvedAmount) =>
                                displayAmount(approvedAmount as number),
                            ),
                        },
                        {
                            title: 'Paid Amount',
                            field: 'paidAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Invoice) => displayAmountWithSign(rowData, rowData.paidAmount),
                        },
                        {
                            title: 'Effective Rate',
                            field: 'effectiveRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('effectiveRate', (effectiveRate) =>
                                (effectiveRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Issue Date',
                            field: 'issueDate',
                            filter: { type: CriteriaType.DateCriterion },
                            render: safeRender('issueDate', (issueDate) => processUnixDateForViewing(issueDate)),
                        },
                        {
                            title: 'Balance Capture Rate',
                            field: 'balanceCaptureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('balanceCaptureRate', (balanceCaptureRate) =>
                                (balanceCaptureRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Capture Rate',
                            field: 'captureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('captureRate', (captureRate) => (captureRate as number).toFixed(4)),
                        },
                        {
                            title: 'Original Capture Rate',
                            field: 'originalCaptureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (invoice: Invoice) => displayRate(invoice.originalCaptureRate),
                        },
                        {
                            title: 'Capture Spot Rate',
                            field: 'captureSpotRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (invoice: Invoice) => displayRate(invoice.captureSpotRate),
                        },
                        {
                            title: 'Capture Date',
                            field: 'captureDate',
                            filter: {
                                displayAccessor: 'captureDate',
                                valueAccessor: 'captureDate',
                                type: CriteriaType.TimeCriterion,
                            },
                            render: (rowData: Invoice) => moment(rowData.captureDate).format(SystemDateTimeFormat),
                        },
                        {
                            title: 'Direction',
                            field: 'direction',
                            filter: {
                                type: CriteriaType.TextCriterion,
                                options: invoiceDirections,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                            },
                        },
                        {
                            title: 'Import/Export',
                            field: 'importExport',
                            filter: {
                                options: invoiceImportExport,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Last Modified',
                            field: 'auditEntry.time',
                            render: safeRender('auditEntry', (auditEntry: unknown) =>
                                processUnixDateTimeForViewing((auditEntry as AuditEntry).time),
                            ),
                        },
                    ]}
                    count={totalInvoices}
                    data={invoices || []}
                    defaultColConfig={[
                        { header: 'Type', visible: true },
                        { header: 'Number', visible: true },
                        { header: 'Counterparty', visible: true },
                        { header: 'Original Amount Due', visible: true },
                        { header: 'Balance Due', visible: true },
                        { header: 'Currency', visible: true },
                        { header: 'Due Date', visible: true },
                        { header: 'Status', visible: true },
                        { header: 'Last Modified', visible: true },
                        { header: 'Financial Year', visible: false },
                        { header: 'Party', visible: false },
                        { header: 'Cost Currency', visible: false },
                        { header: 'Costing Rate', visible: false },
                        { header: 'Shipping Date', visible: false },
                        { header: 'External Reference', visible: false },
                        { header: 'Shipment Reference', visible: false },
                        { header: 'Notes', visible: false },
                        { header: 'Approved Amount', visible: false },
                        { header: 'Paid Amount', visible: false },
                        { header: 'Effective Rate', visible: false },
                        { header: 'Issue Date', visible: false },
                        { header: 'Capture Rate', visible: false },
                        { header: 'Balance Capture Rate', visible: false },
                        { header: 'Capture Date', visible: false },
                        { header: 'Original Capture Rate', visible: false },
                        { header: 'Capture Spot Rate', visible: false },
                        { header: 'Direction', visible: false },
                        { header: 'Import/Export', visible: false },
                    ]}
                    showFilterRow={showFilter}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    initialCriteria={baseCriteriaConfig}
                    loading={loading}
                    onChangeSorting={handleChangeSorting}
                    onFilterChange={handleFilterChange}
                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                    selected={selected ? selected : []}
                    onRowCheck={handleSelectRow}
                    rowDoubleClickAction={(rowData: Invoice) => {
                        setSelected([rowData]);
                        setShowDetail(true);
                    }}
                    rowClickAction={handleSelectRow}
                    onSelectAll={handleSelectAll}
                    rowsPerPage={query.limit}
                    rowsPerPageOptions={rowsPerPageOptions}
                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                    tableID={'InvoiceStationTable'}
                    showCheckboxes
                />
            </StandardCard>
        </div>
    );
};

export default InvoiceStation;
