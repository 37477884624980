import React, { ReactElement } from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { ItemWrapper } from './ItemWrapper';
import { Entity } from 'popcorn-js/audit';
import { HexToRGBA } from 'utils';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: HexToRGBA('#ffffff', 0.1),
        color: theme.palette.text.primary,
        padding: theme.spacing(),
        border: '1px solid #26283c',
        marginBottom: theme.spacing(2),
    },
}));

export const AuditEntry = (props: { entity: Entity }): ReactElement => {
    const classes = useStyles();
    const inputProps = {
        disableUnderline: true,
        readOnly: true,
    };

    const entity = props.entity || {};
    return (
        <React.Fragment>
            <div className={classes.title}>Audit Information</div>
            <Grid alignContent={'flex-end'} container direction={'column'} spacing={1}>
                <ItemWrapper label={'Updated By'}>
                    <TextField InputProps={inputProps} id="UserId" value={entity.auditEntry?.username || 'unknown'} />
                </ItemWrapper>
                <ItemWrapper label={'Previous Action'}>
                    <TextField InputProps={inputProps} id="action" value={entity.auditEntry?.action} />
                </ItemWrapper>
                <ItemWrapper label={'Date of last edit'}>
                    <TextField InputProps={inputProps} id="time" type={'datetime'} value={entity.auditEntry?.time} />
                </ItemWrapper>
                <ItemWrapper label={'Current Version'}>
                    <TextField InputProps={inputProps} id="action" value={entity.auditEntry?.version} />
                </ItemWrapper>
            </Grid>
        </React.Fragment>
    );
};

AuditEntry.propTypes = {};
