/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { ListItem } from './ListItem';
import { makeStyles } from '@material-ui/core';
import { HexToRGBA } from 'utils';
import { CustomTheme } from 'theme/custom';
import { Invoice } from 'popcorn-js/invoice';
import { LinkedInvoice } from 'popcorn-js/order';

const useStyles = makeStyles((theme: CustomTheme) => ({
    formField: {
        color: theme.palette.custom.text.body,
    },
    title: {
        backgroundColor: HexToRGBA('#ffffff', 0.1),
        color: theme.palette.text.primary,
        padding: theme.spacing(),
        border: '1px solid #26283c',
    },
    noRecordsMessage: {
        padding: theme.spacing(),
        flex: 1,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    itemWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    noRecordsMessageContainer: {
        display: 'flex',
        width: '100%',
        height: '33px',
    },
}));

export const EntityList = (props: {
    additionalActions?: any;
    columns: any[];
    entities: any[];
    listHeader?: string;
    noRecordsMessage?: string;
    onClick?: (i: Invoice) => void;
    delinkOnClick?: (i: LinkedInvoice) => void;
}): ReactElement => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.listHeader && <div className={classes.title}>{props.listHeader}</div>}
            <div className={classes.itemWrapper}>
                <ListItem columns={props.columns} header />
                {(() => {
                    if ((props.entities || []).length > 0) {
                        return props.entities.map((entity, index) => {
                            return (
                                <ListItem
                                    additionalActions={props.additionalActions}
                                    delinkOnClick={props.delinkOnClick}
                                    columns={props.columns}
                                    entity={entity}
                                    key={index}
                                    onClick={props.onClick}
                                />
                            );
                        });
                    } else {
                        return (
                            <div className={classes.noRecordsMessageContainer}>
                                <div className={classes.noRecordsMessage}>{props.noRecordsMessage}</div>
                                {props.additionalActions && props.additionalActions.length > 0 && (
                                    <div className={classes.noRecordsMessage}>{props.additionalActions}</div>
                                )}
                            </div>
                        );
                    }
                })()}
            </div>
        </React.Fragment>
    );
};
