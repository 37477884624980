import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import { createStyles, makeStyles } from '@material-ui/core';
import { useService, useServiceSync } from 'hooks/useService';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import FileSaver from 'file-saver';
import DetailDialogNew from 'components/tradeV2/TradeDetailDialog';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { ImportExport, Trade, TradeDirection, TradeStatus, TradeType } from 'popcorn-js/tradeV2';
import { displayDate, displayRate, processUnixDateForViewing } from 'utils/';
import { debounce } from 'lodash';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { History } from 'components/tradeV2/History';
import { displayAmount } from 'utils/';
import {
    Downloader as DownloaderTrade,
    DownloadTradeRequest,
    DownloadTradeResponse,
} from 'popcorn-js/tradeV2/downloader';
import { AppContextT, AppContext } from 'context';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { CustomTheme } from 'theme/custom';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import { StandardCard } from 'components/Card/Card';
import Big from 'big.js';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        root: {
            height: 'calc(100vh - 100px)',
            overflowY: 'scroll',
            justifyItems: 'center',
        },
        rootTable: {
            borderRadius: '8px',
            marginBottom: 0,
        },
        cardContentRoot: {
            padding: 0,
            gridTemplateColumns: 'auto auto 1fr auto',
            '&:last-child': {
                padding: 0,
            },
        },
        headerRoot: {
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.75),
            height: '52px',
            color: theme.palette.primary.contrastText,
        },
    }),
);

const tradeStatuses = [
    {
        value: TradeStatus.OPEN,
        label: TradeStatus.OPEN,
    },
    {
        value: TradeStatus.MATURED,
        label: TradeStatus.MATURED,
    },
];
const tradeDirections = [
    {
        value: TradeDirection.BUY,
        label: TradeDirection.BUY,
    },
    {
        value: TradeDirection.SELL,
        label: TradeDirection.SELL,
    },
];

const tradeTypesForColumn = [
    { value: TradeType.SPOT },
    { value: TradeType.FORWARD },
    { value: TradeType.EXTENSION },
    { value: TradeType.CANCELLATION },
    { value: TradeType.DRAWDOWN },
    { value: TradeType.SWAP },
];
const importExportOptions = [{ value: ImportExport.IMPORT }, { value: ImportExport.EXPORT }];

const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
    dealRate: {
        type: CriteriaType.NumberCriterion,
        field: 'dealRate.value',
        lessThan: {
            ignore: true,
        },
        moreThan: {
            amount: 0,
            inclusive: false,
        },
    },
};

const TradeStationV2 = (): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const currencyPairs = appContext.currencyPairs || [];

    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [trades, setTrades] = useState<Trade[] | undefined>();
    const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
    const [showTradeHistoryDialog, setShowTradeHistoryDialog] = useState<boolean>(false);
    const [query, setQuery] = useState<Query>({
        sortBy: ['tradeDate', 'currencyPair.name', 'status'],
        order: ['desc', 'asc', 'desc', 'asc'],
        limit: 12,
        offset: 0,
    });
    const initialCriteria = Object.keys(baseCriteriaConfig).map((field) => {
        return baseCriteriaConfig[field];
    });
    const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<Trade | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>();

    // service providers
    const [findTradeService] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);

    const [DownloadTradesExec] = useServiceSync<DownloadTradeRequest, DownloadTradeResponse>(
        DownloaderTrade.downloadTrades,
    );
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);

    const downloadTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadTradesResponse = await DownloadTradesExec({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadTradesResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Trade Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occured. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const findTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findTradeService({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            setTrades(result.records);
            setTradesTotal(result.total);

            // set new selected trade
            const seletectedTradeId = selected?.id;
            if (seletectedTradeId) {
                const selectedTrades = result.records?.filter((trd) => trd.id === seletectedTradeId);
                const selectedTrade = selectedTrades?.length === 1 ? selectedTrades[0] : undefined;
                setSelected(selectedTrade);
                if (!selectedTrade) {
                    handleCloseDialog();
                }
            }
        } catch (e) {
            setErrorMessage(e.message);
        }
        setLoading(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findTrades(criteria, newQuery).finally();
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['tradeDate'],
            order: ['desc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findTrades(criteria, newQuery).finally();
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
        setReloadTrigger(true);
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findTrades(criteria, newQuery).finally();
    };

    const handleFilterChange = useCallback(
        debounce((newCrit: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCrit.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (newCrit) {
                if (allCriteria.length > 0) {
                    {
                        if (financialYears[0] === 'ALL') {
                            newCrit.push({
                                type: CriteriaType.TextCriterion,
                                field: 'financialYear',
                                text: '',
                            });
                        }
                    }
                }
                setSelected(undefined);
                setQuery(newQuery);
                setCriteria(newCrit);
                findTrades(newCrit, newQuery).finally();
            }
        }, 100),
        [query],
    );

    const handleSelectRow = (rowData: Trade) => {
        const newSelected = { ...rowData };
        if (newSelected.id === selected?.id) {
            setSelected(undefined);
        } else {
            setSelected(newSelected);
        }
    };

    const handleCloseDialog = () => {
        setSelected(undefined);
        findTrades().finally();
        setDetailDialogOpen(false);
    };
    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const [
        { response: deleteTradeResponse, loading: deleteTradeLoading, error: deleteTradeError },
        setDeleteTradesRequest,
    ] = useService(undefined, TradeHandler.DeleteTransactionForever);

    useEffect(() => {
        if (deleteTradeResponse && !deleteTradeLoading) {
            setSuccessMessage('Trade deleted');
            setSelected(undefined);
            setReloadTrigger(true);
        }
        if (deleteTradeError) {
            setErrorMessage(deleteTradeError);
        }
    }, [deleteTradeResponse, deleteTradeLoading, deleteTradeError]);

    const amendSuccess = async () => {
        await findTrades(criteria, query);
    };

    return (
        <div id="tradeStationRoot" className={classes.root}>
            {detailDialogOpen && (
                <DetailDialogNew
                    closeDialog={handleCloseDialog}
                    open={detailDialogOpen}
                    trade={selected}
                    amendSuccess={amendSuccess}
                />
            )}
            {showTradeHistoryDialog && (
                <History
                    onHide={() => setShowTradeHistoryDialog(false)}
                    open={showTradeHistoryDialog}
                    trade={selected}
                />
            )}
            {!reloadTrigger && (
                <StandardCard
                    cardHeaderProps={{
                        tailoredState: selected ? STATES.SELECTED_ROW : undefined,
                        itemsLeft: [
                            {
                                id: 'TradeStation/title',
                                type: ITEM_VARIATION.TITLE,
                                text: 'Trades',
                            },
                        ],
                        itemsRight: [
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/delete',
                                icon: ACTION_BUTTON_TYPE.DELETE,
                                helpText: 'Delete Transaction',
                                onClick: () => {
                                    setWarningMessage(
                                        `You are about to delete '${selected?.number}'. Deleting this trade will delete the whole transaction
                                           this trade belongs to. Do you want to continue?`,
                                    );
                                    setConfirmationMethod(() => async () => {
                                        setWarningMessage(undefined);
                                        setConfirmationMethod(undefined);
                                        setDeleteTradesRequest({
                                            identifier: {
                                                type: IdentifierType.ID_IDENTIFIER,
                                                id: selected?.transactionID,
                                            },
                                        });
                                    });
                                },
                                hide: !selected,
                            },
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/view-details',
                                icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                helpText: 'View Details',
                                onClick: () => setDetailDialogOpen(true),
                                hide: !selected,
                            },
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/view-history',
                                icon: ACTION_BUTTON_TYPE.HISTORY,
                                helpText: 'History',
                                onClick: () => setShowTradeHistoryDialog(true),
                                hide: !selected,
                            },
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/filter',
                                icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                helpText: 'Filter',
                                onClick: () => {
                                    setShowFilterRow(!showFilterRow);
                                    handleFilterChange(initialCriteria);
                                },
                            },
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/view-column-conf',
                                icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                                helpText: ' Open column configuration',
                                onClick: () => setColConfigOpen(!colConfigOpen),
                            },
                            {
                                type: ITEM_VARIATION.ICON_BUTTON,
                                id: 'TradeStation/download',
                                icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                                helpText: 'Download trades',
                                onClick: () => {
                                    downloadTrades(criteria, query).finally();
                                },
                            },
                        ],
                    }}
                >
                    <div>
                        {((): React.ReactNode => {
                            return (
                                <Table
                                    colConfigCloseFromCard={handleCloseColConfig}
                                    colConfigOpenFromCard={colConfigOpen}
                                    columns={[
                                        {
                                            title: 'Trade Number',
                                            field: 'number',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.number) {
                                                    return rowData.number;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'External Reference',
                                            field: 'externalReference',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.externalReference) {
                                                    return rowData.externalReference;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Cancellation Trade',
                                            field: 'cancellation',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.cancellation ? 'TRUE' : 'FALSE';
                                            },
                                        },
                                        {
                                            title: 'Transaction ID',
                                            field: 'transactionID',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.transactionID) {
                                                    return rowData.transactionID;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Type',
                                            field: 'type',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                options: tradeTypesForColumn,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.type) {
                                                    return rowData.type;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Capture Date',
                                            field: 'captureDate',
                                            render: (rowData: Trade) => {
                                                if (rowData.captureDate) {
                                                    return processUnixDateForViewing(rowData.captureDate);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            filter: { type: CriteriaType.TimeCriterion },
                                        },
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            filter: {
                                                options: tradeStatuses,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.status) {
                                                    return rowData.status;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Currency Pair',
                                            field: 'currencyPair',
                                            filter: {
                                                options: currencyPairs,
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.currencyPair) {
                                                    return rowData.currencyPair;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Import/Export',
                                            field: 'importExport',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                options: importExportOptions,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.importExport) {
                                                    return rowData.importExport;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'ACM Trade',
                                            field: 'acm',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.acm ? 'TRUE' : 'FALSE';
                                            },
                                        },
                                        {
                                            title: 'Financial Year',
                                            field: 'financialYear',
                                            filter: {
                                                options: financialYears.map((f) => ({ name: f })),
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.financialYear) {
                                                    return rowData.financialYear;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Bank',
                                            field: 'bank',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.bank) {
                                                    return rowData.bank;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Direction',
                                            field: 'direction',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                options: tradeDirections,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.direction) {
                                                    return rowData.direction;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Maturity Date',
                                            field: 'maturityDate',
                                            render: (rowData: Trade) => {
                                                if (rowData.maturityDate) {
                                                    return displayDate(rowData.maturityDate.toString());
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            filter: { type: CriteriaType.TimeCriterion },
                                        },
                                        {
                                            title: 'Trade Date',
                                            field: 'tradeDate',
                                            render: (rowData: Trade) => {
                                                if (rowData.tradeDate) {
                                                    return displayDate(rowData.tradeDate.toString());
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            filter: { type: CriteriaType.TimeCriterion },
                                        },
                                        {
                                            title: 'Available Balance',
                                            field: 'availableBalance.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.availableBalance) {
                                                    return displayAmount(rowData.availableBalance);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'ACM Balance',
                                            field: 'acmBalance.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.acmBalance) {
                                                    return displayAmount(rowData.acmBalance);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Captured Spot Rate',
                                            field: 'capturedSpotRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.bankRate) {
                                                    return rowData.bankRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Interbank Rate',
                                            field: 'interbankRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.interbankRate) {
                                                    return rowData.interbankRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Bank Rate',
                                            field: 'bankRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.bankRate) {
                                                    return rowData.bankRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Quote Amount',
                                            field: 'quoteAmount.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.quoteAmount) {
                                                    return displayAmount(rowData.quoteAmount);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Notional Amount',
                                            field: 'notionalAmount.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.notionalAmount) {
                                                    return displayAmount(rowData.notionalAmount);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Spot Price',
                                            field: 'spotPrice.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.spotPrice) {
                                                    return rowData.spotPrice.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Forward Points',
                                            field: 'forwardPoints.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.forwardPoints) {
                                                    return displayRate(rowData.forwardPoints);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Deal Rate',
                                            field: 'dealRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.dealRate) {
                                                    return rowData.dealRate.toFixed(6);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },

                                        {
                                            title: 'Effective Rate',
                                            field: 'effectiveRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.effectiveRate) {
                                                    return rowData.effectiveRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Net Cash Flow',
                                            field: 'netCashFlow.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.netCashFlow) {
                                                    return rowData.netCashFlow.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Realised PnL',
                                            field: 'realisedPnl.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.realisedPnl) {
                                                    return rowData.realisedPnl.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Matched',
                                            field: 'matched',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.matched ? 'Matched' : '';
                                            },
                                        },
                                        {
                                            title: 'Trader',
                                            field: 'trader',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.trader) {
                                                    return rowData.trader;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Trader Organisation',
                                            field: 'traderOrganisation',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.traderOrganisation) {
                                                    return rowData.traderOrganisation;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Portfolio Manager',
                                            field: 'portfolioManager',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.portfolioManager) {
                                                    return rowData.portfolioManager;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Relationship Manager',
                                            field: 'relationshipManager',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.relationshipManager) {
                                                    return rowData.relationshipManager;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Notes',
                                            field: 'notes',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.notes) {
                                                    return rowData.notes;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                    ]}
                                    count={tradesTotal}
                                    data={trades || []}
                                    defaultColConfig={[
                                        { header: 'Trade Number', visible: true },
                                        { header: 'External Reference', visible: true },
                                        { header: 'Cancellation Trade', visible: true },
                                        { header: 'Transaction ID', visible: false },
                                        { header: 'Type', visible: true },
                                        { header: 'Capture Date', visible: true },
                                        { header: 'Status', visible: true },
                                        { header: 'Currency Pair', visible: true },
                                        { header: 'Import/Export', visible: true },
                                        { header: 'ACM Trade', visible: false },
                                        { header: 'Financial Year', visible: false },
                                        { header: 'Bank', visible: false },
                                        { header: 'Direction', visible: true },
                                        { header: 'Maturity Date', visible: true },
                                        { header: 'Trade Date', visible: true },
                                        { header: 'Available Balance', visible: true },
                                        { header: 'ACM Balance', visible: false },
                                        { header: 'Matched', visible: false },
                                        { header: 'Captured Spot Rate', visible: false },
                                        { header: 'Interbank Rate', visible: false },
                                        { header: 'Bank Rate', visible: false },
                                        { header: 'Quote Amount', visible: false },
                                        { header: 'Notional Amount', visible: true },
                                        { header: 'Spot Price', visible: false },
                                        { header: 'Forward Points', visible: false },
                                        { header: 'Deal Rate', visible: true },
                                        { header: 'Effective Rate', visible: true },
                                        { header: 'Net Cash Flow', visible: false },
                                        { header: 'Realised PnL', visible: false },
                                        { header: 'Trader', visible: false },
                                        { header: 'Trader Organisation', visible: false },
                                        { header: 'Portfolio Manager', visible: false },
                                        { header: 'Relationship Manager', visible: false },
                                        { header: 'Notes', visible: false },
                                    ]}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    loading={loading}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    onRowCheck={handleSelectRow}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    rowClickAction={handleSelectRow}
                                    rowDoubleClickAction={(e) => {
                                        setSelected(e);
                                        setDetailDialogOpen(true);
                                    }}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                    onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                                    selected={selected ? [selected] : []}
                                    showCheckboxes
                                    showFilterRow={showFilterRow}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    tableID={'TradeStationTable'}
                                    title={'Trades'}
                                    initialCriteria={baseCriteriaConfig}
                                />
                            );
                        })()}
                    </div>
                    <NotificationSweetAlert
                        errorMessage={errorMessage}
                        onClose={handleHideAlert}
                        onConfirm={confirmationMethod}
                        successMessage={successMessage}
                        warningMessage={warningMessage}
                    />
                </StandardCard>
            )}
        </div>
    );
};

export default TradeStationV2;
