import createPalette, { Palette } from '@material-ui/core/styles/createPalette';

export const paletteFromGuidelines = {
    primary: {
        light: '#2FDCFC', // Specified in guidelines but never used
        main: '#2BBED9', //
        dark: '#0079c5',
        contrastText: '#040833',
    },
    secondary: {
        light: '#FFFF51',
        main: '#ECD400',
        dark: '#B5A300',
        contrastText: '#040833',
    },
    info: {
        light: '#7FD8E8',
        main: '#55CBE0',
        dark: '#2BA8F8',
        contrastText: '#040833',
    },
    success: {
        light: '#97f19d',
        main: '#65BE6E',
        dark: '#328d41',
        contrastText: '#040833',
    },
    error: {
        light: '#FF8584',
        main: '#FF5057',
        dark: '#c5072e',
        contrastText: '#040833',
    },
    warning: {
        light: '#FFE75F',
        main: '#FFB529',
        dark: '#c78500',
        contrastText: '#000000',
    },
    background: {
        paper: '#27254D',
        default: '#040833',
    },
    text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
        hint: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
        active: 'rgba(255, 255, 255, 0.7)',
        hover: '#74d9ff99',
        hoverOpacity: 0.08,
        selected: 'rgba(255, 255, 255, 0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255, 255, 255, 0.3)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(255, 255, 255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
    },
    // custom
    custom: {
        rowHighlights: {
            light: '#414272',
            dark: '#333260',
        },
        // This is a names color in the new designs
        icons: {
            main: '#9FA7DF',
        },
        // TODO this is confusing - we need to call this something other that primary - like context/direct
        stellcapBrand2: {
            light: '#AAD5DB',
            main: '#077280',
            dark: '#044F59',
            contrastText: '#FFFFFF',
        },
        stellcapBrand1: {
            light: '#FFF372',
            main: '#D5C141',
            dark: '#8C803C',
            contrastText: '#040833',
        },
        import: {
            light: '#FEC54E',
            main: '#F89515',
            dark: '#BE6600',
            contrastText: '#040833',
        },
        export: {
            light: '#76FFE0',
            main: '#39CBAE',
            dark: '#109A80',
            contrastText: '#040833',
        },
        paperExtended: {
            paper2: '#2F305D',
            paper3: '#525081',
            paper4: '#9FA7DF',
            paper5: '#383964',
            paper6: '#39375C',
        },
        dividerExtended: {
            hor_div1: '#4C497D',
            ver_div2: '#64659A',
        },
        spotRate: {
            light: '#63F87B',
            main: '#18C44C',
            dark: '#00921b',
            contrastText: '#040833',
        },
        btnPrimary: {
            light: '#D5C141',
            main: '#B0A03E',
            dark: '#8C803C',
            contrastText: '#040833',
        },
        btnExport: {
            light: '#39CBAE',
            main: '#33A896',
            dark: '#2E877E',
            contrastText: '#040833',
        },
        btnImport: {
            light: '#F89515',
            main: '#CC7D29',
            dark: '#A16622',
            contrastText: '#040833',
        },
        data: {
            // old data colours - to be removed when graphs are ported to new palette
            graphB: '#FF5057', // 'error red main' on guidelines
            graphC: '#F1FFA3', // 'net trade cash flow yellow main' on guidelines
            data1B: '#FC930C',
            data3B: '#1DC447',
            data1: {
                light: '#ECADFF',
                main: '#B87DF4',
                dark: '#854FC1',
                contrastText: '#040833',
            },
            data2: {
                light: '#FFB6FF',
                main: '#FE83E7',
                dark: '#CA51B5',
                contrastText: '#FFF',
            },
            data3: {
                light: '#2FDCFC',
                main: '#2BBED9',
                dark: '#26A6BE',
                contrastText: '#040833',
            },
            data4: {
                light: '#FFFF51',
                main: '#ECD400',
                dark: '#B5A300',
                contrastText: '#040833',
            },
            exportData: {
                light: '#76FFE0',
                main: '#39CBAE',
                dark: '#109A80',
                contrastText: '#040833',
            },
            importData: {
                light: '#FEC54E',
                main: '#F89515',
                dark: '#BE6600',
                contrastText: '#040833',
            },
            overdue: {
                light: '#FE8584',
                main: '#FE5057',
                dark: '#C5072E',
                contrastText: '#040833',
            },
            cashFlows: {
                main: '#F1FFA3',
                contrastText: '#040833',
            },
        },
        // NB not in palette, but used quite extensively....
        text: {
            primary: '#FFFFFF',
            secondary: '#787986',
            body: '#8F909B',
            accent: '#F2C713',
            label: '#00D2FE',
            positive: '#33D1B2',
        },
    },
};

// the default palette if no calculation w.r.t. context is required
export const paletteDefault = {
    ...paletteFromGuidelines,
};
// the dynamic palette, based on context
export const palette = (): Palette => {
    return {
        ...createPalette({}),
        ...paletteDefault,
    };
};
