import React, { ReactElement, useContext, useState } from 'react';
import { Collapse, Drawer, List, ListItem, useTheme } from '@material-ui/core';
import { styled, useStyletron } from 'styletron-react';
import { useEffect } from 'react';
import logo from 'assets/img/stellcap-logo.svg';
import miniLogo from 'assets/img/stellcap-logo-mini.svg';
import { ViewRoute } from 'routes';
import { useLocation } from 'react-router-dom';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { NavLink } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { HexToRGBA } from 'utils';

export const Sidebar = (props: {
    open: boolean;
    openWidth: number;
    closedWidth: number;
    onClose: () => void;
    routes: ViewRoute[];
}): ReactElement => {
    const { openWidth, closedWidth, onClose } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    // keeps track of collapsible route states
    const [collapsables, setCollapsables] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (hover || props.open) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [hover, props.open]);

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);

    const mainColor = appContext.contextSwitched
        ? theme.palette.custom.stellcapBrand2
        : theme.palette.custom.stellcapBrand1;

    const routes = props.routes
        .filter((r: ViewRoute) => !(['Configuration'].includes(r.name) || !r.icon))
        .filter((r: ViewRoute) => !r.permission || appContext.viewPermissions?.includes(r.permission || ''))
        .filter((r: ViewRoute) => (r.dev ? ['localhost', 'dev'].includes(appContext.environment) : true))
        .map((route: ViewRoute, key: number) => {
            if (route.path) {
                return (
                    <NavListItem
                        onClose={() => {
                            setOpen(false);
                            setHover(false);
                            onClose();
                        }}
                        key={key}
                        mainColor={mainColor}
                        open={open}
                        route={route}
                    />
                );
            } else if (route.views && route.views.length > 0) {
                return (
                    <CollapsedNavList
                        key={route.name}
                        collapsed={collapsables[route.name]}
                        onCollapse={(c: boolean) => setCollapsables({ ...collapsables, [route.name]: c })}
                        open={open}
                        route={route}
                        mainColor={mainColor}
                        onClose={() => {
                            setOpen(false);
                            setHover(false);
                            onClose();
                        }}
                    />
                );
            }
            return <div key={key} />;
        });

    return (
        <Drawer
            className={css({
                width: `${open ? openWidth : closedWidth}px`,
                overflow: 'auto',
                position: 'absolute',
            })}
            classes={{
                paper: css({
                    width: `${open ? openWidth : closedWidth}px`,
                    overflow: 'auto',
                    border: 'none',
                }),
            }}
            variant={open ? 'temporary' : 'permanent'}
            anchor={'left'}
            open={open}
            ModalProps={{
                onClose: () => {
                    setOpen(false);
                    setHover(false);
                    onClose();
                },
            }}
            transitionDuration={0}
        >
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: theme.palette.background.paper,
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: open ? '104px' : '64px',
                        maxHeight: open ? '104px' : '64px',
                        minHeight: open ? '104px' : '64px',
                        overflow: 'hidden',
                        backgroundColor: theme.palette.background.default,
                    })}
                >
                    {open ? (
                        <img
                            alt="logo"
                            className={css({
                                overflow: 'hidden',
                                height: '72px',
                            })}
                            src={logo}
                        />
                    ) : (
                        <img
                            alt="mini-logo"
                            className={css({
                                overflow: 'hidden',
                                height: '40px',
                            })}
                            src={miniLogo}
                        />
                    )}
                </div>
                <div
                    className={css({
                        display: 'flex',
                        flexGrow: 1,
                        height: '100%',
                        overflow: 'hidden',
                    })}
                >
                    <div
                        className={css({
                            flexGrow: 1,
                            width: '100vw',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        })}
                    >
                        <List>{routes}</List>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

const activeRoute = (location: { pathname: string }, path: string | undefined) => {
    return location.pathname === path;
};

const StyledListItem = styled((props) => <ListItem button={false} {...props} />, {
    color: '#FFF',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    background: 'none',
});
const StyledListItemText = styled('span', {
    lineHeight: '30px',
    fontSize: '14px',
    opacity: '1',
    verticalAlign: 'center',
    textAlign: 'left',
    position: 'relative',
    display: 'block',
    height: 'auto',
    marginLeft: '11px',
});
const StyledMiniListItemText = styled('span', {
    lineHeight: '30px',
    fontSize: '14px',
    verticalAlign: 'center',
    textAlign: 'left',
    position: 'relative',
    display: 'block',
    height: 'auto',
    marginLeft: '11px',
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
});

const NavListItem = (props: {
    open: boolean;
    route: ViewRoute;
    mainColor: { main: string; contrastText: string };
    onClose: () => void;
}): ReactElement => {
    const { open, route, mainColor, onClose } = props;

    const [hover, setHover] = useState<boolean>(false);

    const [css] = useStyletron();
    const location = useLocation();

    const isActive = activeRoute(location, route.path);
    const backgroundColor = isActive ? mainColor.main : 'none';

    const color = isActive || hover ? mainColor.contrastText : HexToRGBA('#FFF', 0.8);

    return (
        <StyledListItem onClick={onClose}>
            <NavLink
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onMouseOver={() => setHover(true)}
                className={css({
                    width: open ? '100%' : '40px',
                    height: open ? '56px' : '40px',
                    textDecoration: 'none',
                    borderRadius: '3px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor,
                    ':hover': {
                        backgroundColor: HexToRGBA(mainColor.main, 0.8),
                    },
                    ':active': {
                        backgroundColor: HexToRGBA(mainColor.main, 0.6),
                    },
                })}
                id={route.linkID}
                to={route.path || ''}
            >
                {route.icon ? (
                    <route.icon
                        className={css({
                            color,
                            width: '20px',
                            height: '20px',
                            float: 'left',
                            position: 'inherit',
                            opacity: '0.8',
                            marginLeft: '8px',
                        })}
                    />
                ) : (
                    <div
                        className={css({
                            color,
                            width: '20px',
                            minWidth: '20px',
                            height: '20px',
                            minHeight: '20px',
                            float: 'left',
                            position: 'inherit',
                            opacity: '0.8',
                            marginLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        })}
                    >
                        {route.mini}
                    </div>
                )}
                {open ? (
                    <StyledListItemText className={css({ color })}>{route.name}</StyledListItemText>
                ) : (
                    <StyledMiniListItemText className={css({ color })}>{route.name}</StyledMiniListItemText>
                )}
            </NavLink>
        </StyledListItem>
    );
};

const CollapsedNavList = (props: {
    open: boolean;
    collapsed: boolean;
    onCollapse: (collapsed: boolean) => void;
    onClose: () => void;
    route: ViewRoute;
    mainColor: { main: string; contrastText: string };
}): ReactElement => {
    const { open, route, mainColor, collapsed, onCollapse, onClose } = props;

    const [hover, setHover] = useState<boolean>(false);

    const [css] = useStyletron();

    const appContext = useContext<AppContextT>(AppContext);

    const filteredViews = route?.views?.filter((r: ViewRoute) =>
        appContext.viewPermissions?.includes(r.permission || ''),
    );

    if ((filteredViews || []).length === 0) {
        return <div key={route.linkID} />;
    }

    const childViews = filteredViews?.map((r: ViewRoute, k: number) => (
        <NavListItem onClose={onClose} key={k} mainColor={mainColor} open={open} route={r} />
    ));

    return (
        <>
            <StyledListItem>
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={css({
                        width: open ? '100%' : '40px',
                        height: open ? '56px' : '40px',
                        textDecoration: 'none',
                        borderRadius: '3px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: 'none',
                        ':hover': {
                            backgroundColor: HexToRGBA(mainColor.main, 0.8),
                        },
                        ':active': {
                            backgroundColor: HexToRGBA(mainColor.main, 0.6),
                        },
                    })}
                    id={route.linkID}
                    onClick={() => onCollapse(!collapsed)}
                >
                    {route.icon ? (
                        <route.icon
                            className={css({
                                color: hover ? mainColor.contrastText : '#FFF',
                                width: '20px',
                                height: '20px',
                                float: 'left',
                                position: 'inherit',
                                opacity: '0.8',
                                marginLeft: '8px',
                            })}
                        />
                    ) : (
                        ''
                    )}
                    {open ? (
                        <StyledListItemText className={css({ color: hover ? mainColor.contrastText : '#FFF' })}>
                            {route.name}
                        </StyledListItemText>
                    ) : (
                        <StyledMiniListItemText className={css({ color: hover ? mainColor.contrastText : '#FFF' })}>
                            {route.name}
                        </StyledMiniListItemText>
                    )}
                    {!collapsed ? (
                        <KeyboardArrowUp
                            className={css({
                                color: hover ? mainColor.contrastText : '#FFF',
                                marginLeft: 'auto',
                                marginRight: 0,
                            })}
                        />
                    ) : (
                        <KeyboardArrowDown
                            className={css({
                                color: hover ? mainColor.contrastText : '#FFF',
                                marginLeft: 'auto',
                                marginRight: 0,
                            })}
                        />
                    )}
                </div>
            </StyledListItem>
            <Collapse in={collapsed}>
                <List>{childViews}</List>
            </Collapse>
        </>
    );
};
