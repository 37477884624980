import { restRPC } from 'utils/restrpc';
import { Option, OptionAmendInput, OptionRecordInput } from 'popcorn-js/options/index';
import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { FindRequest, FindResponse, RetrieveHistoryRequestNew, RetrieveHistoryResponse } from 'popcorn-js/index';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Criteria } from 'popcorn-js/search';

export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    option: Option;
};

export type RecordRequest = {
    options: Array<OptionRecordInput>;
};

export type RecordResponse = {
    /* */
};

export type AmendRequest = {
    identifier?: Identifier | string;
    option: OptionAmendInput;
};

export type DeletePermanentlyRequest = {
    identifier?: Identifier | string;
};

export type DeletePermanentlyResponse = {
    /* */
};

export type ExerciseOptionsRequest = {
    optionsIDs: string[];
};

export type ExerciseOptionsResponse = {
    /* */
};

export interface HandlerI {
    ServiceProviderName: string;
    Record(request: RecordRequest): Promise<RecordResponse>;
    Amend(request: AmendRequest): Promise<RecordResponse>;
    DeletePermanently(request: DeletePermanentlyRequest): Promise<DeletePermanentlyResponse>;
    Find(request: FindRequest): Promise<FindResponse<Option>>;
    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse>;
    RetrieveHistory(request: RetrieveHistoryRequestNew): Promise<RetrieveHistoryResponse<Option>>;
    ExerciseOptions(request: ExerciseOptionsRequest): Promise<ExerciseOptionsResponse>;
}

export const MockHandler: HandlerI = {
    ServiceProviderName: 'Option-Handler',
    Record(request: RecordRequest): Promise<RecordResponse> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({});
        });
    },
    Amend(request: AmendRequest): Promise<RecordResponse> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({});
        });
    },
    DeletePermanently(request: DeletePermanentlyRequest): Promise<DeletePermanentlyResponse> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({});
        });
    },
    Find(request: FindRequest): Promise<FindResponse<Option>> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({ records: [] as Option[], total: 0 });
        });
    },
    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({ option: {} });
        });
    },
    RetrieveHistory(request: RetrieveHistoryRequestNew): Promise<RetrieveHistoryResponse<Option>> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({ history: [] as Option[] });
        });
    },
    ExerciseOptions(request: ExerciseOptionsRequest): Promise<ExerciseOptionsResponse> {
        return new Promise((resolve) => {
            console.log(request);
            resolve({});
        });
    },
};

export const Handler: HandlerI = {
    ServiceProviderName: 'Option-Handler',
    Record(request: RecordRequest): Promise<RecordResponse> {
        return restRPC<RecordRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.Record`,
            request,
        });
    },
    Amend(request: AmendRequest): Promise<RecordResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<AmendRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.Amend`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeletePermanently(request: DeletePermanentlyRequest): Promise<DeletePermanentlyResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeletePermanentlyRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.DeletePermanently`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Find(request: FindRequest): Promise<FindResponse<Option>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<Option>>({
            method: `${Handler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RetrieveRequest, RetrieveResponse>({
            method: `${Handler.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    RetrieveHistory(request: RetrieveHistoryRequestNew): Promise<RetrieveHistoryResponse<Option>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<RetrieveHistoryRequestNew, RetrieveHistoryResponse<Option>>({
            method: `${Handler.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    ExerciseOptions(request: ExerciseOptionsRequest): Promise<ExerciseOptionsResponse> {
        return restRPC<ExerciseOptionsRequest, ExerciseOptionsResponse>({
            method: `${Handler.ServiceProviderName}.ExerciseOptions`,
            request,
        });
    },
};
