// pages
import Home from 'views/Home/Home';
import InvoiceStation from 'views/InvoiceStation/InvoiceStation';
import OrderStation from 'views/OrderStation/OrderStation';
import FECManagementStationV2 from './views/FECManagementStationV2/FECManagementStation';
// formally known as FEC Management station
import ConsolidatedHedgeManagementStation from './views/ConsolidatedHedgeWorkstation/ConsolidatedHedgeManagementStation';
import { Workstation } from 'views/SettlementInstruction/Workstation';
import { SettlementInstructionsManagement } from 'views/SettlementInstruction/Management';
import CounterpartyStation from 'views/CounterpartyStation/CounterpartyStation';
import { Reports } from 'views/Reports/Reports';
// // configuration
import { UserConfiguration } from 'views/configuration/user/UserConfiguration';
import { APIUserConfiguration } from 'views/configuration/apiUser/APIUserConfiguration';
import { TradingDayExceptionsConfig } from 'views/configuration/tradingDayExceptions';
import { RoleConfiguration } from 'views/configuration/role/RoleConfiguration';
import { RoleTemplateConfiguration } from 'views/configuration/roleTemplate/RoleTemplateConfiguration';
import { ClientConfiguration } from 'views/configuration/party/ClientConfiguration';
import { ProcessingBankConfiguration } from 'views/configuration/party/ProcessingBankConfiguration';
import { ProcessingOrgConfiguration } from 'views/configuration/party/ProcessingOrgConfiguration';
import { ObfuscationConfig } from './views/configuration/obfuscation';

// icons
import {
    Dashboard,
    Assessment,
    PieChart,
    MonetizationOn,
    SvgIconComponent,
    BusinessCenter,
    People,
    Settings,
    CodeOutlined,
    Assignment,
    CloudUpload,
} from '@material-ui/icons';
import RateReviewIcon from '@material-ui/icons/RateReview';
// permissions
import {
    viewCounterpartyStation,
    viewConfiguration,
    viewLegalEntities,
    viewLegalEntitiesClient,
    viewLegalEntitiesProcessingBank,
    viewLegalEntitiesProcessingOrg,
    viewSettlementInstructionManagement,
    viewSettlementInstructionsV2,
    viewOperations,
    viewOperationsInvoiceStation,
    viewOperationsOrderStation,
    viewConfigurationRoles,
    viewConfigurationRoleTemplates,
    viewConfigurationTradingDayExceptions,
    viewConfigurationUsers,
    viewPartySettingsApiUsers,
    viewOpsStation,
    viewConfirmationsWorkstation,
    viewOperationsTradeStationV2,
    viewOperationsProducerContractsStation,
    viewHedgeStation,
    viewFECManagementStationV2,
    viewOperationsPaymentStation,
    viewCFCDepositManagementStation,
    viewUploadClientData,
    viewConfigurationObfuscation,
    viewRevenueWorkStation,
} from 'popcorn-js/permissions';
import { OpsManagement } from 'views/OpsManagement/OpsManagement';
import { Dev } from 'views/Dev/Dev';
import { ConfirmationsWorkstation } from 'views/ConfirmationsWorkstation/ConfirmationsWorkstation';
import TradeStationV2 from './views/TradeStation/TradeStationV2';
import { PaymentStation } from './views/PaymentStation/PaymentStation';
import CFCDepositManagementStation from './views/CFCDepositManagementStation/CFCDepositManagementStation';
import { UploadClientDataStation } from './views/UploadClientData/UploadClientDataStation';
import ProducerContractsStation from './views/ProducerContractsStation/ProducerContractsStation';
import { RevenueWorkStation } from 'views/RevenueWorkStation/RevenueWorkStation';

export type ViewRoute = {
    name: string;
    path?: string;
    mini?: string;
    component?: (props: unknown) => JSX.Element;
    icon?: ((props: unknown) => JSX.Element) | SvgIconComponent;
    permission?: string;
    linkID?: string;
    collapse?: boolean;
    views?: ViewRoute[];
    state?: string;
    redirect?: boolean;
    exact?: boolean;
    pathTo?: string;
    reportingEntity?: boolean;
    dev?: boolean;
};

export const configurationRoutes: ViewRoute[] = [
    {
        path: '/app/configuration-users',
        name: 'Users',
        mini: 'US',
        component: UserConfiguration,
        permission: viewConfigurationUsers,
        linkID: 'sidebarConfigurationUsers',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-roles',
        name: 'Roles',
        mini: 'RO',
        component: RoleConfiguration,
        permission: viewConfigurationRoles,
        linkID: 'sidebarConfigurationRolesLink2',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-role-templates',
        name: 'Role Templates',
        mini: 'RT',
        component: RoleTemplateConfiguration,
        permission: viewConfigurationRoleTemplates,
        linkID: 'sidebarConfigurationRoleTemplates',
        reportingEntity: true,
    },
    {
        path: '/app/configuration-trading-day-exceptions',
        name: 'Trading Day Exceptions',
        mini: 'TE',
        component: TradingDayExceptionsConfig,
        permission: viewConfigurationTradingDayExceptions,
        linkID: 'sidebarConfigurationTradingDayExceptionsLink',
    },
    {
        path: '/app/configuration-obfuscation',
        name: 'Obfuscation Config',
        mini: 'CM',
        component: ObfuscationConfig,
        permission: viewConfigurationObfuscation,
        linkID: 'sidebarConfigurationObfuscationLink',
    },
    {
        path: '/app/configuration-apiUsers',
        name: 'APIUsers',
        mini: 'AU',
        component: APIUserConfiguration,
        permission: viewPartySettingsApiUsers,
        linkID: 'configurationAPIUser',
    },
];

export const appRoutes: ViewRoute[] = [
    {
        path: '/app/dev',
        name: 'Dev',
        icon: CodeOutlined,
        component: Dev,
        dev: true,
    },
    {
        path: '/app/dashboard',
        name: 'Dashboard',
        icon: Dashboard,
        component: Home,
        linkID: 'sidebarClientHomeDashboardLink',
        reportingEntity: true,
    },
    {
        path: '/app/confirmationsWorkstation',
        name: 'Confirmations Workstation',
        icon: Assignment,
        component: ConfirmationsWorkstation,
        permission: viewConfirmationsWorkstation,
        linkID: 'sidebarClientHomeConfirmationsWorkstationLink',
    },
    {
        path: '/app/uploadClientData',
        name: 'Upload Client Data',
        icon: CloudUpload,
        component: UploadClientDataStation,
        permission: viewUploadClientData,
        linkID: 'sidebarClientHomeUploadClientDataLink',
    },

    {
        path: '/app/reports',
        name: 'Reports',
        icon: PieChart,
        component: Reports,
        permission: 'Hide',
        linkID: 'Reports',
    },
    {
        path: '/app/ConsolidatedHedgeDealingStation',
        name: 'Consolidated Hedge Workstation',
        icon: Assessment,
        component: ConsolidatedHedgeManagementStation,
        permission: viewHedgeStation,
        linkID: 'ConsolidatedHedgeManagementStation',
    },
    {
        path: '/app/FECDealingStation',
        name: 'FEC Management Station',
        icon: Assessment,
        component: FECManagementStationV2,
        permission: viewFECManagementStationV2,
        linkID: 'FECManagementStationV2',
    },
    {
        path: '/app/settlementInstructionWorkstation',
        name: 'Settlement Instruction Workstation',
        icon: MonetizationOn,
        component: Workstation,
        permission: viewSettlementInstructionsV2,
        linkID: 'sidebarSettlementInstructionWorkstationLink',
    },
    {
        path: '/app/manageSettlementInstructions',
        name: 'Manage Settlement Instructions',
        icon: MonetizationOn,
        component: SettlementInstructionsManagement,
        permission: viewSettlementInstructionManagement,
        linkID: 'sidebarSettlementInstructionManagementLink',
    },
    {
        path: '/app/revenueWorkStation',
        name: 'Revenue WorkStation',
        icon: MonetizationOn,
        component: RevenueWorkStation,
        permission: viewRevenueWorkStation,
        linkID: 'sidebarRevenueWorkStationLink',
    },
    {
        collapse: true,
        name: 'Operations',
        state: 'openOperations',
        icon: BusinessCenter,
        permission: viewOperations,
        views: [
            {
                path: '/app/counterparties',
                name: 'Counterparties',
                mini: 'C',
                component: CounterpartyStation,
                permission: viewCounterpartyStation,
                linkID: 'sidebarCounterpartyStationLink',
            },
            {
                path: '/app/orders',
                name: 'Orders',
                mini: 'O',
                component: OrderStation,
                permission: viewOperationsOrderStation,
                linkID: 'sidebarOrderStation',
            },
            {
                path: '/app/invoices',
                name: 'Invoices',
                mini: 'I',
                component: InvoiceStation,
                permission: viewOperationsInvoiceStation,
                linkID: 'sidebarInvoiceStationLink',
            },
            {
                path: '/app/payments',
                name: 'Payments',
                mini: 'P',
                component: PaymentStation,
                permission: viewOperationsPaymentStation,
                linkID: 'sidebarPaymentStationLink',
            },
            {
                path: '/app/tradeStation',
                name: 'Trades',
                mini: 'T',
                component: TradeStationV2,
                permission: viewOperationsTradeStationV2,
                linkID: 'sidebarTradeStationLink',
            },
            {
                path: '/app/CFCDepositManagementStation',
                name: 'CFC Deposits',
                mini: 'D',
                component: CFCDepositManagementStation,
                permission: viewCFCDepositManagementStation,
                linkID: 'sidebarCFCDepositManagementStationLink',
            },
            {
                path: '/app/producerContractsStation',
                name: 'Producer Contracts',
                mini: 'PC',
                component: ProducerContractsStation,
                permission: viewOperationsProducerContractsStation,
                linkID: 'sidebarProducerContractsStationLink',
            },
        ],
        linkID: 'operationsMenuOpen',
    },
    {
        collapse: true,
        path: '/app/configuration',
        name: 'Configuration',
        icon: Settings,
        views: configurationRoutes,
        permission: viewConfiguration,
        linkID: 'sidebarConfigurationMenuOpen',
    },
    {
        collapse: true,
        name: 'Legal Entities',
        state: 'openLegalEntities',
        icon: People,
        permission: viewLegalEntities,
        reportingEntity: true,
        views: [
            {
                path: '/app/legalEntities/processingBank',
                name: 'Processing Bank',
                mini: 'B',
                component: ProcessingBankConfiguration,
                permission: viewLegalEntitiesProcessingBank,
                linkID: 'sidebarLegalEntitiesProcessingBankLink',
            },
            {
                path: '/app/legalEntities/processingOrg',
                name: 'Processing Organization',
                mini: 'P',
                component: ProcessingOrgConfiguration,
                permission: viewLegalEntitiesProcessingOrg,
                linkID: 'sidebarLegalEntitiesProcessingOrgLink',
            },
            {
                path: '/app/legalEntities/client',
                name: 'Client',
                mini: 'C',
                component: ClientConfiguration,
                permission: viewLegalEntitiesClient,
                linkID: 'sidebarLegalEntitiesClientLink',
                reportingEntity: true,
            },
        ],
        linkID: 'legalEntitiesMenuOpen',
    },
    {
        path: '/app/operations',
        name: 'Ops Management',
        icon: RateReviewIcon,
        component: OpsManagement,
        permission: viewOpsStation,
        linkID: 'sidebarOperations',
    },
];
