import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import Table from 'components/Table/Table';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import moment from 'moment';
import { Revenue } from 'popcorn-js/revenue';
import { FindRevenueRequest, FindRevenueResponse, RevenueHandler } from 'popcorn-js/revenue/handler';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { DownloadRequest, DownloadResponse, RevenueDownloader } from 'popcorn-js/revenue/downloader';
import { useServiceSync } from 'hooks/useService';
import { SystemDateFormat } from 'constants/formats';
import { displayAmount, objectCopy } from 'utils';
import { AppContext, AppContextT } from 'context';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import { debounce } from '@material-ui/core';
import Big from 'big.js';
import FileSaver from 'file-saver';
import { Client, ProcessingBank, ProcessingOrg } from 'popcorn-js/party';
import { Handler as ClientHandler } from 'popcorn-js/party/client/handler';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'popcorn-js/party/processingOrg/recordkeeper';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'popcorn-js/party/processingBank/recordkeeper';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { Handler as OptionHandler } from 'popcorn-js/options/handler';
import { Counterparty } from 'popcorn-js/counterparty';
import { Trade } from 'popcorn-js/tradeV2';
import { Option } from 'popcorn-js/options';
import OptionDetailDialog from 'components/Option/OptionDetailDialog';
import DetailDialog from 'components/tradeV2/TradeDetailDialog';

const defaultCriteria: Criterion[] = [{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }];

export const RevenueTable = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [revenueResponse, setRevenueResponse] = useState<Revenue[] | undefined>();
    const [revenueTotal, setRevenueTotal] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [selected, setSelected] = useState<Revenue | undefined>();
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [viewOption, setViewOption] = useState<boolean>(false);
    const [viewTrade, setViewTrade] = useState<boolean>(false);
    const [trade, setTrade] = useState<Trade>();
    const [option, setOption] = useState<Option>();

    const [findRevenue] = useServiceSync<FindRevenueRequest, FindRevenueResponse>(RevenueHandler.Find);
    const [findTrades] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [findOption] = useServiceSync<FindRequest, FindResponse<Option>>(OptionHandler.Find);
    const [findClient] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);
    const [findProcessingOrg] = useServiceSync<FindRequest, FindResponse<ProcessingOrg>>(
        ProcessingOrgRecordkeeper.find,
    );
    const [findProcessingBank] = useServiceSync<FindRequest, FindResponse<ProcessingBank>>(
        ProcessingBankRecordkeeper.find,
    );
    const [DownloadRevenue] = useServiceSync<DownloadRequest, DownloadResponse>(RevenueDownloader.Download);

    const [criteriaTrade, setCriteriaTrade] = useState<Criteria>(defaultCriteria);

    const [criteriaOption, setCriteriaOption] = useState<Criteria>(defaultCriteria);

    const [query, setQuery] = useState<Query>({
        sortBy: ['currencyPair', 'maturityDate'],
        order: ['asc', 'asc'],
        limit: 12,
        offset: 0,
    });

    const systemColConfig = [
        { header: 'External Reference', visible: true },
        { header: 'Trade Date', visible: true },
        { header: 'Client', visible: true },
        { header: 'Processing Org', visible: true },
        { header: 'Bank', visible: true },
        { header: 'Trade Type', visible: true },
        { header: 'Currency Pair', visible: true },
        { header: 'IM/EX', visible: true },
        { header: 'ZAR', visible: true },
        { header: 'Maturity Date', visible: true },
        { header: 'Bank Rate', visible: true },
        { header: 'Deal Rate', visible: true },
        { header: 'Margin', visible: true },
        { header: 'Billed to Bank', visible: true },
        { header: 'Client Fee', visible: true },
        { header: 'Admin Fee', visible: true },
    ];

    const processingOrgColConfig = [
        { header: 'External Reference', visible: true },
        { header: 'Trade Date', visible: true },
        { header: 'Client', visible: true },
        { header: 'Bank', visible: true },
        { header: 'Trade Type', visible: true },
        { header: 'Currency Pair', visible: true },
        { header: 'IM/EX', visible: true },
        { header: 'ZAR', visible: true },
        { header: 'Maturity Date', visible: true },
        { header: 'Bank Rate', visible: true },
        { header: 'Deal Rate', visible: true },
        { header: 'Margin', visible: true },
        { header: 'Billed to Bank', visible: true },
        { header: 'Client Fee', visible: true },
        { header: 'Admin Fee', visible: true },
    ];

    const clientColConfig = [
        { header: 'External Reference', visible: true },
        { header: 'Trade Date', visible: true },
        { header: 'Bank', visible: true },
        { header: 'Trade Type', visible: true },
        { header: 'Currency Pair', visible: true },
        { header: 'IM/EX', visible: true },
        { header: 'ZAR', visible: true },
        { header: 'Maturity Date', visible: true },
        { header: 'Bank Rate', visible: true },
        { header: 'Deal Rate', visible: true },
        { header: 'Margin', visible: true },
        { header: 'Billed to Bank', visible: true },
        { header: 'Client Fee', visible: true },
        { header: 'Admin Fee', visible: true },
    ];

    const findTransactionalRevenue = async (_criteriaTrade?: Criteria, _criteriaOption?: Criteria, _query?: Query) => {
        setLoading(true);
        const newQuery = objectCopy(_query) || objectCopy(query);
        newQuery.limit = newQuery.limit ? newQuery.limit : undefined;

        if (_criteriaTrade && _criteriaTrade.length == 0) {
            _criteriaTrade = criteriaTrade;
        }
        if (_criteriaOption && _criteriaOption.length == 0) {
            _criteriaOption = criteriaOption;
        }

        try {
            const result = await findRevenue({
                criteriaTrade: _criteriaTrade,
                criteriaOption: _criteriaOption,
                query: newQuery,
            });
            setRevenueResponse(result.records);
            setRevenueTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const downloadTransactionalRevenue = async (
        _criteriaTrade?: Criteria,
        _criteriaOption?: Criteria,
        _query?: Query,
    ) => {
        setLoading(true);
        try {
            const downloadResponse = await DownloadRevenue({
                criteriaTrade: _criteriaTrade || criteriaTrade,
                criteriaOption: _criteriaOption || criteriaOption,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Transactional Revenue Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findTransactionalRevenue(criteriaTrade, criteriaOption, newQuery).finally();
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['currencyPair', 'maturityDate'],
            order: ['asc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findTransactionalRevenue(criteriaTrade, criteriaOption, newQuery).finally();
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findTransactionalRevenue(criteriaTrade, criteriaOption, newQuery).finally();
    };

    const handleFilterChange = useCallback(
        debounce((newCriteria: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };

            let newTradeCriteria = [] as Criterion[];
            let newOptionCriteria = [] as Criterion[];
            let tmpCriteria: Criterion;

            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );

            if (newCriteria.length > 0) {
                newCriteria.forEach((crit: Criterion) => {
                    switch (crit.field) {
                        case 'date':
                            tmpCriteria = objectCopy(crit);
                            tmpCriteria.field = 'tradeDate';
                            newTradeCriteria.push(tmpCriteria);
                            newOptionCriteria.push(tmpCriteria);
                            break;
                        case 'maturityDate':
                            tmpCriteria = objectCopy(crit);
                            tmpCriteria.field = 'deliveryDate';
                            newTradeCriteria.push(crit);
                            newOptionCriteria.push(tmpCriteria);
                            break;
                        case 'dealRate':
                            tmpCriteria = objectCopy(crit);
                            tmpCriteria.field = 'strikePrice';
                            newTradeCriteria.push(crit);
                            newOptionCriteria.push(tmpCriteria);
                            break;
                        default:
                            newTradeCriteria.push(crit);
                            newOptionCriteria.push(crit);
                    }
                });
                const finYearCrit = newCriteria.filter((f: Criterion) => f.field === 'financialYear');
                if (finYearCrit.length === 0) {
                    newTradeCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: 'CURRENT',
                    });
                    newOptionCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: 'CURRENT',
                    });
                } else if (allCriteria.length > 0) {
                    if (financialYears[0] === 'ALL') {
                        newTradeCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                        newOptionCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                    }
                }
            } else {
                newTradeCriteria = defaultCriteria;
                newOptionCriteria = defaultCriteria;
            }
            if (newCriteria) {
                setSelected(undefined);
                setCriteriaTrade(newTradeCriteria);
                setCriteriaOption(newOptionCriteria);
                setQuery(newQuery);
                findTransactionalRevenue(newTradeCriteria, newOptionCriteria, newQuery).finally();
            }
        }, 100),
        [query],
    );

    const handleSelectRow = (rowData: Revenue) => {
        const newSelected = { ...rowData };
        if (newSelected.id === selected?.id) {
            setSelected(undefined);
        } else {
            setSelected(newSelected);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
    };

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const generateClientOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findClient({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateProcessingOrgOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingOrg({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateBankOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingBank({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const openDetailDialog = async (selectedRev: Revenue) => {
        if (selectedRev.type === 'PUT' || selectedRev.type === 'CALL') {
            try {
                const result = await findOption({
                    criteria: [
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'id',
                            text: selectedRev.id,
                        },
                    ],
                });
                if (result.total === 1) {
                    setOption(result.records[0]);
                    setViewOption(true);
                }
            } catch (e) {
                setErrorMessage(e);
            }
        } else {
            try {
                const result = await findTrades({
                    criteria: [
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'id',
                            text: selectedRev.id,
                        },
                    ],
                });
                if (result.total === 1) {
                    setTrade(result.records[0]);
                    setViewTrade(true);
                }
            } catch (e) {
                setErrorMessage(e);
            }
        }
    };

    const handleCloseDialog = async () => {
        setViewOption(false);
        setViewTrade(false);
        setSelected(undefined);
        await findTransactionalRevenue(criteriaTrade, criteriaOption, query);
    };

    return (
        <div id={'revenueWorkStationRoot'}>
            {viewTrade && (
                <DetailDialog
                    closeDialog={handleCloseDialog}
                    open={viewTrade}
                    trade={trade}
                    amendSuccess={() => undefined}
                />
            )}
            {viewOption && (
                <OptionDetailDialog
                    closeDialog={handleCloseDialog}
                    open={viewOption}
                    option={option}
                    amendSuccess={() => undefined}
                />
            )}
            <StandardCard
                cardHeaderProps={{
                    tailoredState: selected ? STATES.SELECTED_ROW : undefined,
                    itemsLeft: [
                        {
                            id: 'revenue/revenue-title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Transactional Revenue',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/view',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View',
                            onClick: () => openDetailDialog(selected ? selected : {}),
                            hide: !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/show_filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter Transactional Revenue',
                            onClick: () => {
                                setShowFilterRow(!showFilterRow);
                                handleFilterChange([]);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Transactional Revenue',
                            onClick: () => downloadTransactionalRevenue(criteriaTrade, criteriaOption, query),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'revenue/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setColConfigOpen(true),
                        },
                    ],
                }}
            >
                <Table
                    tableID={'revenue-table'}
                    title={'Trades'}
                    columns={[
                        {
                            title: 'External Reference',
                            field: 'externalReference',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.externalReference) {
                                    return rowData.externalReference;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Trade Date',
                            field: 'tradeDate',
                            filter: { type: CriteriaType.TimeCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.tradeDate) {
                                    return moment(rowData.tradeDate).format(SystemDateFormat);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Client',
                            field: 'tradingPartyCode',
                            filter: {
                                asyncOptionsFetcher: generateClientOptions,
                                displayAccessor: 'name',
                                valueAccessor: 'partyCode',
                                type: CriteriaType.TextCriterion,
                            },
                            render: (rowData: Revenue) => {
                                if (rowData.tradingPartyCode) {
                                    return rowData.clientName;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Processing Org',
                            field: 'processingOrgPartyCode',
                            filter: {
                                asyncOptionsFetcher: generateProcessingOrgOptions,
                                displayAccessor: 'name',
                                valueAccessor: 'partyCode',
                                type: CriteriaType.TextCriterion,
                            },
                            render: (rowData: Revenue) => {
                                if (rowData.processingOrgPartyCode) {
                                    return rowData.processingOrgName;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Bank',
                            field: 'bank',
                            filter: {
                                asyncOptionsFetcher: generateBankOptions,
                                displayAccessor: 'name',
                                valueAccessor: 'partyCode',
                                type: CriteriaType.TextCriterion,
                            },
                            render: (rowData: Revenue) => {
                                if (rowData.bank) {
                                    return rowData.bankName;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Trade Type',
                            field: 'type',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.type) {
                                    return rowData.type;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Currency Pair',
                            field: 'currencyPair',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.currencyPair) {
                                    return rowData.currencyPair;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'IM/EX',
                            field: 'importExport',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.importExport) {
                                    return rowData.importExport;
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'ZAR',
                            field: 'quoteAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.quoteAmount) {
                                    return displayAmount(rowData.quoteAmount);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Maturity Date',
                            field: 'maturityDate',
                            filter: { type: CriteriaType.TimeCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.maturityDate) {
                                    return moment(rowData.maturityDate).format(SystemDateFormat);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Bank Rate',
                            field: 'bankRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.bankRate) {
                                    return rowData.bankRate.toFixed(4);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Deal Rate',
                            field: 'dealRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.dealRate) {
                                    return rowData.dealRate.toFixed(4);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Margin',
                            field: 'intermediaryMargin.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.intermediaryMargin) {
                                    return rowData.intermediaryMargin.toFixed(4);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Billed to Bank',
                            field: 'billedToBank.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.billedToBank) {
                                    return rowData.billedToBank.toFixed(2);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Client Fee',
                            field: 'clientFee.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.clientFee) {
                                    return rowData.clientFee.toFixed(2);
                                } else {
                                    return '-';
                                }
                            },
                        },
                        {
                            title: 'Admin Fee',
                            field: 'adminFee.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (rowData: Revenue) => {
                                if (rowData.adminFee) {
                                    return rowData.adminFee.toFixed(2);
                                } else {
                                    return '-';
                                }
                            },
                        },
                    ]}
                    defaultColConfig={
                        appContext.currentContext?.partyType === 'SYSTEM'
                            ? systemColConfig
                            : appContext.currentContext?.partyType === 'PROCESSING_ORG'
                            ? processingOrgColConfig
                            : clientColConfig
                    }
                    loading={loading}
                    count={revenueTotal}
                    data={revenueResponse || []}
                    showCheckboxes
                    showFilterRow={showFilterRow}
                    colConfigCloseFromCard={handleCloseColConfig}
                    colConfigOpenFromCard={colConfigOpen}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    onChangeSorting={handleChangeSorting}
                    onFilterChange={handleFilterChange}
                    onRowCheck={handleSelectRow}
                    rowClickAction={handleSelectRow}
                    rowDoubleClickAction={(rowData: Revenue) => {
                        openDetailDialog(rowData).then();
                    }}
                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                    rowsPerPage={query.limit}
                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                    onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                    selected={selected ? [selected] : []}
                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                />
            </StandardCard>
            <NotificationSweetAlert errorMessage={errorMessage} onClose={handleHideAlert} />
        </div>
    );
};
