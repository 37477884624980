import React, { ChangeEvent, cloneElement, Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Card, FormControl, MenuItem } from '@material-ui/core';
import { LightNumberField, LightSelect } from './styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getMidDay } from 'utils';
import { formatISO, isBefore, isValid, isWeekend, parseISO } from 'date-fns';
import { LightDatePicker } from 'components/Option/Tickets/styledComponents';
import { ProcessingBank } from 'popcorn-js/party';
import { AppContext, AppContextT } from 'context';
import Big from 'big.js';

export const OptionDetails = ({
    expiryDate,
    tradeDate,
    bank,
    strikePrice,
    deliveryDate,
    premium,
    disableEditMaturityDate,
    setExpiryDate,
    setDeliveryDate,
    setTradeDate,
    handleTradeDateChange,
    handleBankChange,
    handleExpiryDateChange,
    handleDeliveryDateChange,
    handlePremiumChange,
    nonTradingDays,
}: {
    expiryDate: Date | null | undefined;
    tradeDate: Date | null | undefined;
    bank: ProcessingBank | undefined;
    strikePrice: Big | undefined;
    deliveryDate: Date | null | undefined;
    premium: Big | undefined;
    disableEditMaturityDate: boolean | undefined;
    setExpiryDate: Dispatch<SetStateAction<Date | null | undefined>>;
    setDeliveryDate: Dispatch<SetStateAction<Date | null | undefined>>;
    setTradeDate: Dispatch<SetStateAction<Date | null | undefined>>;
    handleTradeDateChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<Date | null | undefined>>>;
    handleBankChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<ProcessingBank | undefined>>>;
    handleExpiryDateChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<Date | null | undefined>>>;
    handleDeliveryDateChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<Date | null | undefined>>>;
    handlePremiumChange: _.DebouncedFunc<React.Dispatch<React.SetStateAction<Big | undefined>>>;
    nonTradingDays: Date[];
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Trade Date'}
                        error={!tradeDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                    handleTradeDateChange(null);
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                setTradeDate(newTradeDate);
                                if (expiryDate) {
                                    const currentMaturityDate = getMidDay(expiryDate);
                                    if (isBefore(currentMaturityDate, newTradeDate)) {
                                        handleTradeDateChange(newTradeDate);
                                    }
                                }
                            } else {
                                setTradeDate(null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={tradeDate}
                        inputValue={tradeDate ? formatISO(tradeDate, { representation: 'date' }) : undefined}
                    />
                    <LightDatePicker
                        disabled={disableEditMaturityDate}
                        format={'yyyy-MM-dd'}
                        label={'Expiry Date'}
                        error={!expiryDate}
                        onChange={(date: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && tradeDate && isValid(parseISO(value))) {
                                const tradeDateStart = getMidDay(tradeDate);
                                const newExpiryTime = getMidDay(date as Date);
                                if (isBefore(newExpiryTime, tradeDateStart) || isWeekend(newExpiryTime)) {
                                    handleExpiryDateChange(null);
                                    return;
                                }
                                handleExpiryDateChange(newExpiryTime);
                            } else {
                                setExpiryDate(null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || (tradeDate && isBefore(date, tradeDate))) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={expiryDate}
                        inputValue={expiryDate ? formatISO(expiryDate, { representation: 'date' }) : undefined}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Bank'}
                            error={!bank}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) =>
                                handleBankChange(
                                    appContext.processingBanks?.find(
                                        (bank: ProcessingBank) => bank.id === event.target.value,
                                    ),
                                )
                            }
                            style={{ width: '100%' }}
                            value={bank ? bank.id : ''}
                        >
                            {appContext.processingBanks?.map((bank: ProcessingBank) => (
                                <MenuItem key={bank.partyCode} value={bank.id}>
                                    {bank.name}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Delivery Date'}
                        error={!deliveryDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                    handleDeliveryDateChange(null);
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                handleDeliveryDateChange(newTradeDate);
                            } else {
                                setDeliveryDate(null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={deliveryDate}
                        inputValue={deliveryDate ? formatISO(deliveryDate, { representation: 'date' }) : undefined}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Strike Price'}
                        value={strikePrice ? strikePrice.toFixed(6) : ''}
                        disabled
                    />
                    <LightNumberField
                        label={'Premium'}
                        error={!premium}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handlePremiumChange(event.target.value ? Big(event.target.value) : undefined)
                        }
                        value={premium ? premium.toFixed(6) : ''}
                    />
                </div>
            </div>
        </Card>
    );
};

const isHoliday = (d: Date, nonTradingDays: Date[]): boolean => {
    for (const ntd of nonTradingDays) {
        if (ntd.getDate() === d.getDate() && ntd.getMonth() === d.getMonth() && ntd.getFullYear() === d.getFullYear()) {
            return true;
        }
    }
    return false;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
