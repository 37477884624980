import React, { ReactElement } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import loaderGIF from 'assets/img/loader.gif';

export const FullPageLoader = (props: {
    errorMessage?: string;
    onDismiss?: () => void;
    modal?: boolean;
}): ReactElement => {
    const { errorMessage, onDismiss, modal } = props;

    const classes = useStyles();

    return (
        <>
            {modal && (
                <Modal open={true}>
                    <div className={classes.primaryContainer}>
                        <div className={classes.logoContainer}>
                            <NotificationSweetAlert
                                errorMessage={errorMessage}
                                onDismiss={() => onDismiss && onDismiss()}
                            />
                        </div>
                        <div className={classes.logoContainer}>
                            <img width="96px" height="96px" src={loaderGIF} />
                        </div>
                    </div>
                </Modal>
            )}
            {!modal && (
                <div className={classes.primaryContainer}>
                    <div className={classes.logoContainer}>
                        <NotificationSweetAlert
                            errorMessage={errorMessage}
                            onDismiss={() => onDismiss && onDismiss()}
                        />
                    </div>
                    <div className={classes.logoContainer}>
                        <img width="96px" height="96px" src={loaderGIF} />
                    </div>
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles(() => ({
    primaryContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    logoContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        color: '#000',
    },
    spinnerContainer: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        borderRadius: '50%',
    },
    logo: {
        width: '30px',
        height: '36px',
    },
    spinner: {
        width: '64px',
        height: '64px',
        margin: '0 auto',
    },
}));
