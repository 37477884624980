import { Currency } from 'popcorn-js/currency';
import { ProcessingBank } from 'popcorn-js/party';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import _ from 'lodash';
import { v4 } from 'uuid';
import { getMidDay, getNextTradeDate } from 'utils';
import { isWeekend } from 'date-fns';
import Big from 'big.js';
import { OptionDirection, OptionRecordInput, OptionType } from 'popcorn-js/options';
import { CustomTheme } from 'theme/custom';
import { ImportExport } from 'popcorn-js/tradeV2';

export const addOptionT = (
    options: OptionValues[],
    setOptions: (t: OptionValues[]) => void,
    setExpanded: (e?: string) => void,
    currencies?: Currency[],
    processingBank?: ProcessingBank,
    currencyPair?: CurrencyPair,
    direction?: OptionDirection,
    type?: OptionType,
) => (): void => {
    const _options = _.cloneDeep(options);
    const _uuid = v4();
    const today = getMidDay(new Date());
    const putAmount = Big(0);
    const callAmount = Big(0);
    const importExport = calcDefaultImportExport(type, direction);

    _options.push({
        uuid: _uuid,
        bank: processingBank,
        putAmount,
        callAmount,
        direction,
        type,
        importExport,
        currencyPair,
        tradeDate: isWeekend(today) ? getNextTradeDate(today) : today,
        expiryDate: isWeekend(today) ? getNextTradeDate(today) : today,
    } as OptionValues);

    setOptions(_options);
    setExpanded(_uuid);
};

export type OptionValues = {
    uuid?: string;
    externalReference?: string;
    number?: string;
    notes?: string;
    currencyPair?: CurrencyPair;
    premium?: Big;
    expiryDate?: Date;
    deliveryDate?: Date;
    strikePrice?: Big;
    tradeDate?: Date;
    type?: OptionType;
    direction?: OptionDirection;
    importExport?: ImportExport;
    bank?: ProcessingBank;
    interbankRate?: Big;
    bankRate?: Big;
    notionalAmount?: Big;
    quoteAmount?: Big;
};

export const calcDefaultImportExport = (type?: OptionType, direction?: OptionDirection): ImportExport => {
    switch (true) {
        case direction == OptionDirection.BUY && type == OptionType.PUT:
            return ImportExport.EXPORT;
        case direction == OptionDirection.SELL && type == OptionType.PUT:
            return ImportExport.IMPORT;
        case direction == OptionDirection.BUY && type == OptionType.CALL:
            return ImportExport.IMPORT;
        case direction == OptionDirection.SELL && type == OptionType.CALL:
            return ImportExport.EXPORT;
        default:
            return ImportExport.IMPORT;
    }
};

export const optionValid = (t: OptionValues): boolean => {
    return !!(
        t.externalReference &&
        t.currencyPair &&
        t.premium &&
        t.bank &&
        t.tradeDate &&
        t.direction &&
        t.expiryDate &&
        t.strikePrice &&
        t.type &&
        t.importExport &&
        t.notionalAmount &&
        t.deliveryDate
    );
};

export const mapOption = ({
    optionValues,
    trader,
    traderOrganisation,
    capturedSpotRate,
}: {
    optionValues: OptionValues;
    parentPartyCode?: string;
    trader?: string;
    traderOrganisation?: string;
    spotPrice?: Big;
    capturedSpotRate?: Big;
}): OptionRecordInput => ({
    externalReference: optionValues.externalReference,
    number: optionValues.number,
    notionalAmount: optionValues.notionalAmount?.toNumber(),
    currencyPair: optionValues.currencyPair?.name,
    premium: optionValues.premium?.toNumber(),
    expiryDate: optionValues.expiryDate,
    deliveryDate: optionValues.deliveryDate,
    strikePrice: optionValues.strikePrice?.toNumber(),
    tradeDate: optionValues.tradeDate,
    type: optionValues.type,
    direction: optionValues.direction,
    importExport: optionValues.importExport,
    bank: optionValues.bank?.partyCode,
    interbankRate: optionValues.interbankRate?.toNumber(),
    bankRate: optionValues.bankRate?.toNumber(),
    trader: trader,
    traderOrganisation: traderOrganisation,
    notes: optionValues.notes,
    capturedSpotRate: capturedSpotRate?.toNumber(),
});

export const determineSellColor = (theme: CustomTheme, type?: OptionType, direction?: OptionDirection): string => {
    if (type === OptionType.CALL && direction === OptionDirection.BUY) {
        return theme.palette.custom.import.main;
    }
    if (type === OptionType.CALL && direction === OptionDirection.SELL) {
        return theme.palette.custom.export.main;
    }
    return theme.palette.custom.icons.main;
};

export const determineBuyColor = (theme: CustomTheme, type?: OptionType, direction?: OptionDirection): string => {
    if (type === OptionType.PUT && direction === OptionDirection.BUY) {
        return theme.palette.custom.export.main;
    }
    if (type === OptionType.PUT && direction === OptionDirection.SELL) {
        return theme.palette.custom.import.main;
    }
    return theme.palette.custom.icons.main;
};

export const getPutCurrency = (
    type?: OptionType,
    currencyPair?: CurrencyPair,
    currencies?: Currency[],
): Currency | undefined => {
    return type === OptionType.PUT
        ? currencies?.find((currency: Currency) => currencyPair?.baseCurrency === currency.isoCode)
        : currencies?.find((currency: Currency) => currencyPair?.quoteCurrency === currency.isoCode);
};

export const getCallCurrency = (
    type?: OptionType,
    currencyPair?: CurrencyPair,
    currencies?: Currency[],
): Currency | undefined => {
    return type === OptionType.CALL
        ? currencies?.find((currency: Currency) => currencyPair?.baseCurrency === currency.isoCode)
        : currencies?.find((currency: Currency) => currencyPair?.quoteCurrency === currency.isoCode);
};

export const calculateCallAmount = (strikePrice?: Big, notionalAmount?: Big, type?: OptionType): Big | undefined => {
    switch (type) {
        case OptionType.CALL:
            return notionalAmount;
        case OptionType.PUT:
            return notionalAmount?.mul(strikePrice || new Big(0));
        default:
            return new Big(0);
    }
};

export const calculatePutAmount = (strikePrice?: Big, notionalAmount?: Big, type?: OptionType): Big | undefined => {
    switch (type) {
        case OptionType.CALL:
            return notionalAmount?.mul(strikePrice || new Big(0));
        case OptionType.PUT:
            return notionalAmount;
        default:
            return new Big(0);
    }
};
