import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import HedgeMaturingTable from './HedgeMaturingTable';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    bottomColumnLayout: {
        display: 'grid',
        gridTemplateColumns: '294px 580px 390px',
    },
}));

const ConsolidatedHedgeManagementStation = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div
                id="consolidatedHedgeStationRoot"
                style={{
                    margin: '0 auto',
                }}
            >
                <Grid
                    item
                    style={{
                        width: '0 auto',
                        margin: '0 auto',
                    }}
                >
                    <HedgeMaturingTable />
                </Grid>
            </div>
        </div>
    );
};

export default ConsolidatedHedgeManagementStation;
