/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React, { useEffect, useState, ReactElement } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    Step,
    StepButton,
    Stepper,
    Tooltip,
    makeStyles,
} from '@material-ui/core';
import { isObject, isString } from 'utils';
import { Close as CloseIcon, ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Table from 'components/Table/Table';
import logoImg from 'assets/img/stellcap-logo-mini.svg';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/order/recordkeeper';
import { Handler as OrderHandler } from 'popcorn-js/order/handler';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import Fab from '@material-ui/core/Fab';
import styles from './styles';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Currency } from 'popcorn-js/currency';
import { Counterparty } from 'popcorn-js/counterparty';
import { Invoice } from 'popcorn-js/invoice';

const useStyles = makeStyles(styles as any);

const processingStates: any = {
    orderBatchValidation: 'Validating Orders',
    orderRestore: 'Restoring Orders',
    counterpartyCreation: 'Creating Counterparties',
    uploadNewOrders: 'Uploading New Orders',
    uploadUpdatedOrders: 'Uploading Updated Orders',
};
const errorStates: any = {
    processFileError: 'Error processing file',
    batchValidationError: 'Error validating batch of orders',
    noRecordsToUpload: 'There are no new records to upload',
    counterpartyCreation: 'Error creating counterparties',
    buildingTable: 'Error building table',
    uploadNewError: 'Error creating new orders',
    uploadUpdatedError: 'Error updating orders',
};
const states: any = {
    'Counterparty Creation': {
        orderBatchValidation: processingStates.orderBatchValidation,
        batchValidationError: errorStates.batchValidationError,
        showMissingCounterparties: 0,
        counterpartyCreationInProgress: processingStates.counterpartyCreation,
        counterpartyCreationError: errorStates.counterpartyCreation,
        counterpartyCreationSuccess: 1,
    },
    'Resolve Invalid': {
        view: 2,
        errorBuildingTable: errorStates.buildingTable,
    },
    'Resolve Deleted': {
        view: 3,
        errorBuildingTable: errorStates.buildingTable,
    },
    'Resolve Duplicates': {
        view: 4,
        errorBuildingTable: errorStates.buildingTable,
    },
    'Confirm Upload': {
        view: 5,
        uploadConfirmed: 6,
        errorBuildingTable: errorStates.buildingTable,
        uploadNew: processingStates.uploadNewOrders,
        uploadUpdated: processingStates.uploadUpdatedOrders,
        uploadNewError: errorStates.uploadNewError,
        uploadUpdatedError: errorStates.uploadUpdatedError,
    },
    Finish: {
        view: 7,
        noRecordsToUploadError: errorStates.noRecordsToUpload,
    },
};
const events: any = {
    // Initialise to batch validation in progress
    init: states['Counterparty Creation'].orderBatchValidation,

    // "Counterparty Creation" Step Events
    batchValidationError: states['Counterparty Creation'].batchValidationError,
    batchValidationSuccessWithMissingCounterparties: states['Counterparty Creation'].showMissingCounterparties,
    batchValidationSuccessWithInvalid: states['Resolve Invalid'].view,
    batchValidationSuccessWithDuplicates: states['Resolve Duplicates'].view,
    batchValidationSuccessWithDeleted: states['Resolve Deleted'].view,
    batchValidationSuccessNoRecordsToUpload: states['Finish'].noRecordsToUploadError,
    batchValidationSuccess: states['Confirm Upload'].view,
    createAllCounterparties: states['Counterparty Creation'].counterpartyCreationInProgress,
    createAllCounterpartiesError: states['Counterparty Creation'].counterpartyCreationError,
    createAllCounterpartiesSuccess: states['Counterparty Creation'].counterpartyCreationSuccess,

    // "Resolve Duplicates" Step Events
    errorBuildingInvalidOrdersTable: states['Resolve Duplicates'].errorBuildingTable,
    noDuplicatesToUpdate: states['Finish'].noRecordsToUploadError,

    // "Resolve Deleted" Step Events
    errorBuildingResolveDeletedOrdersTable: states['Resolve Deleted'].errorBuildingTable,

    // "Invalid Orders" Step Events
    errorBuildingResolveDuplicateOrdersTable: states['Resolve Invalid'].errorBuildingTable,
    finishDuplicateResolution: states['Confirm Upload'].view,
    finishDeletedResolution: states['Resolve Duplicates'].view,

    // "Confirm Upload" Step Events
    errorBuildingConfirmUploadOrdersTable: states['Confirm Upload'].errorBuildingTable,
    confirmUpload: states['Confirm Upload'].uploadConfirmed,
    uploadNew: states['Confirm Upload'].uploadNew,
    uploadUpdated: states['Confirm Upload'].uploadUpdated,
    errorUploadingNew: states['Confirm Upload'].uploadNewError,
    errorUploadingUpdated: states['Confirm Upload'].uploadUpdatedError,
    uploadingComplete: states['Finish'].view,
};
const steps: any = Object.keys(states);

// common utility functions
const getStep = (activeState: any) => {
    for (let stepIdx = 0; stepIdx < steps.length; stepIdx++) {
        const stepStates = states[steps[stepIdx]];
        if (isObject(stepStates)) {
            for (const stepState in stepStates) {
                if (stepStates[stepState] === activeState) {
                    return steps[stepIdx];
                }
            }
        } else if (stepStates === activeState) {
            return steps[stepIdx];
        }
    }
};
const stepComplete = (stepLabel: any, activeStep: any) => {
    return steps.indexOf(activeStep) > steps.indexOf(stepLabel);
};

/**
 * A stepped dialog that facilitates the uploading of orders.
 */
function OrderUploadDialog(props: {
    onAwayClick: any;
    ordersValidated: any;
    uploadSuccess: any;
    validateBatchOrder: any;
    newOrders: any;
    orderCounterparties: any;
    currencies: any;
    invoices: any;
    show: any;
    category: any;
    previewTableColumns: any;
}): ReactElement {
    const {
        onAwayClick,
        ordersValidated,
        uploadSuccess,
        validateBatchOrder,
        newOrders,
        currencies,
        invoices,
        show,
        category,
        previewTableColumns,
        orderCounterparties,
    } = props;

    const classes = useStyles();

    const [activeState, setActiveState] = useState<any>(events.init);
    const [errors, setErrors] = useState<any>({});
    const [newToUpload, setNewToUpload] = useState<any[]>([]);
    const [existingToUpload, setExistingToUpload] = useState<any[]>([] as any[]);
    const [ordersCreateBatch, setOrdersCreateBatch] = useState<any>({
        failed: 0,
        succeeded: 0,
        orderReasonsFailed: [],
        inProgress: false,
        success: false,
        error: undefined,
    });
    const [ordersUpdateBatch, setOrdersUpdateBatch] = useState<any>({
        failed: 0,
        succeeded: 0,
        orderReasonsFailed: [],
        inProgress: false,
        success: false,
        error: undefined,
    });
    const [counterpartiesCreateBatch, setCounterpartiesCreateBatch] = useState<any>({
        inProgress: false,
        error: undefined,
        failed: 0,
        succeeded: 0,
        success: false,
    });
    const [invalid, setInvalid] = useState<any>([]);
    const [duplicate, setDuplicate] = useState<any>([]);
    const [deleted, setDeleted] = useState<any>([]);
    const [, setUnique] = useState<any>([]);

    const reInit = () => {
        setErrors({});
        setNewToUpload([]);
        setExistingToUpload([]);
        setActiveState(events.init);
    };

    const closeDialog = () => {
        reInit();
        onAwayClick();
    };

    /*
     * Update state according to props
     */
    useEffect(() => {
        setUnique(ordersValidated.unique);
    }, [ordersValidated.unique]);
    useEffect(() => {
        setInvalid(ordersValidated.invalid);
    }, [ordersValidated.invalid]);
    useEffect(() => {
        setDuplicate(ordersValidated.duplicate);
    }, [ordersValidated.duplicate]);
    useEffect(() => {
        setDeleted(ordersValidated.deleted);
    }, [ordersValidated.deleted]);
    useEffect(() => {
        if (getStep(activeState) === 'Counterparty Creation') {
            // Check if we are currently waiting for order batch validation, or re-validation
            if (activeState === states['Counterparty Creation'].orderBatchValidation) {
                if (!ordersValidated.validationInProgress) {
                    // Then...
                    // If an upload error exists
                    if (ordersValidated.uploadError) {
                        setErrors({
                            ...errors,
                            [events.batchValidationError]: ordersValidated.uploadError,
                        });
                        // change the state with batch validation error event
                        setActiveState(events.batchValidationError);
                    } else {
                        // Otherwise the validation was successful
                        // Set state appropriately depending on the contents of:
                        //  -missingCounterparties
                        //  -invalid
                        //  -duplicates
                        //  -unique
                        if (ordersValidated.missingCounterparties.length) {
                            setActiveState(events.batchValidationSuccessWithMissingCounterparties);
                        } else if (ordersValidated.invalid.length) {
                            setActiveState(events.batchValidationSuccessWithInvalid);
                        } else if (ordersValidated.deleted.length) {
                            setNewToUpload([...ordersValidated.unique]);
                            setActiveState(events.batchValidationSuccessWithDeleted);
                        } else if (ordersValidated.duplicate.length) {
                            setNewToUpload([...ordersValidated.unique]);
                            setActiveState(events.batchValidationSuccessWithDuplicates);
                        } else if (ordersValidated.unique.length) {
                            setNewToUpload([...ordersValidated.unique]);
                            setActiveState(events.batchValidationSuccess);
                        } else {
                            setErrors({
                                ...errors,
                                [events.batchValidationSuccessNoRecordsToUpload]: '',
                            });
                            setActiveState(events.batchValidationSuccessNoRecordsToUpload);
                        }
                    }
                } else {
                    // Validation is still in progress
                }
            }
        }
    }, [
        activeState,
        errors,
        ordersValidated.deleted,
        ordersValidated.duplicate,
        ordersValidated.invalid,
        ordersValidated.unique,
        ordersValidated.validationInProgress,
        ordersValidated.missingCounterparties,
        ordersValidated.uploadError,
    ]);
    // convenience functions for better readability in the ensuing useEffect hook declaration
    const startCreate = () => {
        setOrdersCreateBatch({
            inProgress: true,
            error: undefined,
            orderReasonsFailed: [],
            failed: 0,
            succeeded: 0,
            success: false,
        });
    };
    const createSuccess = (result: any) => {
        setOrdersCreateBatch({
            inProgress: false,
            error: undefined,
            orderReasonsFailed: result.failed,
            failed: result.failed ? result.failed.length : 0,
            succeeded: result.succeeded ? result.succeeded.length : 0,
            success: true,
        });
    };
    useEffect(() => {
        const createError = (error: any) => {
            setOrdersCreateBatch({
                inProgress: false,
                error: error,
                failed: 0,
                orderReasonsFailed: [],
                succeeded: 0,
                success: false,
            });
            setErrors({
                ...errors,
                [events.errorUploadingNew]: error,
            });
        };
        const startUpdate = () => {
            setOrdersUpdateBatch({
                inProgress: true,
                error: undefined,
                failed: 0,
                orderReasonsFailed: [],
                succeeded: 0,
                success: false,
            });
        };
        const updateSuccess = (result: any) => {
            setOrdersUpdateBatch({
                inProgress: false,
                error: undefined,
                orderReasonsFailed: [],
                failed: result.failed ? result.failed.length : 0,
                succeeded: result.succeeded ? result.succeeded.length : 0,
                success: true,
            });
        };
        const updateError = (error: any) => {
            setOrdersUpdateBatch({
                inProgress: false,
                error: error,
                failed: 0,
                orderReasonsFailed: [],
                succeeded: 0,
                success: false,
            });
            setErrors({
                ...errors,
                [events.errorUploadingUpdated]: error,
            });
        };
        if (getStep(activeState) === 'Confirm Upload') {
            // If We are, check if upload has been cosnfirmed
            if (activeState === states['Confirm Upload'].uploadConfirmed) {
                // Check if there are New Orders to upload
                const updates: any[] = existingToUpload.map((inv: any) => {
                    return {
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: inv.id },
                        order: inv,
                    };
                });
                if (newToUpload.length) {
                    startCreate();
                    // Upload new orders
                    setActiveState(events.uploadNew);
                    OrderHandler.CreateBatch({ orders: newToUpload })
                        .then((result) => {
                            createSuccess(result);
                            if (existingToUpload.length) {
                                startUpdate();
                                OrderHandler.UpdateBatch({ updates: updates })
                                    .then((result) => {
                                        uploadSuccess();
                                        updateSuccess(result);
                                        setActiveState(events.uploadingComplete);
                                    })
                                    .catch((error) => {
                                        updateError(error);
                                        setActiveState(events.errorUploadingUpdated);
                                    });
                                setActiveState(events.uploadUpdated);
                            } else {
                                uploadSuccess();
                                // There is nothing else to do, show Finish
                                setActiveState(events.uploadingComplete);
                            }
                        })
                        .catch((error) => {
                            createError(error);
                            setActiveState(events.errorUploadingNew);
                        });
                    // Or if there are Existing Orders to upload
                } else if (existingToUpload.length) {
                    startUpdate();
                    OrderHandler.UpdateBatch({ updates: updates })
                        .then((result) => {
                            updateSuccess(result);
                            uploadSuccess();
                            setActiveState(events.uploadingComplete);
                        })
                        .catch((error) => {
                            updateError(error);
                            setActiveState(events.errorUploadingUpdated);
                        });
                    setActiveState(events.uploadUpdated);
                }
            }
        }
    }, [activeState, newToUpload, existingToUpload, errors, uploadSuccess]);
    useEffect(() => {
        if (getStep(activeState) === 'Resolve Duplicates') {
            if (duplicate.length === 0 && existingToUpload.length > 0) {
                setActiveState(events.finishDuplicateResolution);
            } else if (duplicate.length === 0 && existingToUpload.length === 0) {
                setActiveState(events.noDuplicatesToUpdate);
            }
        }
    }, [activeState, duplicate, existingToUpload]);
    useEffect(() => {
        if (getStep(activeState) === 'Resolve Deleted') {
            if (deleted.length === 0) {
                setActiveState(events.finishDeletedResolution);
            }
        }
    }, [activeState, deleted, existingToUpload]);

    // resolution handlers
    const resolveDuplicateExclude = (selectedOrder: any) => {
        // Remove it from the list of duplicate orders
        const duplicateNew = duplicate.filter((order: any) => {
            return !(
                order.number === selectedOrder.number &&
                order.partyCode === selectedOrder.partyCode &&
                order.counterparty === selectedOrder.counterparty
            );
        });
        setDuplicate(duplicateNew);
    };
    const resolveDuplicateUpdate = (selectedOrder: any) => {
        // If the selected order is the new one add it to
        // the list of existingToUpload (i.e. for update)
        const _existingToUpload = [...existingToUpload];
        if (selectedOrder && !selectedOrder.isNew) {
            _existingToUpload.push(selectedOrder);
        }
        // Remove it from the list of duplicates
        const duplicateNew = duplicate.filter((order: any) => {
            return !(
                order.number === selectedOrder.number &&
                order.partyCode === selectedOrder.partyCode &&
                order.counterparty === selectedOrder.counterparty
            );
        });
        // Update existingToUpload piece of state
        setExistingToUpload(_existingToUpload);
        setDuplicate(duplicateNew);
    };
    const updateAllDuplicates = () => {
        // Select all new
        const _existingToUpload = [...existingToUpload];
        duplicate.forEach((order: any) => {
            if (order.isNew) {
                _existingToUpload.push(order);
            }
        });
        // Update existingToUpload piece of state
        setExistingToUpload(_existingToUpload);
        // Clear duplicates
        setDuplicate([]);
    };
    const resolveDeletedRestore = (selectedOrder: any) => {
        // restore the order and then pass it on to the duplicate list
        const duplicateNew = duplicate.slice();
        OrderRecordkeeper.restore({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selectedOrder.id } })
            .then(() => {
                duplicateNew.push(selectedOrder);
                // remove it from the list of deleted orders
                const deletedNew = deleted.filter((order: any) => {
                    return !(
                        order.number === selectedOrder.number &&
                        order.partyCode === selectedOrder.partyCode &&
                        order.counterparty === selectedOrder.counterparty
                    );
                });
                setDeleted(deletedNew);
                setDuplicate(duplicateNew);
            })
            .catch((e) => {
                console.error('could not restore order:', e);
            });
    };
    const resolveDeletedExclude = (selectedOrder: any) => {
        // Remove it from the list of deleted orders
        const deletedNew = deleted.filter((order: any) => {
            return !(
                order.number === selectedOrder.number &&
                order.partyCode === selectedOrder.partyCode &&
                order.counterparty === selectedOrder.counterparty
            );
        });
        // Update existingToUpload piece of state
        setDeleted(deletedNew);
    };
    const restoreAllDeleted = () => {
        // Select all new
        const duplicateNew = duplicate.slice();
        deleted.forEach((order: any) => {
            OrderRecordkeeper.restore({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id } })
                .then(() => duplicateNew.push(order))
                .catch((e) => {
                    console.error('could not restore order:', e);
                })
                .finally();
        });
        // Update existingToUpload piece of state
        setDuplicate(duplicateNew);
        // Clear deleted (assuming they were all restored)
        setDeleted([]);
    };

    // render functions
    const renderTable = () => {
        const tableData = (() => {
            switch (activeState) {
                case states['Resolve Invalid'].view:
                    return invalid;
                case states['Resolve Deleted'].view:
                    return deleted;
                case states['Resolve Duplicates'].view:
                    return duplicate;
                case states['Confirm Upload'].view:
                    return [...newToUpload, ...existingToUpload];
                default:
                    console.error(
                        `unable to determine which data to use to build table. State: ${activeState}, Step: ${getStep(
                            activeState,
                        )}`,
                    );
                    throw new Error(
                        `unable to determine which data to use to build table. State: ${activeState}, Step: ${getStep(
                            activeState,
                        )}`,
                    );
            }
        })();

        let tableColumns = previewTableColumns.map((col: any) => {
            return {
                title: col.Header,
                field: col.accessor,
                render: (rowObj: any) => {
                    switch (col.accessor) {
                        case 'counterpartyId':
                            // todo this does not work currently - not in scope for ticket,  but needs attention...
                            return orderCounterparties.find((c: Counterparty) => c.id === rowObj.counterpartyId)?.name;
                        case 'currency':
                            return currencies.find((c: Currency) => c.isoCode === rowObj.currency)?.isoCode;
                        case 'costCurrency':
                            return currencies.find((c: Currency) => c.isoCode === rowObj.costCurrency)?.isoCode;
                        case 'invoiceExternalReference':
                            return invoices.find((i: Invoice) => i.id === rowObj.invoiceId)?.externalReference;
                        default:
                            return rowObj[col.accessor];
                    }
                },
            };
        });

        // Add additional columns
        switch (activeState) {
            case states['Resolve Invalid'].view: {
                // Determine width for reason column
                let reasonLen = 70;
                tableData.forEach((inv: any) => {
                    if (inv.reasons) {
                        inv.reasons.forEach((reason: any) => {
                            if (isString(reason.reason)) {
                                reasonLen =
                                    reasonLen > reason.reason.length * 11 ? reasonLen : reason.reason.length * 11;
                            }
                        });
                    }
                });
                reasonLen = reasonLen > 200 ? 200 : reasonLen;

                tableColumns = [
                    {
                        title: 'Number',
                        field: 'number',
                        width: 120,
                    },
                    {
                        title: 'Issues',
                        render: (invalidOrder: any) => {
                            return (
                                <div
                                    style={{
                                        display: 'grid',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                    }}
                                >
                                    <Table
                                        disableFooter
                                        columns={[
                                            { title: 'Field', field: 'field' },
                                            { title: 'Reason', field: 'reason' },
                                        ]}
                                        data={invalidOrder.reasons}
                                    />
                                </div>
                            );
                        },
                    },
                ];
                break;
            }
            case states['Resolve Duplicates'].view:
                tableColumns.unshift({
                    title: 'Action',
                    width: 250,
                    render: (rowCell: any) => {
                        return (
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    className={classes.button}
                                    onClick={() => resolveDuplicateUpdate(rowCell.original)}
                                    style={{
                                        minWidth: '90px',
                                        width: '90px',
                                        height: '28px',
                                    }}
                                >
                                    Update
                                </Button>
                                <Button
                                    className={classes.button}
                                    onClick={() => resolveDuplicateExclude(rowCell.original)}
                                    style={{
                                        minWidth: '90px',
                                        width: '90px',
                                        height: '28px',
                                    }}
                                >
                                    Exclude
                                </Button>
                            </div>
                        );
                    },
                });
                break;
            case states['Resolve Deleted'].view:
                tableColumns.unshift({
                    title: 'Actions',
                    width: 250,
                    render: (rowCell: any) => {
                        return (
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    className={classes.button}
                                    onClick={() => resolveDeletedRestore(rowCell.original)}
                                >
                                    Restore
                                </Button>
                                <Button
                                    className={classes.button}
                                    onClick={() => resolveDeletedExclude(rowCell.original)}
                                >
                                    Exclude
                                </Button>
                            </div>
                        );
                    },
                });
                break;
            default:
        }
        return <Table disableFooter columns={tableColumns} data={tableData} />;
    };
    const renderProcessing = () => {
        return (
            <div
                id="orderUploadDialogProcessing"
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    alignItems: 'center',
                    justifyItems: 'center',
                    margin: '15px',
                }}
            >
                <div>
                    <b>{activeState}</b>
                </div>
                <div>
                    <CircularProgress className={classes.progress} />
                </div>
            </div>
        );
    };
    const renderError = (outOfStateError?: any) => {
        const error = errors[activeState];

        let errorMsg = '';
        if (outOfStateError) {
            errorMsg = outOfStateError;
        } else if (isObject(error)) {
            if (error.message) {
                errorMsg = error.message;
            }
        } else if (isString(error)) {
            errorMsg = error;
        }

        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    alignItems: 'center',
                    justifyItems: 'center',
                    margin: '15px',
                }}
            >
                <div>
                    <b>{activeState}</b>
                </div>
                <div>
                    <ErrorIcon className={classes.errorIcon} />
                </div>
                <div>{errorMsg}</div>
                <div>
                    <Button className={classes.button} onClick={closeDialog}>
                        Close
                    </Button>
                </div>
            </div>
        );
    };
    const renderCounterpartyCreation = () => {
        return (
            <div id="orderUploadDialogCounterpartyCreationStepRoot" style={{ padding: 10 }}>
                <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                    {(() => {
                        switch (activeState) {
                            case states['Counterparty Creation'].showMissingCounterparties:
                                return (
                                    <React.Fragment>
                                        <Grid item>Some orders have counterparties that do not exist.</Grid>
                                        <Grid item>
                                            <Table
                                                disableFooter
                                                columns={[
                                                    {
                                                        title: 'Counterparty',
                                                        field: 'name',
                                                        width: '500',
                                                    },
                                                ]}
                                                data={ordersValidated.missingCounterparties}
                                            />
                                        </Grid>
                                        <Grid item>What would you like to do?</Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} spacing={8}>
                                                <Grid item>
                                                    <Button
                                                        className={classes.button}
                                                        id="orderUploadDialogMissingCounterpartiesCloseBtn"
                                                        onClick={closeDialog}
                                                    >
                                                        Close
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className={classes.button}
                                                        id="orderUploadDialogMissingCounterpartiesCreateAllBtn"
                                                        onClick={() => {
                                                            setActiveState(events.createAllCounterparties);
                                                            CounterpartyRecordkeeper.createBatch({
                                                                counterparties: ordersValidated.missingCounterparties,
                                                            })
                                                                .then((result) => {
                                                                    setCounterpartiesCreateBatch({
                                                                        inProgress: false,
                                                                        error: undefined,
                                                                        failed: result.failed
                                                                            ? result.failed.length
                                                                            : 0,
                                                                        succeeded: result.succeeded
                                                                            ? result.succeeded.length
                                                                            : 0,
                                                                        success: true,
                                                                    });
                                                                    setActiveState(
                                                                        events.createAllCounterpartiesSuccess,
                                                                    );
                                                                })
                                                                .catch((error) => {
                                                                    setErrors({
                                                                        ...errors,
                                                                        [events.createAllCounterpartiesError]: error,
                                                                    });
                                                                    setActiveState(events.createAllCounterpartiesError);
                                                                });
                                                        }}
                                                    >
                                                        Create All
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                );
                            case states['Counterparty Creation'].counterpartyCreationSuccess:
                                return (
                                    <React.Fragment>
                                        <Grid item>
                                            <u id="orderUploadDialogMissingCounterpartiesCreateCreateComplete">
                                                Counterparty Creation Complete
                                            </u>
                                        </Grid>
                                        <Grid id="orderUploadDialogMissingCounterpartiesSucceededCount" item>
                                            Succeeded: {counterpartiesCreateBatch.succeeded}
                                        </Grid>
                                        <Grid id="orderUploadDialogMissingCounterpartiesFailedCount" item>
                                            Failed: {counterpartiesCreateBatch.failed}
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={'row'} spacing={8}>
                                                {counterpartiesCreateBatch.failed !== 0 && (
                                                    <Grid item>
                                                        <Button
                                                            className={classes.button}
                                                            id="orderUploadDialogMissingCounterpartiesCloseBtn"
                                                            onClick={closeDialog}
                                                        >
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Button
                                                        className={classes.button}
                                                        id="orderUploadDialogMissingCounterpartiesNextBtn"
                                                        onClick={() => {
                                                            validateBatchOrder(newOrders);
                                                            reInit();
                                                        }}
                                                    >
                                                        {counterpartiesCreateBatch.failed === 0 ? 'Next' : 'Try Again'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                );
                            default:
                        }
                    })()}
                </Grid>
            </div>
        );
    };
    const renderInvalidOrders = () => {
        const RenderTable = () => {
            return (() => {
                try {
                    return renderTable();
                } catch (e) {
                    console.error(`error rendering table for invalid orders: ${e}`);
                    setErrors({
                        ...errors,
                        [events.errorBuildingInvalidOrdersTable]: e,
                    });
                    setActiveState(events.errorBuildingInvalidOrdersTable);
                    return <div>Error Building table</div>;
                }
            })();
        };

        return (
            <div
                style={{
                    padding: 10,
                    display: 'grid',
                    justifyItems: 'center',
                    gridTemplateColumns: '1fr',
                }}
            >
                <div style={{ padding: '0px 0px 10px 0px' }}>
                    <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                        <Grid item>Invalid Orders Found</Grid>
                        <Grid item>Please Resolve Issues and Try Again</Grid>
                    </Grid>
                </div>
                <div style={{ display: 'grid' }}>
                    <div style={{ overflow: 'auto' }}>
                        <RenderTable />
                    </div>
                </div>
            </div>
        );
    };
    const renderDeletedOrders = () => {
        const RenderTable = () => {
            return (() => {
                try {
                    return renderTable();
                } catch (e) {
                    console.error(`error rendering table for deleted orders: ${e}`);
                    setErrors({
                        ...errors,
                        [events.errorBuildingResolveDeletedOrdersTable]: e,
                    });
                    setActiveState(events.errorBuildingResolveDeletedOrdersTable);
                    return <div>Error Building table</div>;
                }
            })();
        };

        return (
            <div
                style={{
                    padding: 10,
                    display: 'grid',
                    justifyItems: 'center',
                    gridTemplateColumns: '1fr',
                }}
            >
                <div style={{ padding: '0px 0px 10px 0px' }}>
                    <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                        <Grid item>Some orders that changed in the upload file have been deleted.</Grid>
                        <Grid item>
                            Please select the orders you would like to restore before updating, or exclude from the
                            updates.
                        </Grid>
                        <Grid item>
                            <Grid alignItems={'center'} container direction={'row'} spacing={8}>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        id="orderUploadDialogResolveDeletedStpSelectAllNewBtn"
                                        onClick={restoreAllDeleted}
                                    >
                                        Restore all
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ display: 'grid' }}>
                    <div style={{ overflow: 'auto' }}>
                        <RenderTable />
                    </div>
                </div>
            </div>
        );
    };
    const renderDuplicateOrders = () => {
        const RenderTable = () => {
            return (() => {
                try {
                    return renderTable();
                } catch (e) {
                    console.error(`error rendering table for duplicate orders: ${e}`);
                    setErrors({
                        ...errors,
                        [events.errorBuildingResolveDuplicateOrdersTable]: e,
                    });
                    setActiveState(events.errorBuildingResolveDuplicateOrdersTable);
                    return <div>Error Building table</div>;
                }
            })();
        };

        return (
            <div
                style={{
                    padding: 10,
                    display: 'grid',
                    justifyItems: 'center',
                    gridTemplateColumns: '1fr',
                }}
            >
                <div style={{ padding: '0px 0px 10px 0px' }}>
                    <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                        <Grid item>Some orders will be updated during this upload.</Grid>
                        <Grid item>Please select the orders that you would like to update or exclude.</Grid>
                        <Grid item>
                            <Grid alignItems={'center'} container direction={'row'} spacing={8}>
                                <Grid item>
                                    <Button
                                        className={classes.button}
                                        id="orderUploadDialogResolveDuplicatesStpSelectAllNewBtn"
                                        onClick={updateAllDuplicates}
                                    >
                                        Update all
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ display: 'grid' }}>
                    <div style={{ overflow: 'auto' }}>
                        <RenderTable
                        // id="orderUploadDialogResolveDuplicatesStpTable"
                        />
                    </div>
                </div>
            </div>
        );
    };
    const renderConfirmUpload = () => {
        const RenderTable = () => {
            return (() => {
                try {
                    return renderTable();
                } catch (e) {
                    console.error(`error rendering table for upload confirmation: ${e}`);
                    setErrors({
                        ...errors,
                        [events.errorBuildingConfirmUploadOrdersTable]: e,
                    });
                    setActiveState(events.errorBuildingConfirmUploadOrdersTable);
                    return <div>Error Building table</div>;
                }
            })();
        };
        return (
            <div
                style={{
                    padding: 10,
                    display: 'grid',
                    justifyItems: 'center',
                    gridTemplateColumns: '1fr',
                }}
            >
                <div id="orderUploadDialogConfirmUploadStpRoot" style={{ padding: '0px 0px 10px 0px' }}>
                    <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                        <Grid item>Please Confirm Upload</Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                id="orderUploadDialogConfirmUploadStpUploadBtn"
                                onClick={() => {
                                    setActiveState(events.confirmUpload);
                                }}
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ display: 'grid' }}>
                    <div style={{ overflow: 'auto' }}>
                        {(() => {
                            if (activeState === states['Confirm Upload'].view) {
                                return (
                                    <RenderTable
                                    // id="orderUploadDialogConfirmUploadStpTable"
                                    />
                                );
                            } else if (activeState === states['Confirm Upload'].uploadConfirmed) {
                                return <div>Upload Confirmed</div>;
                            } else {
                                return <div>Error</div>;
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    };
    const renderFinish = () => {
        const showCreateMsg = ordersCreateBatch.succeeded !== 0 || ordersCreateBatch.failed !== 0;
        const showUpdateMsg = ordersUpdateBatch.succeeded !== 0 || ordersUpdateBatch.failed !== 0;

        const msg = (action: any, succeeded: any, failed: any) => {
            return (
                <React.Fragment>
                    <Grid item>
                        <u id="orderUploadDialogFinishStpMsg">Order {action}:</u>
                    </Grid>
                    <Grid id="orderUploadDialogSucceededCount" item>
                        Succeeded: {succeeded}
                    </Grid>
                    <Grid id="orderUploadDialogFailedCount" item>
                        Failed: {failed}
                    </Grid>
                    <Grid id="orderUploadDialogFailedReasons" item>
                        <FailedOrderReasonsList failedOrderReasons={ordersCreateBatch.orderReasonsFailed} />
                    </Grid>
                </React.Fragment>
            );
        };

        return (
            <Grid alignItems={'center'} container direction={'column'} spacing={8}>
                <Grid item>Upload Complete</Grid>
                {showCreateMsg && msg('Creation', ordersCreateBatch.succeeded, ordersCreateBatch.failed)}
                {showUpdateMsg && msg('Updating', ordersUpdateBatch.succeeded, ordersUpdateBatch.failed)}
                <Grid item>
                    <Grid container direction={'row'} spacing={8}>
                        <Grid item>
                            <Button
                                className={classes.button}
                                id="orderUploadDialogFinishStpCloseBtn"
                                onClick={closeDialog}
                            >
                                Close
                            </Button>
                        </Grid>
                        {(ordersUpdateBatch.failed !== 0 || ordersCreateBatch.failed !== 0) && (
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    onClick={() => {
                                        validateBatchOrder(newOrders);
                                        reInit();
                                    }}
                                >
                                    Try Again
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const activeStep = getStep(activeState);
    const dialogProps = (() => {
        switch (activeStep) {
            default:
                return {
                    fullScreen: true,
                };
        }
    })();

    return (
        <Dialog className={classes.root} onClose={closeDialog} open={show} scroll="paper" {...dialogProps}>
            <DialogTitle className={classes.dialogTitleWrapper}>
                <div className={classes.dialogTitle}>
                    <div className={classes.TBDLogoWrapper}>
                        <img alt="logo" className={classes.TBDLogoImg} src={logoImg} />
                    </div>
                    <div className={classes.dialogTitleText}>Importing: {category} ORDERS/CREDIT NOTES</div>
                    <div className={classes.dialogTitleCloseBtnWrapper}>
                        <Tooltip placement="top" title="Close">
                            <Fab
                                aria-label="Close"
                                className={classes.dialogTitleCloseButton}
                                color="primary"
                                onClick={closeDialog}
                            >
                                <CloseIcon className={classes.dialogTitleCloseIcon} />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            </DialogTitle>
            <div className={classes.dialogContent}>
                <div className={classes.stepperWrapper}>
                    <Stepper activeStep={steps.indexOf(activeStep)} className={classes.stepper} nonLinear>
                        {steps.map((stepLabel: any, stepIdx: any) => {
                            return (
                                <Step key={stepIdx}>
                                    <StepButton
                                        completed={stepComplete(stepLabel, activeStep)}
                                        id={
                                            stepComplete(stepLabel, activeStep)
                                                ? `orderUploadDialogStepper_Step${stepIdx}_complete`
                                                : `orderUploadDialogStepper_Step${stepIdx}_incomplete`
                                        }
                                    >
                                        {stepLabel}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.stepContentWrapper}>
                    {(() => {
                        switch (true) {
                            // Render Generic Processing Screen
                            case Object.values(processingStates).includes(activeState):
                                return renderProcessing();
                            // Render Generic Error Screen
                            case Object.values(errorStates).includes(activeState):
                                return renderError();
                            // Check For Steps and Render Them
                            case activeStep === 'Counterparty Creation':
                                return renderCounterpartyCreation();
                            case activeStep === 'Resolve Invalid':
                                return renderInvalidOrders();
                            case activeStep === 'Resolve Deleted':
                                return renderDeletedOrders();
                            case activeStep === 'Resolve Duplicates':
                                return renderDuplicateOrders();
                            case activeStep === 'Confirm Upload':
                                return renderConfirmUpload();
                            case activeStep === 'Finish':
                                return renderFinish();
                            default:
                                return renderError('Invalid State');
                        }
                    })()}
                </div>
            </div>
        </Dialog>
    );
}

const FailedOrderReasonsList = (props: any) => {
    if ((props.failedOrderReasons || []).length === 0) {
        return <div />;
    }
    return (
        <React.Fragment>
            <div
                style={{
                    margin: '40px 0 10px',
                    fontWeight: 'bold',
                }}
            >
                Failed Orders:
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    textAlign: 'center',
                    padding: '5px',
                    fontWeight: 'bold',
                }}
            >
                <div>Order Number</div>
                <div>Reason(s)</div>
            </div>
            <div
                style={{
                    height: '400px',
                    overflow: 'scroll',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    {(() => {
                        return props.failedOrderReasons.map((orderReasons: any, index: any) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        textAlign: 'center',
                                        padding: '5px',
                                        borderWidth: '1px 0 0 0',
                                        borderColor: '#ddd',
                                        borderStyle: 'solid',
                                    }}
                                >
                                    <div>{orderReasons.order.number}</div>
                                    <Grid container direction={'column'} spacing={8}>
                                        {orderReasons.reasons.map((reason: any, index: any) => {
                                            return (
                                                <Grid item key={index}>
                                                    {reason.reason}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            );
                        });
                    })()}
                </div>
            </div>
        </React.Fragment>
    );
};

export default OrderUploadDialog;
