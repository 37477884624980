import React from 'react';
import { Bar, BarChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { processUnixDateForViewing } from 'utils';
import { calculateTooltipOffset, roundScaleAndFormatNumber } from 'views/Client/SharedGraphComponents';
import { GenerateMonthViewCashFlowReportResponse, Aggregation } from 'popcorn-js/report';
import { CustomTooltip, CustomTooltipSection, CustomTooltipSectionLine } from 'views/Client/RechartsCustomTooltip';
import { Currency } from 'popcorn-js/currency';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { CustomTheme } from 'theme/custom';

interface Response {
    data: Array<Record<string, number | string>>; // USD Invoice Overdue: <amount>, also contains 'name' key for x-axis
    purchaseContractsPerCurrencyKeys: Array<string>;
    salesContractsPerCurrencyKeys: Array<string>;
}

let purchaseContractsPerCurrencyKeys: Set<string>;
let salesContractsPerCurrencyKeys: Set<string>;

const transformMonthViewData = (
    cashFlowResponse: GenerateMonthViewCashFlowReportResponse,
    dateFormat: string,
): Response => {
    let data: Array<Record<string, number | string>> = [];

    if (!cashFlowResponse.aggregations) {
        return {
            data,
            purchaseContractsPerCurrencyKeys: [],
            salesContractsPerCurrencyKeys: [],
        };
    }

    purchaseContractsPerCurrencyKeys = new Set<string>();
    salesContractsPerCurrencyKeys = new Set<string>();

    data = cashFlowResponse.aggregations.map(
        (p: Aggregation): Record<string, number | string> => {
            const dateInfo = p.categoryName.split('_');
            const date = moment(`${dateInfo[0]}-${dateInfo[1]}-01`).unix();
            const returnObj: Record<string, number | string> = {
                name: processUnixDateForViewing(date, dateFormat),
                date: date,

                // For Tooltip and graph: Trade unrealised cash flow
                tradeUnrealised: p.allTradeUnrealised?.costCurrencyAmount,

                // For Tooltip: Net Cash flows section
                netCashFlows: p.netCashFlow,

                // For Tooltip:  Total purchase contracts
                purchaseContractsTotal: p.invoiceTotalImport?.total?.costCurrencyAmount,

                // For Tooltip:  Total sales contracts
                salesContractsTotal: p.invoiceTotalExport?.total?.costCurrencyAmount,

                // key examples to be added for currency breakdown on purchase and sales contracts
                //USD_purchaseContracts
                //EUR_purchaseContracts

                // Note: Currency breakdown no longer on graph, only in tooltip
                // For Graph: Import Invoice Overdue Series
                invoiceOverdueImport: p.invoiceOverdueImport?.total?.costCurrencyAmount,
                // For Graph: Export Invoice Overdue Series
                invoiceOverdueExport: p.invoiceOverdueExport?.total?.costCurrencyAmount,
                // For Graph: Import Invoice Due Series
                invoiceUnRealisedImport: p.invoiceUnRealisedImport?.total?.costCurrencyAmount,
                // For Graph: Export Invoice Due Series
                invoiceUnRealisedExport: p.invoiceUnRealisedExport?.total?.costCurrencyAmount,
            };
            const purchaseContractsKey = '_purchaseContracts';
            const salesContractsKey = '_salesContracts';

            // For tooltip: purchase contracts per currency
            for (const curr of Object.keys(p.invoiceTotalImport?.perCurrency || {})) {
                const currKey: string = curr + purchaseContractsKey;
                purchaseContractsPerCurrencyKeys.add(currKey);
                returnObj[currKey] = p.invoiceTotalImport?.perCurrency[curr]?.costCurrencyAmount;
            }

            // For tooltip: sales contracts per currency
            for (const curr of Object.keys(p.invoiceTotalExport?.perCurrency || {})) {
                const currKey: string = curr + salesContractsKey;
                salesContractsPerCurrencyKeys.add(currKey);
                returnObj[currKey] = p.invoiceTotalExport?.perCurrency[curr]?.costCurrencyAmount;
            }

            return returnObj;
        },
    );

    return {
        data,
        purchaseContractsPerCurrencyKeys: Array.from(purchaseContractsPerCurrencyKeys).sort(),
        salesContractsPerCurrencyKeys: Array.from(salesContractsPerCurrencyKeys).sort(),
    };
};

interface CashFlowGraphProps {
    data: GenerateMonthViewCashFlowReportResponse | undefined;
    localCurrency: Currency;
    width: number;
}

const CashFlowGraph: React.FC<CashFlowGraphProps> = (props: CashFlowGraphProps) => {
    if (!props.data) {
        return <div />;
    }
    const dateFormat = 'MMM-YY';
    const graphData = transformMonthViewData(props.data, dateFormat);
    const theme = useTheme<CustomTheme>();
    const TickFormatter = (tick: number): string => {
        // return (tick / scaleToNumber(scale)) //.toFixed(0)
        return roundScaleAndFormatNumber(tick || 0, currencyCode);
    };

    const { localCurrency } = props;
    const currencyCode = localCurrency ? localCurrency.symbol : '';

    const tooltip: React.ReactElement = (
        <CustomTooltip
            currency={localCurrency}
            valueFormatter={(val: number): string => {
                return roundScaleAndFormatNumber(val || 0, currencyCode);
            }}
        >
            <CustomTooltipSection
                heading={'Purchase Contracts'}
                hideIfZero
                showTotal
                style={{
                    color: theme.palette.custom.import.main,
                }}
            >
                {(graphData.purchaseContractsPerCurrencyKeys || []).map((k: string, i: number) => {
                    return <CustomTooltipSectionLine key={i} dataKey={k} heading={k.split('_')[0]} />;
                })}
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Sales Contracts'}
                hideIfZero
                showTotal
                style={{ color: theme.palette.custom.export.main }}
            >
                {(graphData.salesContractsPerCurrencyKeys || []).map((k: string, i: number) => {
                    return <CustomTooltipSectionLine key={i} dataKey={k} heading={k.split('_')[0]} />;
                })}
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Future Net Trade Cash Flows'}
                hideIfZero
                style={{ color: theme.palette.custom.data.graphC }}
            >
                <CustomTooltipSectionLine
                    dataKey={'tradeUnrealised'}
                    heading={'Total'}
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                />
            </CustomTooltipSection>
            <CustomTooltipSection>
                <CustomTooltipSectionLine
                    dataKey={'netCashFlows'}
                    heading={'Net Cash Flows: '}
                    style={{
                        marginTop: '8px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                />
            </CustomTooltipSection>
        </CustomTooltip>
    );

    const InvoiceStack = 'InvoiceStack';
    const TradeUnrealisedStack = 'TradeUnrealisedStack';

    return (
        <div>
            <BarChart
                barCategoryGap={'15%'}
                barGap={0}
                data={graphData.data || []}
                height={360}
                margin={{
                    top: 25,
                    right: 30,
                    left: 35,
                    bottom: 15,
                }}
                stackOffset={'sign'}
                width={props.width || 800}
            >
                <XAxis
                    axisLine={false}
                    dataKey="name"
                    interval={0}
                    orientation={'top'}
                    stroke={'white'}
                    tickLine={false}
                    angle={-45}
                    tick={{ fontSize: '12px' }}
                    tickMargin={25}
                />
                <YAxis
                    allowDecimals
                    axisLine={false}
                    tick={{ fill: theme.palette.grey[200] }}
                    tickFormatter={TickFormatter}
                    tickLine={false}
                />
                <Tooltip
                    content={tooltip}
                    cursor={{ fill: '#1E2036' }}
                    offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
                />
                <CartesianGrid stroke={'#494A8B'} vertical={false} />
                <Bar
                    dataKey={'invoiceOverdueImport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceOverdueExport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceUnRealisedImport'}
                    fill={theme.palette.custom.import.main}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.import.main}
                />
                <Bar
                    dataKey={'invoiceUnRealisedExport'}
                    fill={theme.palette.custom.export.main}
                    stackId={InvoiceStack}
                    stroke={theme.palette.custom.export.main}
                />
                <Bar
                    dataKey={'tradeUnrealised'}
                    fill={theme.palette.custom.data.graphC}
                    stackId={TradeUnrealisedStack}
                />
                <ReferenceLine stroke={'#C6C6C6'} y={0} />
            </BarChart>
        </div>
    );
};

export default CashFlowGraph;
