/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
    Recordkeeper as CounterpartyRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/counterparty/recordkeeper';
import { Invoice, InvoiceStatus, InvoiceType } from 'popcorn-js/invoice';
import { UpdateRequest, UpdateResponse, ValidateRequest, ValidateResponse } from 'popcorn-js/invoice/recordkeeper';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { Handler as InvoiceHandler } from 'popcorn-js/invoice/handler';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { History } from './History';
import { Counterparty } from 'popcorn-js/counterparty';
import { AppContext, AppContextT } from 'context';
import { InvoiceData } from './InvoiceData';
import { AmountDetails } from './AmountDetails';
import { OriginalDetails } from './OriginalDetails';
import { financialYears } from 'popcorn-js';
import { CircularProgress, Dialog } from '@material-ui/core';
import { useServiceSync } from 'hooks/useService';
import { AuditActionType } from 'popcorn-js/audit';

export const InvoiceDetail = (props: {
    invoice: Invoice;
    updateInvoiceSuccess?: (i?: Invoice) => void;
    readOnly?: boolean;
    onClose?: () => void;
    counterparties?: Counterparty[];
}): ReactElement => {
    const { updateInvoiceSuccess } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();
    const [activeState, setActiveState] = useState<activeStates>(activeStates.viewing);
    const [invoiceValidationErrors, setInvoiceValidationErrors] = useState<{ field: string; reason: string }[]>(
        [] as { field: string; reason: string }[],
    );
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [showHistory, setShowHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState(props.invoice);
    const [originalInvoice, setOriginalInvoice] = useState(props.invoice);
    // eslint-disable-next-line
    const [invoiceCounterparty, setInvoiceCounterparty] = useState<Counterparty | undefined>();
    const readOnly = activeState === activeStates.viewing;
    const disableUnderLine = activeState === activeStates.viewing;

    const [counterpartyRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(CounterpartyRecordkeeper.retrieve);
    const [InvoiceHandlerValidate] = useServiceSync<ValidateRequest, ValidateResponse>(InvoiceHandler.Validate);
    const [InvoiceHandlerUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(InvoiceHandler.Update);

    const inputProps = {
        classes: {
            underline: classes.fieldUnderline,
        },
        disableUnderline: disableUnderLine,
        readOnly: readOnly,
    };

    useEffect(() => {
        assignInvoice(props.invoice).finally();
    }, [props.invoice]);

    const assignInvoice = async (i: Invoice) => {
        const _invoiceCounterparty = await retrieveInvoiceCounterparty(i);
        setInvoiceCounterparty(_invoiceCounterparty);
        const _invoice = processEntityForViewing(i);
        setInvoice(_invoice);
        setOriginalInvoice(_invoice);
    };

    const retrieveInvoiceCounterparty = async (i: Invoice): Promise<Counterparty | undefined> => {
        try {
            setLoading(true);
            const retrieveResponse = await counterpartyRetrieve({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: i.counterpartyId || '' },
            });
            setInvoiceCounterparty(retrieveResponse.counterparty);
            setLoading(false);
            return retrieveResponse.counterparty;
        } catch (e) {
            setInvoiceCounterparty(undefined);
            console.error('error retrieving invoice counterparty', e);
            setLoading(false);
        }
    };

    const validateInvoice = async (): Promise<{ field: string; reason: string }[] | undefined> => {
        setInvoiceValidationErrors([]);
        try {
            setLoading(true);
            const validateResponse = await InvoiceHandlerValidate({ invoice });
            setLoading(false);
            if (validateResponse.reasons && validateResponse.reasons.length > 0) {
                setInvoiceValidationErrors(validateResponse.reasons);
                return validateResponse.reasons;
            }
        } catch (e) {
            setErrorMessage(e.message || e);
            setLoading(false);
        }
    };

    const updateInvoice = async () => {
        setLoading(true);
        try {
            const updateResponse = await InvoiceHandlerUpdate({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: invoice.id },
                invoice,
            });
            assignInvoice(updateResponse.invoice);
            const _invoice = processEntityForViewing(updateResponse.invoice);
            updateInvoiceSuccess && updateInvoiceSuccess(_invoice);
            setInvoice(_invoice);
            setOriginalInvoice(_invoice);
            setActiveState(activeStates.viewing);
            setSuccessMessage('Updated');
        } catch (e) {
            setInvoice(originalInvoice);
            setErrorMessage(e);
            setActiveState(activeStates.viewing);
        }
        setLoading(false);
    };

    const onSave = async () => {
        const _invoiceValidationErrors = await validateInvoice();
        if ((!_invoiceValidationErrors || _invoiceValidationErrors?.length === 0) && errorMessage === undefined) {
            await updateInvoice();
        }
    };

    const showDiscardChangesConfirmation = (functionAfterDiscard: () => void) => {
        setWarningMessage('Would you like to discard all changes?');
        setConfirmationMethod(functionAfterDiscard);
    };

    const handleDiscardChangesAndView = () => {
        setInvoice(originalInvoice);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
        setInvoiceValidationErrors([]);
        setActiveState(activeStates.viewing);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const editInvoice = (field: string, newValue: unknown) => {
        // setInvoiceChanged(true);
        const _invoiceValidationErrors = [...invoiceValidationErrors];
        const _invoice = { ...invoice };
        (_invoice as Record<string, unknown>)[field] = newValue;
        _invoiceValidationErrors.filter((fe) => fe.field === field);
        setInvoice(_invoice);
        setInvoiceValidationErrors(_invoiceValidationErrors);
    };

    const processEntityForViewing = (i: Invoice): Invoice => {
        return {
            ...i,
            auditEntry: {
                ...invoice.auditEntry,
                username: appContext.userProfile?.displayName,
            },
        };
    };

    const generateCounterpartyOptions = async (inputValue: string): Promise<{ value: string; label: string }[]> => {
        const criteria: Criteria = [
            {
                type: CriteriaType.TextCriterion,
                text: inputValue,
                field: 'name',
            },
            {
                type: CriteriaType.ExactCriterion,
                text: invoice.partyCode,
                field: 'partyCode',
            },
        ];
        try {
            const findResponse = await CounterpartyRecordkeeper.find({ criteria });
            return (findResponse.records || []).map((b) => ({
                value: b.id || '',
                label: b.name || '',
            }));
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        return [];
    };

    const switchToEditing = async () => {
        setActiveState(activeStates.editing);
    };

    const renderDialogs = () => {
        return (
            <span>
                {showHistory && (
                    <History invoice={props.invoice} onHide={() => setShowHistory(false)} open={showHistory} />
                )}
                {loading && (
                    <Dialog
                        BackdropProps={{
                            classes: { root: classes.progressSpinnerDialogBackdrop },
                        }}
                        PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                        className={classes.loading}
                        open={loading}
                    >
                        <CircularProgress className={classes.progress} />
                    </Dialog>
                )}
            </span>
        );
    };

    const currencyOptions: { value: string; label: string }[] = [];
    appContext.currencies?.slice().forEach((c) => {
        currencyOptions.push({
            value: c.isoCode,
            label: c.isoCode,
        });
    });

    const showDeleteConfirmation = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(`You are about to delete invoice '${invoice.number}'. Do you want to continue?`);
        setConfirmationMethod(() => async () => {
            try {
                await InvoiceHandler.Delete({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: invoice.id },
                });
                setSuccessMessage('Invoice Deleted');
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(undefined);
                updateInvoiceSuccess && updateInvoiceSuccess();
            } catch (e) {
                setSuccessMessage(undefined);
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(e.message || e);
            }
        });
    };

    const paymentStatus = () => {
        const payment = [];
        switch (invoice.status) {
            case InvoiceStatus.INVOICE_STATUS_PAID:
                payment.push(<div className={classes.paidWrapper}>Paid</div>);
                break;
            case InvoiceStatus.INVOICE_STATUS_PENDING:
                payment.push(<div className={classes.pendingWrapper}>Pending</div>);
                break;
            default:
        }
        payment.push(<div />);
        return payment;
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    squareEdge: true,

                    itemsLeft: [
                        {
                            id: 'InvoiceDetail/title',
                            type: ITEM_VARIATION.TITLE,
                            text: props.invoice?.number || '',
                        },
                        {
                            id: 'InvoiceDetail/paymentStatus',
                            type: ITEM_VARIATION.ELEMENT,
                            element: <div>{paymentStatus()}</div>,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceDetail/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: () => switchToEditing(),
                            hide: activeState == activeStates.editing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceDetail/save',
                            icon: ACTION_BUTTON_TYPE.SAVE,
                            helpText: 'Save',
                            onClick: () => onSave(),
                            hide: activeState == activeStates.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceDetail/cancel',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel',
                            onClick: () => showDiscardChangesConfirmation(handleDiscardChangesAndView),
                            hide: activeState == activeStates.viewing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceDetail/history',
                            icon: ACTION_BUTTON_TYPE.HISTORY,
                            helpText: 'History',
                            onClick: () => setShowHistory(true),
                            hide: activeState == activeStates.editing,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'InvoiceDetail/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(),
                            hide: invoice.auditEntry?.action === AuditActionType.DELETED,
                        },
                    ],
                }}
            >
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
                <div className={classes.moreDetailsWrapper}>
                    <InvoiceData
                        invoiceValidationErrors={invoiceValidationErrors}
                        activeState={activeState}
                        editInvoice={editInvoice}
                        readOnly={readOnly}
                        invoice={invoice}
                        currencyOptions={currencyOptions}
                        inputProps={inputProps}
                        generateCounterpartyOptions={generateCounterpartyOptions}
                        invoiceCounterparty={invoiceCounterparty}
                    />
                    <AmountDetails
                        invoiceValidationErrors={invoiceValidationErrors}
                        editInvoice={editInvoice}
                        readOnly={readOnly}
                        invoice={invoice}
                        inputProps={inputProps}
                    />
                </div>
                <OriginalDetails
                    invoiceValidationErrors={invoiceValidationErrors}
                    invoice={invoice}
                    inputProps={inputProps}
                />
                {renderDialogs()}
            </StandardCard>
        </React.Fragment>
    );
};

export const typeOptions: { value: InvoiceType; label: InvoiceType }[] = [
    {
        value: InvoiceType.PurchaseInvoiceType,
        label: InvoiceType.PurchaseInvoiceType,
    },
    {
        value: InvoiceType.PurchaseDebitNoteType,
        label: InvoiceType.PurchaseDebitNoteType,
    },
    {
        value: InvoiceType.PurchaseCreditNoteType,
        label: InvoiceType.PurchaseCreditNoteType,
    },
    {
        value: InvoiceType.PurchaseAdjustmentType,
        label: InvoiceType.PurchaseAdjustmentType,
    },
    {
        value: InvoiceType.PurchasePaymentType,
        label: InvoiceType.PurchasePaymentType,
    },
    {
        value: InvoiceType.PurchaseRefundType,
        label: InvoiceType.PurchaseRefundType,
    },
    {
        value: InvoiceType.PurchaseReversalType,
        label: InvoiceType.PurchaseReversalType,
    },
    {
        value: InvoiceType.SalesInvoiceType,
        label: InvoiceType.SalesInvoiceType,
    },
    {
        value: InvoiceType.SalesDebitNoteType,
        label: InvoiceType.SalesDebitNoteType,
    },
    {
        value: InvoiceType.SalesCreditNoteType,
        label: InvoiceType.SalesCreditNoteType,
    },
    {
        value: InvoiceType.SalesAdjustmentType,
        label: InvoiceType.SalesAdjustmentType,
    },
    {
        value: InvoiceType.SalesPaymentType,
        label: InvoiceType.SalesPaymentType,
    },
    {
        value: InvoiceType.SalesRefundType,
        label: InvoiceType.SalesRefundType,
    },
    {
        value: InvoiceType.SalesReversalType,
        label: InvoiceType.SalesReversalType,
    },
];

export const financialYearOptions = financialYears.map((financialYear) => ({
    value: financialYear,
    label: financialYear,
}));

export enum activeStates {
    viewing = 'viewing',
    editing = 'editing',
}

export const showIcon = (readOnly: boolean): string | undefined => {
    return readOnly ? '' : undefined;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    moreDetailsWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: '574.51px',
        width: '500px',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
    },
    paidWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.success.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    pendingWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    fieldUnderline: {
        '&:before': {
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
        },
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progress: {
        color: theme.palette.text.hint,
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default InvoiceDetail;
