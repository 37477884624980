import * as React from 'react';
import { Button, ButtonOverrides, SIZE as BASE_SIZE } from 'baseui/button';
import { themedUseStyletron as useStyletron } from 'theme/basewebStyleUtils';
import { ReactElement, SyntheticEvent, useContext } from 'react';
import { AppContext, AppContextT } from 'context';
import { HexToRGBA } from 'utils';

export enum VARIANT {
    CONTAINED = 'CONTAINED',
    OUTLINED = 'OUTLINED',
    NO_OUTLINE = 'NO_OUTLINE',
}

export enum COLOR {
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
    CONTEXT = 'CONTEXT',
    ACTION = 'ACTION',
    DARKBLUE = 'DARKBLUE',
    WHITE = 'WHITE',
    DEFAULT = 'DEFAULT',
    INACTIVE = 'INACTIVE',
}

export enum SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

type borderStyleT =
    | 'none'
    | 'solid'
    | 'inset'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'dashed'
    | 'dotted'
    | 'double'
    | 'groove'
    | 'hidden'
    | 'outset'
    | 'ridge'
    | undefined;

const mapSizeToBaseWeb = {
    [SIZE.SMALL]: BASE_SIZE.compact,
    [SIZE.MEDIUM]: BASE_SIZE.default,
    [SIZE.LARGE]: BASE_SIZE.large,
};

const useButtonVariant = ({
    variant,
    color,
    size,
    customHeight,
    customMarginTop,
    customMarginRight,
    customMarginBottom,
    customMarginLeft,
    groupInfo,
    customWidth,
    disableUppercase,
}: {
    variant: VARIANT;
    color?: COLOR;
    size: SIZE;
    customHeight?: string;
    customMarginTop?: string;
    customMarginRight?: string;
    customMarginBottom?: string;
    customMarginLeft?: string;
    groupInfo?: { position: number; total: number };
    customWidth?: string;
    disableUppercase?: boolean;
}): [overrides: ButtonOverrides, size: SIZE] => {
    const [, theme] = useStyletron();

    let backgroundColorCode = '#000000';
    let hoverBackgroundColorCode = '#000000';
    let activeBackgroundColorCode = '#000000';
    let colorCode = '#ffffff';

    switch (color) {
        case COLOR.ACTION:
            backgroundColorCode = theme.primary.main;
            colorCode = theme.primary.contrastText;
            break;
        case COLOR.CONTEXT:
            const appContext = useContext<AppContextT>(AppContext);
            const mainColor = appContext.contextSwitched ? theme.custom.stellcapBrand2 : theme.custom.stellcapBrand1;
            backgroundColorCode = mainColor.main;
            colorCode = mainColor.contrastText;
            break;
        case COLOR.DARKBLUE:
            backgroundColorCode = theme.background.default;
            colorCode = theme.custom.text.primary;
            break;
        case COLOR.EXPORT:
            backgroundColorCode = theme.custom.export.main;
            colorCode = theme.custom.export.contrastText;
            break;
        case COLOR.IMPORT:
            backgroundColorCode = theme.custom.import.main;
            colorCode = theme.custom.import.contrastText;
            break;
        case COLOR.WHITE:
            backgroundColorCode = theme.custom.text.primary;
            break;
        case COLOR.INACTIVE:
            backgroundColorCode = theme.text.disabled;
            colorCode = theme.action.disabled;
            break;
        case COLOR.DEFAULT:
            backgroundColorCode = theme.custom.icons.main;
            colorCode = theme.primary.contrastText;
    }
    hoverBackgroundColorCode = HexToRGBA(backgroundColorCode, 0.6);
    activeBackgroundColorCode = HexToRGBA(backgroundColorCode, 0.3);

    let outlineStyle: borderStyleT = 'none';
    let outlineColor = 'none';
    let outlineWidth = '0px';
    if (variant === VARIANT.OUTLINED) {
        colorCode = backgroundColorCode;
        outlineStyle = 'solid';
        outlineColor = backgroundColorCode;
        outlineWidth = '1px';
        backgroundColorCode = 'transparent';
        hoverBackgroundColorCode = HexToRGBA('#FFF', 0.3);
        activeBackgroundColorCode = HexToRGBA('#FFF', 0.75);
    }

    if (variant === VARIANT.NO_OUTLINE) {
        colorCode = colorCode;
        outlineStyle = 'none';
        backgroundColorCode = 'transparent';
        hoverBackgroundColorCode = HexToRGBA('#FFF', 0.6);
        activeBackgroundColorCode = HexToRGBA('#FFF', 0.75);
    }

    let fontSize = '';
    let height = '';
    switch (size) {
        case SIZE.SMALL:
            fontSize = '12px';
            height = '24px';
            break;
        case SIZE.LARGE:
            fontSize = '16px';
            height = '39px';
            break;
        default:
            height = '35px';
            fontSize = '14px';
    }

    // determine border styles for button groups
    // this can also be used for split-buttons, with an icon button w/ menu
    let borderStyle: {
        borderBottomRightRadius?: string;
        borderBottomLeftRadius?: string;
        borderTopRightRadius?: string;
        borderTopLeftRadius?: string;

        borderTopStyle?: borderStyleT;
        borderTopColor?: string;
        borderTopWidth?: string;

        borderBottomStyle?: borderStyleT;
        borderBottomColor?: string;
        borderBottomWidth?: string;

        borderRightStyle?: borderStyleT;
        borderRightColor?: string;
        borderRightWidth?: string;

        borderLeftStyle?: borderStyleT;
        borderLeftColor?: string;
        borderLeftWidth?: string;

        marginLeft?: string;
        marginRight?: string;
        marginTop?: string;
        marginBottom?: string;
    } = {
        borderTopStyle: outlineStyle,
        borderTopColor: outlineColor,
        borderTopWidth: outlineWidth,
        borderBottomStyle: outlineStyle,
        borderBottomColor: outlineColor,
        borderBottomWidth: outlineWidth,
        borderRightStyle: outlineStyle,
        borderRightColor: outlineColor,
        borderRightWidth: outlineWidth,
        borderLeftStyle: outlineStyle,
        borderLeftColor: outlineColor,
        borderLeftWidth: outlineWidth,
    };
    if (groupInfo && groupInfo.total > 1) {
        if (groupInfo.position === 0) {
            borderStyle = {
                ...borderStyle,
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '5px',
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '5px',
                borderRightStyle: 'solid',
                borderRightColor: theme.background.default,
                borderRightWidth: '1px',
                borderLeftColor: theme.background.default,
                borderLeftWidth: '0px',
                borderLeftStyle: 'none',
                marginLeft: '0px',
                marginRight: '0px',
                marginTop: '0px',
                marginBottom: '0px',
            };
        } else if (groupInfo.position === groupInfo.total - 1) {
            borderStyle = {
                ...borderStyle,
                borderBottomRightRadius: '5px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '0px',

                borderRightStyle: 'none',
                borderRightColor: theme.background.default,
                borderRightWidth: '0px',

                borderLeftColor: theme.background.default,
                borderLeftWidth: '1px',
                borderLeftStyle: 'solid',

                marginLeft: '-1px',
                marginRight: '0px',
                marginTop: '0px',
                marginBottom: '0px',
            };
        } else {
            borderStyle = {
                ...borderStyle,
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '0px',

                borderRightStyle: 'solid',
                borderRightColor: theme.background.default,
                borderRightWidth: '1px',

                borderLeftColor: theme.background.default,
                borderLeftWidth: '1px',
                borderLeftStyle: 'solid',

                marginLeft: '-1px',
                marginRight: '0px',
                marginTop: '0px',
                marginBottom: '0px',
            };
        }
    } else {
        borderStyle = {
            ...borderStyle,
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            marginLeft: '0px',
            marginRight: '0px',
            marginTop: '0px',
            marginBottom: '0px',
        };
    }

    const overrides: ButtonOverrides = {
        BaseButton: {
            // available props: { $theme, $kind, $isSelected, $shape, $size, $isLoading, $disabled }
            style: (
                {
                    /* insert available props here */
                },
            ) => {
                return {
                    ...borderStyle,
                    color: colorCode,
                    backgroundColor: backgroundColorCode,
                    paddingTop: '11px',
                    paddingBottom: '11px',
                    ':hover': {
                        backgroundColor: hoverBackgroundColorCode,
                        color: colorCode,
                    },
                    ':active': {
                        backgroundColor: activeBackgroundColorCode,
                        color: colorCode,
                    },
                    height: customHeight || height,
                    marginTop: customMarginTop || borderStyle.marginTop,
                    marginRight: customMarginRight || borderStyle.marginRight,
                    marginBottom: customMarginBottom || borderStyle.marginBottom,
                    marginLeft: customMarginLeft || borderStyle.marginLeft,
                    fontSize,
                    textTransform: disableUppercase ? undefined : 'uppercase',
                    width: customWidth,
                };
            },
        },
    };

    return [overrides, size];
};

type BaseButtonProps = {
    id: string;
    text?: React.ReactNode;
    icon?: React.ReactElement;
    disabled?: boolean;
    onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
    variant: VARIANT;
    color?: COLOR;
    size: SIZE;
    height?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    margin?: string;
    groupInfo?: { position: number; total: number };
    width?: string;
    disableUppercase?: boolean;
};
export const BaseButton = (props: BaseButtonProps): React.FunctionComponentElement<BaseButtonProps> => {
    const {
        text,
        icon,
        onClick,
        variant,
        color,
        size,
        height,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        margin,
        groupInfo,
        disabled,
        id,
        width,
        disableUppercase,
    } = props;

    let parsedMarginTop = undefined;
    let parsedMarginRight = undefined;
    let parsedMarginBottom = undefined;
    let parsedMarginLeft = undefined;

    if (margin) {
        try {
            const splitMar = margin.split(' ');
            parsedMarginTop = splitMar[0];
            parsedMarginRight = splitMar[1];
            parsedMarginBottom = splitMar[2];
            parsedMarginLeft = splitMar[3];
        } catch (e) {
            console.error('failed to parse margin');
        }
    }

    const [overrides, sizeFromVariant] = useButtonVariant({
        variant,
        color,
        size,
        customHeight: height,
        customMarginTop: marginTop || parsedMarginTop,
        customMarginRight: marginRight || parsedMarginRight,
        customMarginBottom: marginBottom || parsedMarginBottom,
        customMarginLeft: marginLeft || parsedMarginLeft,
        groupInfo,
        customWidth: width,
        disableUppercase,
    });

    return (
        <Button
            key={id}
            disabled={disabled}
            overrides={overrides}
            size={mapSizeToBaseWeb[sizeFromVariant]}
            onClick={(event: SyntheticEvent<HTMLButtonElement>) => {
                onClick
                    ? onClick(event)
                    : () => {
                          return;
                      };
            }}
        >
            {icon || ''}
            <span style={{ width: '1px' }} />
            {text || '-'}
        </Button>
    );
};

export const BaseButtonGroup = (props: { children: React.ReactElement[] }): React.ReactElement => {
    const modifiedChildren: ReactElement[] = [];
    React.Children.forEach(props.children, (Element: React.ReactElement, i: number) => {
        if (!React.isValidElement(Element)) return;

        const BB = React.createElement<BaseButtonProps>(BaseButton, {
            ...(Element.props as BaseButtonProps),
            groupInfo: { position: i, total: props.children.length },
        });

        modifiedChildren.push(BB);
    });
    return <React.Fragment>{modifiedChildren}</React.Fragment>;
};
